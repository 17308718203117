export { default as demopdf } from "./blank.pdf";
export { default as downloadicon } from "./financial/download-icon.svg";
export { default as leftArrow } from "./image/packaging/left_arrow.svg";
export { default as rightArrow } from "./image/packaging/right_arrow.svg";
export { default as downArrow} from "./down_arrow_navbar.png";
export { default as colorDownArrow} from "./color_down_arrow_navbar.png";
export { default as coloredDownArrow} from "./color_down_arrow_navbar.png";

// packaging and dispatch
export { default as packagingBanner } from "./image/packaging/packaging_page_banner.jpg";
export { default as furnace } from "./image/packaging/furnace.png";
export { default as cncPeeps } from "./image/packaging/CNC_peeeps.png";
export { default as first_scroll_long } from "./image/packaging/first_scroll_long.jpg";
export { default as first_scroll_short } from "./image/packaging/first_scroll_short.jpg";

//Testing
export { default as testingBanner } from "./image/testing/testing_banner.jpg";
// export { default as testingBanner } from "./image/testing/testing_bg.jpg";
export { default as icon1 } from "./image/testing/icon_1.png";
export { default as icon2 } from "./image/testing/icon_2.png";
export { default as icon3 } from "./image/testing/icon_3.png";

// overview
export { default as overviewBanner } from "./image/overview/overview_banner.jpg";
export { default as overviewIcon } from "./image/overview/overview_icon.png";
export { default as quality_assurance_main } from "./image/overview/Quality Assurance.jpg";
export { default as quality_assurance_small } from "./image/overview/overview_small_img1.png";
export { default as overviewIcon1 } from "./image/overview/quality_supervision.png";
export { default as customer_approval_main } from "./image/overview/Customer Approvals.jpg";
export { default as customer_approval_small } from "./image/overview/Nickel_Silver.png";
export { default as overviewIcon2 } from "./image/overview/approval_symbol.png";
export { default as testing_facilities_main } from "./image/overview/TESTING FACILITIES.jpg";
export { default as testing_facilities_small } from "./image/overview/overview_small_img2.png";
export { default as overviewIcon3 } from "./image/overview/management.png";

// Homepage
export { default as mmlcoporateVideo } from "./home/mml-coporate.webm";

//ProductInner
export { default as shareicon } from "./productinner/shar-icon.png";
export { default as proddownloadicon } from "./productinner/download-icon.svg";
export { default as locationicon } from "./productinner/location.svg";
export { default as brochure } from "./productinner/brochure.pdf";
export { default as productSize } from "./multi-provide.png"
export { default as productInnerBanner } from "./productinner/product_banner.jpg"

// awards
// export { default as awardsBanner } from "./image/awards/awards_banner.jpg";
// export { default as awardsBannerNew } from "./image/awards/awards_banner.jpg";

// manufacturing process
export { default as manufacutringIcon1 } from "./image/manufacturing/icon_1.png";
export { default as manufacutringIcon2 } from "./image/manufacturing/icon_2.png";
export { default as manufacutringIcon3 } from "./image/manufacturing/icon_3.png";
export { default as maunfacutringProcess } from "./image/manufacturing/Manufacturing_process.png";

// manufacturing process inner
export { default as acrSpinner } from "./image/manufacturing/manufacturing inner/ACR_Spinner.png";
export { default as annealingFurnace } from "./image/manufacturing/manufacturing inner/Annealing_Furnace.png";
export { default as innerFurnace } from "./image/manufacturing/manufacturing inner/Furnace.png";
export { default as billetHeater } from "./image/manufacturing/manufacturing inner/Billet_Heater.png";
export { default as extrusion } from "./image/manufacturing/manufacturing inner/Extrusion.png";
export { default as pilger } from "./image/manufacturing/manufacturing inner/pilger.png";
export { default as billetsaw } from "./image/manufacturing/manufacturing inner/Billet_Saw.png";
export { default as drawBench } from "./image/manufacturing/manufacturing inner/Draw_bench.png";
export { default as straightener } from "./image/manufacturing/manufacturing inner/Straightener.png";

// Annual Return PDF
export { default as annual2015 } from "./financial/Annual-Return/2015.pdf";
export { default as annual2016 } from "./financial/Annual-Return/2016.pdf";
export { default as annual2017 } from "./financial/Annual-Return/2017.pdf";
export { default as annual2018 } from "./financial/Annual-Return/2018.pdf";
export { default as annual2019 } from "./financial/Annual-Return/2019.pdf";
export { default as annual2020 } from "./financial/Annual-Return/2020.pdf";
export { default as annual2021 } from "./financial/Annual-Return/2021.pdf";
export { default as annual2022 } from "./financial/Annual-Return/2022.pdf";
export { default as annual2023 } from "./financial/Annual-Return/2023.pdf";

export { default as financial2014 } from "./financial/Financial-Insights/2014.pdf";
export { default as financial2015 } from "./financial/Financial-Insights/2015.pdf";
export { default as financial2016 } from "./financial/Financial-Insights/2016.pdf";
export { default as financial2017 } from "./financial/Financial-Insights/2017.pdf";
export { default as financial2018 } from "./financial/Financial-Insights/2018.pdf";
export { default as financial2019 } from "./financial/Financial-Insights/2019.pdf";
export { default as financial2020 } from "./financial/Financial-Insights/2020.pdf";
export { default as financial2021 } from "./financial/Financial-Insights/2021.pdf";
export { default as financial2022 } from "./financial/Financial-Insights/2022.pdf";
export { default as financial2023 } from "./financial/Financial-Insights/2023.pdf";

// contact us
export { default as contactMap } from "./image/contactus/contact_map.png";
export { default as contactLocation } from "./image/contactus/locationline.svg";
export { default as contactCall } from "./image/contactus/callline.svg";
export { default as contactMail } from "./image/contactus/mail.svg";
export { default as contactLinkedin } from "./image/contactus/linkedin.png";
export { default as contactCar } from "./image/contactus/contact-car.png";
export { default as contactPlane } from "./image/contactus/contact_plane.png";
export { default as contactLocationGold } from "./image/contactus/locationline-1.svg";
export { default as contactTrain } from "./image/contactus/contact_train.png";
export { default as contactArrow } from "./image/contactus/contact_arrow.png";
export { default as submitButton } from "./image/contactus/submit_button.svg";
export { default as downloadsvg } from "./image/download.png";
export { default as searchsvg } from "./image/search-icon.svg";

export { default as footerscroll } from "./footer_cricle_yellow.png";
export { default as footerscrollhover } from "./footer_cricle_yellow.png";

export { default as specialityVideo } from "./PromoMM.mp4";
export { default as tubebg } from '../assets/productinner/tube_bg.jpg'

export let images = {
  notfound: {
    image: require("../assets/image/notfound.png"),
    alt: "notfound",
  },
  leftarrow: {
    image: require("../assets/image/down_arrow.png"),
    alt: "left arrow",
  },
  bannerbg: {
    image: require("../assets/image/img20.png"),
    alt: "banner bg",
  },
  homeicon: {
    image: require("../assets/image/img31.png"),
    alt: "home icon",
  },
  financialbanner: {
    image: require("../assets/financial/financial_bg.jpg"),
    alt: "financial banner",
  },
  copperpipe: {
    image: require("../assets/financial/copper-pipe.png"),
    alt: "copper pipe",
  },
  nickelsilver: {
    image: require("../assets/productlisting/nickel-silver-wires-bzn.png"),
    alt: "nickel silver",
  },
  extruded: {
    image: require("../assets/productlisting/nickel-silver-wires-bzn.png"),
    alt: "nickel silver",
  },
  wires: {
    image: require("../assets/productlisting/nickel-silver-wires-bzn.png"),
    alt: "nickel silver",
  },
  sectionsProfiles: {
    image: require("../assets/productlisting/nickel-silver-wires-bzn.png"),
    alt: "nickel silver",
  },
  Casted: {
    image: require("../assets/productlisting/nickel-silver-wires-bzn.png"),
    alt: "nickel silver",
  },
  brassalloy: {
    image: require("../assets/productlisting/brass-alloy-tubes.png"),
    alt: "brass",
  },
  thankyou: {
    image: require("../assets/image/thank_you.jpg"),
    alt: "thankyou",
  },


  // product listing

  copper_liting: {
    image: require("../assets/productlisting/Tubes _ Pipes/Copper/DHP 1.png"),
    alt: "ETP Tube",
  },
  copper_nickel_liting: {
    image: require("../assets/productlisting/Tubes _ Pipes/Copper Nickel/90_10 Cuni 1.png"),
    alt: "ETP Tube",
  },
  brasses_liting: {
    image: require("../assets/productlisting/Tubes _ Pipes/Plain Brass/60_40 brass 1.png"),
    alt: "ETP Tube",
  },
  ad_brasses_liting: {
    image: require("../assets/productlisting/Tubes _ Pipes/Admiralty Brass/Admiralty Brass 1.png"),
    alt: "ETP Tube",
  },
  al_brasses_liting: {
    image: require("../assets/productlisting/Tubes _ Pipes/Aluminium Brass/Aluminium Brass.png"),
    alt: "ETP Tube",
  },
  al_bronze_listing: {
    image: require("../assets/productlisting/Tubes _ Pipes/Copper Arsenic Aluminium Bronze/Copper Arsenic Aluminium Bronze 1.png"),
    alt: "ETP Tube",
  },
  finned_tube_liting: {
    image: require("../assets/productlisting/Speciality _ Finned Tubes/Finned Tubes/Finned Tube 1.png"),
    alt: "ETP Tube",
  },
  u_bend_liting: {
    image: require("../assets/productlisting/Speciality _ Finned Tubes/U Bend/U_Bend_1-removebg-preview.png"),
    alt: "ETP Tube",
  },
  machined_liting: {
    image: require("../assets/productlisting/Speciality _ Finned Tubes/Machined Components/Rings 2.png"),
    alt: "ETP Tube",
  },
  coils_liting: {
    image: require("../assets/productlisting/Speciality _ Finned Tubes/Machined Components/Rings 2.png"),
    alt: "ETP Tube",
  },
  bus_bars_liting: {
    image: require("../assets/productlisting/Speciality _ Finned Tubes/Machined Components/Rings 2.png"),
    alt: "ETP Tube",
  },
  strips_liting: {
    image: require("../assets/productlisting/Speciality _ Finned Tubes/Machined Components/Rings 2.png"),
    alt: "ETP Tube",
  },
  fittings_liting: {
    image: require("../assets/productlisting/Speciality _ Finned Tubes/Machined Components/Rings 2.png"),
    alt: "ETP Tube",
  },
  copper_rod_liting: {
    image: require("../assets/productlisting/Cast, Extruded _ Drawn Semis/Copper Rods/DPA Rod 1.png"),
    alt: "ETP Tube",
  },
  copper_nickel_rod_liting: {
    image: require("../assets/productlisting/Cast, Extruded _ Drawn Semis/Copper Nickel Rods/95_5 Cuni rod 1 copy.png"),
    alt: "ETP Tube",
  },
  brass_rod_liting: {
    image: require("../assets/productlisting/Cast, Extruded _ Drawn Semis/Brass Rods/Naval Brass Rod 1 copy.png"),
    alt: "ETP Tube",
  },
  brass_hollow_rod_liting: {
    image: require("../assets/productlisting/Cast, Extruded _ Drawn Semis/Brass Hollows/63_37 Brass Hollow 1 copy.png"),
    alt: "ETP Tube",
  },
  extruded_liting: {
    image: require("../assets/productlisting/Cast, Extruded _ Drawn Semis/Brass Hollows/63_37 Brass Hollow 1 copy.png"),
    alt: "ETP Tube",
  },
  wires_liting: {
    image: require("../assets/productlisting/Cast, Extruded _ Drawn Semis/Brass Hollows/63_37 Brass Hollow 1 copy.png"),
    alt: "ETP Tube",
  },
  sectionsProfiles_liting: {
    image: require("../assets/productlisting/Cast, Extruded _ Drawn Semis/Brass Hollows/63_37 Brass Hollow 1 copy.png"),
    alt: "ETP Tube",
  },
  casted_liting: {
    image: require("../assets/productlisting/Cast, Extruded _ Drawn Semis/Brass Hollows/63_37 Brass Hollow 1 copy.png"),
    alt: "ETP Tube",
  },
  nickel_silver_liting: {
    image: require("../assets/productlisting/Cast, Extruded _ Drawn Semis/Nickel Silver Rod/Nickel Silver Rod 1 copy.png"),
    alt: "ETP Tube",
  },
  al_bronze_liting: {
    image: require("../assets/productlisting/Cast, Extruded _ Drawn Semis/Aluminum Bronze Rod/Aluminuim Bronze Rod 2 copy.png"),
    alt: "ETP Tube",
  },
 phosphorous_bronze_liting: {
    image: require("../assets/productlisting/Cast, Extruded _ Drawn Semis/Phosphorus Bronze Rods/Phosphorus Bronze Rods 1.png"),
    alt: "ETP Tube",
  },
  manganeseb_bronze_liting: {
    image: require("../assets/productlisting/Cast, Extruded _ Drawn Semis/Phosphorus Bronze Rods/Phosphorus Bronze Rods 1.png"),
    alt: "ETP Tube",
  },
  nickel_aluminium_bronze_liting: {
    image: require("../assets/productlisting/Cast, Extruded _ Drawn Semis/Phosphorus Bronze Rods/Phosphorus Bronze Rods 1.png"),
    alt: "ETP Tube",
  },


  

  // product images

  copper_etp: {
    image: require("../assets/productinner/Tubes _ Pipes_product/Copper/ETP/ETP_1.jpg"),
    alt: "ETP Tube",
  },
  copper_frtp: {
    image: require("../assets/productinner/Tubes _ Pipes_product/Copper/FRTP/FRTP_1.jpg"),
    alt: "FRTP Tube",
  },
  copper_dhp: {
    image: require("../assets/productinner/Tubes _ Pipes_product/Copper/DHP/DHP_1.jpg"),
    alt: "DHP Tube",
  },
  copper_dlp: {
    image: require("../assets/productinner/Tubes _ Pipes_product/Copper/DLP/DLP_1_1.jpg"),
    alt: "DLP Tube",
  },
  copper_nickel95: {
    image: require("../assets/productinner/Tubes _ Pipes_product/Copper Nickel/95_5/95_5_1.jpg"),
    alt: "95_5 Cuni",
  },
  copper_nickel90: {
    image: require("../assets/productinner/Tubes _ Pipes_product/Copper Nickel/90_10/90_10_cui_1.jpg"),
    alt: "90_10 Cuni",
  },
  copper_nickel70: {
    image: require("../assets/productinner/Tubes _ Pipes_product/Copper Nickel/70_30/70_30 Cuni 1.jpg"),
    alt: "70_30 Cuni",
  },
  copper_nickel_cunifer: {
    image: require("../assets/productinner/Tubes _ Pipes_product/Copper Nickel/Cunifer/Cunifer 1.jpg"),
    alt: "Cunifer",
  },
  brasses_tube90: {
    image: require("../assets/productinner/Tubes _ Pipes_product/Plain Brass/90_10/90_10 brass 1.jpg"),
    alt: "90_10 brass",
  },
  brasses_tube_red85: {
    image: require("../assets/productinner/Tubes _ Pipes_product/Plain Brass/Red Brass 85_15/Red Brass 1.jpg"),
    alt: "Red Brass 85_15",
  },
  brasses_tube70: {
    image: require("../assets/productinner/Tubes _ Pipes_product/Plain Brass/70_30/70_30 Brass 1.jpg"),
    alt: "70_30 Brass",
  },
  brasses_tube65: {
    image: require("../assets/productinner/Tubes _ Pipes_product/Plain Brass/65_35/65_35 Brass 1.jpg"),
    alt: "65_35 Brass",
  },
  brasses_tube63: {
    image: require("../assets/productinner/Tubes _ Pipes_product/Plain Brass/63_37/63_37 Brass 1.jpg"),
    alt: "63_37 Brass",
  },
  brasses_tube60: {
    image: require("../assets/productinner/Tubes _ Pipes_product/Plain Brass/60_40/60_40 brass 1.jpg"),
    alt: "60_40 Brass",
  },
  brasses_tube_naval: {
    image: require("../assets/productinner/Tubes _ Pipes_product/Plain Brass/Naval Brass/Naval Brass 1.jpg"),
    alt: "Naval Brass",
  },
  brasses_tube80: {
    image: require("../assets/productinner/Tubes _ Pipes_product/Plain Brass/80_20/80_20 brass 1.jpg"),
    alt: "80_20 Brass",
  },
  ad_brass: {
    image: require("../assets/productinner/Tubes _ Pipes_product/Admiralty Brass/Admiralty Brass 1.jpg"),
    alt: "Admiralty Brass",
  },
  al_brass: {
    image: require("../assets/productinner/Tubes _ Pipes_product/Aluminium Brass/Aluminium Brass.jpg"),
    alt: "Aluminium Brass",
  },
  al_bronze: {
    image: require("../assets/productinner/Tubes _ Pipes_product/Copper Arsenic Aluminium Bronze/Copper Arsenic Aluminium Bronze 1.jpg"),
    alt: "Copper Arsenic Aluminium Bronze",
  },
  finned_tube: {
    image: require("../assets/productinner/Speciality _ Finned Tubes_product/Finned Tubes/Finned Tube 1.jpg"),
    alt: "Finned Tubes",
  },
  u_bend_tube: {
    image: require("../assets/productinner/Speciality _ Finned Tubes_product/U Bend/U Bend 1.jpg"),
    alt: "U Bend Tubes",
  },
  machined_component: {
    image: require("../assets/productinner/Speciality _ Finned Tubes_product/Machined Components/profile 1.jpg"),
    alt: "Machined Components",
  },
  coils: {
    image: require("../assets/productinner/Speciality _ Finned Tubes_product/Machined Components/profile 1.jpg"),
    alt: "Machined Components",
  },
  bus_bars: {
    image: require("../assets/productinner/Speciality _ Finned Tubes_product/Machined Components/profile 1.jpg"),
    alt: "Machined Components",
  },
  strips: {
    image: require("../assets/productinner/Speciality _ Finned Tubes_product/Machined Components/profile 1.jpg"),
    alt: "Machined Components",
  },
  fittings: {
    image: require("../assets/productinner/Speciality _ Finned Tubes_product/Machined Components/profile 1.jpg"),
    alt: "Machined Components",
  },
  etp_rod: {
    image: require("../assets/productinner/Cast, Extruded _ Drawn Semis_product/Copper Rods/ETP Rod/ETP_1.jpg"),
    alt: "ETP Rod",
  },
  frtp_rod: {
    image: require("../assets/productinner/Cast, Extruded _ Drawn Semis_product/Copper Rods/FRTP Rod/FRTP_ROD_1.jpg"),
    alt: "FRTP Rod",
  },
  dpa_rod: {
    image: require("../assets/productinner/Cast, Extruded _ Drawn Semis_product/Copper Rods/DPA Rod/DPA_ROD.jpg"),
    alt: "DPA Rod",
  },
  tellurium_rod: {
    image: require("../assets/productinner/Cast, Extruded _ Drawn Semis_product/Copper Rods/Tellurium Rod/TELLURIUM_COPPER_ROD.jpg"),
    alt: "Tellurium Rod",
  },
  copper_nickel90_rod: {
    image: require("../assets/productinner/Cast, Extruded _ Drawn Semis_product/Copper Nickel Rods/90_10/90_10_ROD_1.jpg"),
    alt: "Copper Nickel Rods 90_10",
  },
  copper_nickel70_rod: {
    image: require("../assets/productinner/Cast, Extruded _ Drawn Semis_product/Copper Nickel Rods/70_30/70_30 Cuni 1.jpg"),
    alt: "Copper Nickel Rods 70_30",
  },
  brass_rod90: {
    image: require("../assets/productinner/Cast, Extruded _ Drawn Semis_product/Brass Rods/90_10/90_10 Brass Rod 1.jpg"),
    alt: "Brass Rods 90_10",
  },
  brass_rod_red: {
    image: require("../assets/productinner/Cast, Extruded _ Drawn Semis_product/Brass Rods/Red Brass 85_15/Red Brass 85_15 Rod 1.jpg"),
    alt: "Red Brass 85_15",
  },
  brass_rod70: {
    image: require("../assets/productinner/Cast, Extruded _ Drawn Semis_product/Brass Rods/70_30/70_30 Brass rod 1.jpg"),
    alt: "Brass Rods 70_30",
  },
  brass_rod65: {
    image: require("../assets/productinner/Cast, Extruded _ Drawn Semis_product/Brass Rods/65_35/65_35 Brass rod 1.jpg"),
    alt: "Brass Rods 65_35",
  },
  brass_rod63: {
    image: require("../assets/productinner/Cast, Extruded _ Drawn Semis_product/Brass Rods/63_37/63_37 Brass Rod 1.jpg"),
    alt: "Brass Rods 63_37",
  },
  brass_rod60: {
    image: require("../assets/productinner/Cast, Extruded _ Drawn Semis_product/Brass Rods/60_40/60_40 Brass Rod 1.jpg"),
    alt: "Brass Rods 60_40",
  },
  brass_rod_naval: {
    image: require("../assets/productinner/Cast, Extruded _ Drawn Semis_product/Brass Rods/Naval Brass/Naval Brass Rod 1.jpg"),
    alt: "Brass Rods Naval",
  },
  brass_rod80: {
    image: require("../assets/productinner/Cast, Extruded _ Drawn Semis_product/Brass Rods/80_20/80_20 Brass rod 1.jpg"),
    alt: "Brass Rods 80_20",
  },
  brass_rod95: {
    image: require("../assets/productinner/Cast, Extruded _ Drawn Semis_product/Brass Rods/95_5/95_5 Brass Rod 1.jpg"),
    alt: "Brass Rods 95_5",
  },
  brass_hollow70: {
    image: require("../assets/productinner/Cast, Extruded _ Drawn Semis_product/Brass Hollows/70_30 Brass Hollow/70_30 Brass Hollow 1.jpg"),
    alt: "70_30 Brass Hollow",
  },
  brass_hollow63: {
    image: require("../assets/productinner/Cast, Extruded _ Drawn Semis_product/Brass Hollows/63_37 Brass Hollow/63_37 Brass Hollow 1.jpg"),
    alt: "63_37 Brass Hollow",
  },
  ad_brass_hollow: {
    image: require("../assets/productinner/Cast, Extruded _ Drawn Semis_product/Brass Hollows/Admiralty Brass Hollow/admiralty Brass Hollow 1.jpg"),
    alt: "Admiralty Brass Hollow",
  },
  nickel_silver_rod : {
    image: require("../assets/productinner/Cast, Extruded _ Drawn Semis_product/Nickel Silver Rod/Nickel Silver Rod 1.jpg"),
    alt: "Nickel Silver Rod",
  },
  Al_bronze_rod : {
    image: require("../assets/productinner/Cast, Extruded _ Drawn Semis_product/Aluminum Bronze Rod/Alunium Bronze rod 1.jpg"),
    alt: "Aluminium Bronze rod",
  },
  Phosphorous_bronze_rod : {
    image: require("../assets/productinner/Cast, Extruded _ Drawn Semis_product/Phosphorus Bronze Rods/Phosphorus Bronze Rods 1.jpg"),
    alt: "Phosphorus Bronze Rod",
  },
  Manganeseb_bronze : {
    image: require("../assets/productinner/Cast, Extruded _ Drawn Semis_product/Phosphorus Bronze Rods/Phosphorus Bronze Rods 1.jpg"),
    alt: "Phosphorus Bronze Rod",
  },
  Nickel_aluminium_bronze : {
    image: require("../assets/productinner/Cast, Extruded _ Drawn Semis_product/Phosphorus Bronze Rods/Phosphorus Bronze Rods 1.jpg"),
    alt: "Phosphorus Bronze Rod",
  },
  billets_70: {
    image: require("../assets/productinner/Cast, Extruded _ Drawn Semis_product/Brass Hollows/70_30 Brass Hollow/70_30 Brass Hollow 1.jpg"),
    alt: "70_30 Brass Hollow",
  },
  billets_listing: {
    image: require("../assets/productlisting/Cast, Extruded _ Drawn Semis/Billets/billets_listing_1.png"),
    alt: "billets",
  },
  billets_inner: {
    image: require("../assets/productinner/Cast, Extruded _ Drawn Semis_product/Billets/billets_inner.png"),
    alt: "Ingots",
  },
  ingots_listing: {
    image: require("../assets/productlisting/Cast, Extruded _ Drawn Semis/Ingots/ingots_listing.png"),
    alt: "Ingots",
  },
  ingots_inner: {
    image: require("../assets/productinner/Cast, Extruded _ Drawn Semis_product/Ingots/ingots_inner_1.png"),
    alt: "Ingots",
  },


  // product inner specsheet table 
  
  copper_tubes_specsheet: {
    image: require("../assets/productinner/tubes and pipes/copper_tubes.jpg"),
    alt: "copper_specsheet",
  },
  copper_nickel95_specsheet: {
    image: require("../assets/productinner/tubes and pipes/95-5-cuni.jpg"),
    alt: "copper_specsheet",
  },
  copper_nickel90_specsheet: {
    image: require("../assets/productinner/tubes and pipes/90-10-cuni-tubes.jpg"),
    alt: "copper_specsheet",
  },
  copper_nickel70_specsheet: {
    image: require("../assets/productinner/tubes and pipes/70-30-cuni-tubes.jpg"),
    alt: "copper_specsheet",
  },
  admiralty_brass_specsheet: {
    image: require("../assets/productinner/tubes and pipes/admiralty-brass-tubes.jpg"),
    alt: "copper_specsheet",
  },
  aluminium_brass_specsheet: {
    image: require("../assets/productinner/tubes and pipes/aluminium-brass-tubes.jpg"),
    alt: "copper_specsheet",
  },
  aluminium_bronze_specsheet: {
    image: require("../assets/productinner/tubes and pipes/aluminium-bronze-tubes.jpg"),
    alt: "copper_specsheet",
  },
  brass_tube70_specsheet: {
    image: require("../assets/productinner/tubes and pipes/70-30-brass-tubes.jpg"),
    alt: "copper_specsheet",
  },
  // brass_tube63_specsheet: {
  //   image: require("../assets/productinner/tubes and pipes/70-30-brass-tubes.jpg"),
  //   alt: "copper_specsheet",
  // },
  finned_tube_specsheet: {
    image: require("../assets/productinner/SPECIALITY AND FINNED TUBES/finned-tubes.jpg"),
    alt: "copper_specsheet",
  },



  //packaging and dispatch
  slide0: {
    image: require("../assets/image/packaging/0th scroll picture.jpg"),
    alt: "slide 0",
  },
  thumnail0: {
    image: require("../assets/image/packaging/0th scroll small.png"),
    alt: "thumnail 0",
  },
  slide1: {
    image: require("../assets/image/packaging/1st scroll picture.jpg"),
    alt: "slide 1",
  },
  thumnail1: {
    image: require("../assets/image/packaging/1st scroll small.jpg"),
    alt: "thumnail 1",
  },
  slide2: {
    image: require("../assets/image/packaging/2nd scroll picture.jpg"),
    alt: "slide 2",
  },
  thumnail2: {
    image: require("../assets/image/packaging/2nd scroll small.jpg"),
    alt: "thumnail 2",
  },
  slide3: {
    image: require("../assets/image/packaging/3rd scroll picture.jpg"),
    alt: "slide 3",
  },
  thumnail3: {
    image: require("../assets/image/packaging/3rd scroll small.jpg"),
    alt: "thumnail 3",
  },
  slide4: {
    image: require("../assets/image/packaging/4th scroll picture.jpg"),
    alt: "slide 4",
  },
  thumnail4: {
    image: require("../assets/image/packaging/4th scroll small.jpg"),
    alt: "thumnail 4",
  },
  slide5: {
    image: require("../assets/image/packaging/5th scroll picture.jpg"),
    alt: "slide 5",
  },
  thumnail5: {
    image: require("../assets/image/packaging/5th scroll small.jpg"),
    alt: "thumnail 5",
  },
  slide6: {
    image: require("../assets/image/packaging/6th scroll picture.jpg"),
    alt: "slide 6",
  },
  thumnail6: {
    image: require("../assets/image/packaging/6th scroll small.jpg"),
    alt: "thumnail 6",
  },
  mbthumnail1: {
    image: require("../assets/image/packaging/mb_Furnace_Night.png"),
    alt: "thumnail 1",
  },
  mbthumnail2: {
    image: require("../assets/image/packaging/mb_Hydro_peep.png"),
    alt: "thumnail 2",
  },
  product1: {
    image: require("../assets/image/testing/gallery img 1.jpg"),
    alt: "product",
  },
  product2: {
    image: require("../assets/image/testing/gallery img 2.jpg"),
    alt: "product",
  },
  product3: {
    image: require("../assets/image/testing/gallery img 3.jpg"),
    alt: "product",
  },
  product4: {
    image: require("../assets/image/testing/gallery img 4.jpg"),
    alt: "product",
  },
  product5: {
    image: require("../assets/image/testing/gallery img 5.jpg"),
    alt: "product",
  },
  // quality

  quality1: {
    image: require("../assets/image/overview/1st gallery image.jpg"),
    alt: "quality",
  },
  quality2: {
    image: require("../assets/image/overview/2nd gallery img.jpg"),
    alt: "quality",
  },
  quality3: {
    image: require("../assets/image/overview/3rd gallery img.jpg"),
    alt: "quality",
  },
  quality4: {
    image: require("../assets/image/overview/4th gallery img.jpg"),
    alt: "quality",
  },
  quality5: {
    image: require("../assets/image/overview/5th gallery img.jpg"),
    alt: "quality",
  },
  application: {
    image: require("../assets/Applications/application.jpg"),
    alt: "application",
  },
  automobileIndustry: {
    image: require("../assets/Applications/Automobile.png"),
    alt: "AUTOMOBILE INDUSTRY",
  },
  fitting: {
    image: require("../assets/Applications/FITTINGS.png"),
    alt: "FITTINGS",
  },
  aerospace: {
    image: require("../assets/Applications/AEROSPACE_INDUSTRY.png"),
    alt: "AEROSPACE INDUSTRY",
  },
  defense: {
    image: require("../assets/Applications/DEFENSE_APPLICATIONS.png"),
    alt: "DEFENSE APPLICATIONS",
  },
  desalination: {
    image: require("../assets/Applications/DESALINATION_PLANTS.png"),
    alt: "DESALINATION PLANTS",
  },
  sugar: {
    image: require("../assets/Applications/SUGAR_CHEMICAL_INDUSTRY.png"),
    alt: "SUGAR & CHEMICAL INDUSTRY",
  },
  oil: {
    image: require("../assets/Applications/OIL_GAS_INDUSTRY.png"),
    alt: "OIL & GAS INDUSTRY",
  },
  ship: {
    image: require("../assets/Applications/SHIP_BUILDING_REPAIRS.png"),
    alt: "SHIP BUILDING & REPAIRS",
  },
  bearing: {
    image: require("../assets/Applications/BEARING_BUSHING.png"),
    alt: "BEARING & BUSHING",
  },
  air: {
    image: require("../assets/Applications/Air_Conditioning_and_Refrigeration.jpg"),
    alt: "Air Conditioning and Refrigeration",
  },
  heat: {
    image: require("../assets/Applications/HEAT_EXCHANGERS_CONDENSERS.png"),
    alt: "HEAT EXCHANGERS, CONDENSERS",
  },
  evaporator: {
    image: require("../assets/Applications/EVAPOURATOR.png"),
    alt: "EVAPOURATOR",
  },
  chiller: {
    image: require("../assets/Applications/CHILLER.png"),
    alt: "CHILLER",
  },
  sanitry: {
    image: require("../assets/Applications/SANITRY_PLUMBERING.png"),
    alt: "SANITRY & PLUMBERING",
  },
  fertilizer: {
    image: require("../assets/Applications/FERTILIZERS_POTASH_INDUSTRIES.png"),
    alt: "FERTILIZERS & POTASH INDUSTRIES",
  },
  powerPlant: {
    image: require("../assets/Applications/POWER_PLANTS.png"),
    alt: "POWER PLANTS",
  },
  
  // awards

  awardsBanner: {
    image: require("../assets/image/awards/awards_bg.jpg"),
    alt: "Awards Banner",
  },

  //testing
  testing1: {
    image: require("../assets/image/testing/gallery img 1.jpg"),
    alt: "lab peeps",
  },
  testing2: {
    image: require("../assets/image/testing/gallery img 2.jpg"),
    alt: "FURNACE Night",
  },
  testing3: {
    image: require("../assets/image/testing/gallery img 3.jpg"),
    alt: "pilpil",
  },
  testing4: {
    image: require("../assets/image/testing/gallery img 4.jpg"),
    alt: "FURNACE Night",
  },
  testing5: {
    image: require("../assets/image/testing/gallery img 5.jpg"),
    alt: "pilpil",
  },
  testing6: {
    image: require("../assets/image/testing/gallery_img_6.jpg"),
    alt: "pilpil",
  },
  testing7: {
    image: require("../assets/image/testing/gallery_img_7.jpg"),
    alt: "pilpil",
  },

  // awards
  awards1: {
    image: require("../assets/image/awards/awards 1.jpg"),
    alt: "certificate",
  },
  awards2: {
    image: require("../assets/image/awards/awards 2.jpg"),
    alt: "certificate",
  },
  awards3: {
    image: require("../assets/image/awards/awards 3.jpg"),
    alt: "certificate",
  },
  awards4: {
    image: require("../assets/image/awards/awards 4.jpg"),
    alt: "certificate",
  },
  awards5: {
    image: require("../assets/image/awards/awards 5.jpg"),
    alt: "certificate",
  },
  awards6: {
    image: require("../assets/image/awards/awards 6.jpg"),
    alt: "certificate",
  },
  awards7: {
    image: require("../assets/image/awards/awards_7.jpg"),
    alt: "certificate",
  },

  // vendor approval certificates

  vendor1: {
    image: require("../assets/Quality/vendor/1-100 (1).jpg"),
    alt: "certificate",
  },
  vendor2: {
    image: require("../assets/Quality/vendor/2-100 (1).jpg"),
    alt: "certificate",
  },
  vendor3: {
    image: require("../assets/Quality/vendor/3-100 (1).jpg"),
    alt: "certificate",
  },
  vendor4: {
    image: require("../assets/Quality/vendor/4-100 (1).jpg"),
    alt: "certificate",
  },
  vendor5: {
    image: require("../assets/Quality/vendor/5-100 (1).jpg"),
    alt: "certificate",
  },
  vendor6: {
    image: require("../assets/Quality/vendor/6-100 (1).jpg"),
    alt: "certificate",
  },
  vendor7: {
    image: require("../assets/Quality/vendor/7-100 (1).jpg"),
    alt: "certificate",
  },
  vendor8: {
    image: require("../assets/Quality/vendor/8-100 (1).jpg"),
    alt: "certificate",
  },
  vendor9: {
    image: require("../assets/Quality/vendor/9-100 (1).jpg"),
    alt: "certificate",
  },
  vendor10: {
    image: require("../assets/Quality/vendor/10-100 (1).jpg"),
    alt: "certificate",
  },
  vendor11: {
    image: require("../assets/Quality/vendor/11-100 (1).jpg"),
    alt: "certificate",
  },
  vendor12: {
    image: require("../assets/Quality/vendor/12-100 (1).jpg"),
    alt: "certificate",
  },
  vendor13: {
    image: require("../assets/Quality/vendor/13-100 (2).jpg"),
    alt: "certificate",
  },
  vendor14: {
    image: require("../assets/Quality/vendor/13-100(1).jpg"),
    alt: "certificate",
  },

  // Overseas 

  overseasBg : {
    image: require("../assets/Clients/overseas.jpg"),
    alt: "Overseas Presence",
  },
  fourty: {
    image: require("../assets/Clients/40.png"),
    alt: "40+",
  },
  earth: {
    image: require("../assets/Clients/40.png"),
    alt: "40+",
  },

  // Indigenious 
  indiBg : {
    image: require("../assets/Clients/Indigenious.jpg"),
    alt: "metal map",
  },
  metalMap : {
    image: require("../assets/Clients/metalMap_1.png"),
    alt: "metal map",
  },
  clinetlogo_colored_indi_1: {
    image: require("../assets/Clients/colored-Indigenous/thermax.png"),
    alt: "thyssenkrup",
  },
  clinetlogo_bw_indi_1: {
    image: require("../assets/Clients/white-Indigenous/thermax white.png"),
    alt: "thyssenkrup",
  },
  clinetlogo_colored_indi_2: {
    image: require("../assets/Clients/colored-Indigenous/reliance.png"),
    alt: "Takreer",
  },
  clinetlogo_bw_indi_2: {
    image: require("../assets/Clients/white-Indigenous/reliance white.png"),
    alt: "Takreer",
  },
  clinetlogo_colored_indi_3: {
    image: require("../assets/Clients/colored-Indigenous/godrej.png"),
    alt: "shell",
  },
  clinetlogo_bw_indi_3: {
    image: require("../assets/Clients/white-Indigenous/Godrej white.png"),
    alt: "shell",
  },
  clinetlogo_colored_indi_4: {
    image: require("../assets/Clients/colored-Indigenous/siemens.png"),
    alt: "Saudi aramco",
  },
  clinetlogo_bw_indi_4: {
    image: require("../assets/Clients/white-Indigenous/siemens white.png"),
    alt: "Saudi aramco",
  },
  clinetlogo_colored_indi_5: {
    image: require("../assets/Clients/colored-Indigenous/lnt.png"),
    alt: "Qatar petroleum",
  },
  clinetlogo_bw_indi_5: {
    image: require("../assets/Clients/white-Indigenous/lnt white.png"),
    alt: "Qatar petroleum",
  },
  clinetlogo_colored_indi_6: {
    image: require("../assets/Clients/colored-Indigenous/Bharat petroleum.png"),
    alt: "petrobras",
  },
  clinetlogo_bw_indi_6: {
    image: require("../assets/Clients/white-Indigenous/Bharat petroleum white.png"),
    alt: "petrobras",
  },
  clinetlogo_colored_indi_7: {
    image: require("../assets/Clients/colored-Indigenous/indian oil (1).png"),
    alt: "PDO",
  },
  clinetlogo_bw_indi_7: {
    image: require("../assets/Clients/white-Indigenous/indian oil.png"),
    alt: "PDO",
  },
  clinetlogo_colored_indi_8: {
    image: require("../assets/Clients/colored-Indigenous/HP.png"),
    alt: "Lukoil",
  },
  clinetlogo_bw_indi_8: {
    image: require("../assets/Clients/white-Indigenous/HP white.png"),
    alt: "Lukoil",
  },
  clinetlogo_colored_indi_9: {
    image: require("../assets/Clients/colored-Indigenous/bhel.png"),
    alt: "kohler",
  },
  clinetlogo_bw_indi_9: {
    image: require("../assets/Clients/white-Indigenous/bhel white.png"),
    alt: "kohler",
  },
  clinetlogo_colored_indi_10: {
    image: require("../assets/Clients/colored-Indigenous/NPCIL (1).png"),
    alt: "kelvion",
  },
  clinetlogo_bw_indi_10: {
    image: require("../assets/Clients/white-Indigenous/NPCIL.png"),
    alt: "kelvion",
  },
  clinetlogo_colored_indi_11: {
    image: require("../assets/Clients/colored-Indigenous/ntpc.png"),
    alt: "Iberdrola",
  },
  clinetlogo_bw_indi_11: {
    image: require("../assets/Clients/white-Indigenous/ntpc white.png"),
    alt: "Iberdrola",
  },
  clinetlogo_colored_indi_12: {
    image: require("../assets/Clients/colored-Indigenous/isgec – 1.png"),
    alt: "Hyundai",
  },
  clinetlogo_bw_indi_12: {
    image: require("../assets/Clients/white-Indigenous/isgec white – 1.png"),
    alt: "Hyundai",
  },
  clinetlogo_colored_indi_13: {
    image: require("../assets/Clients/colored-Indigenous/sail.png"),
    alt: "Hanwha",
  },
  clinetlogo_bw_indi_13: {
    image: require("../assets/Clients/white-Indigenous/sail white.png"),
    alt: "Hanwha",
  },
  clinetlogo_colored_indi_14: {
    image: require("../assets/Clients/colored-Indigenous/voltas.png"),
    alt: "Galp energia",
  },
  clinetlogo_bw_indi_14: {
    image: require("../assets/Clients/white-Indigenous/voltas white.png"),
    alt: "Galp energia",
  },
  clinetlogo_colored_indi_15: {
    image: require("../assets/Clients/colored-Indigenous/whirlpool.png"),
    alt: "exxon mobil",
  },
  clinetlogo_bw_indi_15: {
    image: require("../assets/Clients/white-Indigenous/whirlpool white.png"),
    alt: "exxon mobil",
  },
  clinetlogo_colored_indi_16: {
    image: require("../assets/Clients/colored-Indigenous/haier.png"),
    alt: "ENI",
  },
  clinetlogo_bw_indi_16: {
    image: require("../assets/Clients/white-Indigenous/haier white.png"),
    alt: "ENI",
  },
  clinetlogo_colored_indi_17: {
    image: require("../assets/Clients/colored-Indigenous/carrier.png"),
    alt: "Enel Group",
  },
  clinetlogo_bw_indi_17: {
    image: require("../assets/Clients/white-Indigenous/carrier white.png"),
    alt: "Enel Group",
  },
  clinetlogo_colored_indi_18: {
    image: require("../assets/Clients/shipyards/MD.png"),
    alt: "Dewa",
  },
  clinetlogo_bw_indi_18: {
    image: require("../assets/Clients/shipyards/Md white.png"),
    alt: "Dewa",
  },
  clinetlogo_colored_indi_19: {
    image: require("../assets/Clients/shipyards/grse.png"),
    alt: "Bapco",
  },
  clinetlogo_bw_indi_19: {
    image: require("../assets/Clients/shipyards/grse white.png"),
    alt: "Bapco",
  },
  clinetlogo_colored_indi_20: {
    image: require("../assets/Clients/shipyards/csl.png"),
    alt: "babcock",
  },
  clinetlogo_bw_indi_20: {
    image: require("../assets/Clients/shipyards/csl-1.png"),
    alt: "babcock",
  },
  clinetlogo_colored_indi_21: {
    image: require("../assets/Clients/shipyards/hsl.png"),
    alt: "babcock",
  },
  clinetlogo_bw_indi_21: {
    image: require("../assets/Clients/shipyards/hsl-1.png"),
    alt: "babcock",
  },

  // overseas
  clinetlogo_colored_1: {
    image: require("../assets/Clients/colored/thyssenkrup.png"),
    alt: "thyssenkrup",
  },
  clinetlogo_bw_1: {
    image: require("../assets/Clients/white/thyssenkrup white.png"),
    alt: "thyssenkrup",
  },
  clinetlogo_colored_2: {
    image: require("../assets/Clients/colored/Takreer.png"),
    alt: "Takreer",
  },
  clinetlogo_bw_2: {
    image: require("../assets/Clients/white/Takreer white.png"),
    alt: "Takreer",
  },
  clinetlogo_colored_3: {
    image: require("../assets/Clients/colored/shell.png"),
    alt: "shell",
  },
  clinetlogo_bw_3: {
    image: require("../assets/Clients/white/shell white.png"),
    alt: "shell",
  },
  clinetlogo_colored_4: {
    image: require("../assets/Clients/colored/Saudi aramco_1.png"),
    alt: "Saudi aramco",
  },
  clinetlogo_bw_4: {
    image: require("../assets/Clients/white/Saudi Aramco white.png"),
    alt: "Saudi aramco",
  },
  clinetlogo_colored_5: {
    image: require("../assets/Clients/colored/Qatar petroleum_1.png"),
    alt: "Qatar petroleum",
  },
  clinetlogo_bw_5: {
    image: require("../assets/Clients/white/Qatar petroleum white.png"),
    alt: "Qatar petroleum",
  },
  clinetlogo_colored_6: {
    image: require("../assets/Clients/colored/petrobras.png"),
    alt: "petrobras",
  },
  clinetlogo_bw_6: {
    image: require("../assets/Clients/white/Petrobras white.png"),
    alt: "petrobras",
  },
  clinetlogo_colored_7: {
    image: require("../assets/Clients/colored/PDO_1.png"),
    alt: "PDO",
  },
  clinetlogo_bw_7: {
    image: require("../assets/Clients/white/PDO white.png"),
    alt: "PDO",
  },
  clinetlogo_colored_8: {
    image: require("../assets/Clients/colored/lukoil.png"),
    alt: "Lukoil",
  },
  clinetlogo_bw_8: {
    image: require("../assets/Clients/white/Lukoil white.png"),
    alt: "Lukoil",
  },
  clinetlogo_colored_9: {
    image: require("../assets/Clients/colored/kohler.png"),
    alt: "kohler",
  },
  clinetlogo_bw_9: {
    image: require("../assets/Clients/white/kohler white.png"),
    alt: "kohler",
  },
  clinetlogo_colored_10: {
    image: require("../assets/Clients/colored/kelvion.png"),
    alt: "kelvion",
  },
  clinetlogo_bw_10: {
    image: require("../assets/Clients/white/kelvion white.png"),
    alt: "kelvion",
  },
  clinetlogo_colored_11: {
    image: require("../assets/Clients/colored/iberdrola.png"),
    alt: "Iberdrola",
  },
  clinetlogo_bw_11: {
    image: require("../assets/Clients/white/Iberdrola white.png"),
    alt: "Iberdrola",
  },
  rheinmetallclient: {
    image: require("../assets/Clients/colored/rheinmetall.png"),
    alt: "rheinmetall",
  },
  hyundaiclient: {
    image: require("../assets/Clients/colored/hyundai-engineering-and-construction.png"),
    alt: "hyundai engineering and construction",
  },
  clinetlogo_colored_12: {
    image: require("../assets/Clients/colored/Hyundai.png"),
    alt: "Hyundai",
  },
  clinetlogo_bw_12: {
    image: require("../assets/Clients/white/hyundai white.png"),
    alt: "Hyundai",
  },
  clinetlogo_colored_13: {
    image: require("../assets/Clients/colored/Hanwha_1.png"),
    alt: "Hanwha",
  },
  clinetlogo_bw_13: {
    image: require("../assets/Clients/white/Hanwha white.png"),
    alt: "Hanwha",
  },
  clinetlogo_colored_14: {
    image: require("../assets/Clients/colored/Galp energia_1.png"),
    alt: "Galp energia",
  },
  clinetlogo_bw_14: {
    image: require("../assets/Clients/white/Galp energia white.png"),
    alt: "Galp energia",
  },
  clinetlogo_colored_15: {
    image: require("../assets/Clients/colored/exxon mobil.png"),
    alt: "exxon mobil",
  },
  clinetlogo_bw_15: {
    image: require("../assets/Clients/white/exxon mobil white.png"),
    alt: "exxon mobil",
  },
  clinetlogo_colored_16: {
    image: require("../assets/Clients/colored/ENI.png"),
    alt: "ENI",
  },
  clinetlogo_bw_16: {
    image: require("../assets/Clients/white/ENI white.png"),
    alt: "ENI",
  },
  clinetlogo_colored_17: {
    image: require("../assets/Clients/colored/Enel Group.png"),
    alt: "Enel Group",
  },
  clinetlogo_bw_17: {
    image: require("../assets/Clients/white/Enel Group white.png"),
    alt: "Enel Group",
  },
  clinetlogo_colored_18: {
    image: require("../assets/Clients/colored/Dewa_1.png"),
    alt: "Dewa",
  },
  clinetlogo_bw_18: {
    image: require("../assets/Clients/white/Dewa white.png"),
    alt: "Dewa",
  },
  clinetlogo_colored_19: {
    image: require("../assets/Clients/colored/Bapco.png"),
    alt: "Bapco",
  },
  clinetlogo_bw_19: {
    image: require("../assets/Clients/white/Bapco white.png"),
    alt: "Bapco",
  },
  clinetlogo_colored_20: {
    image: require("../assets/Clients/colored/babcock_1.png"),
    alt: "babcock",
  },
  clinetlogo_bw_20: {
    image: require("../assets/Clients/white/babcock white.png"),
    alt: "babcock",
  },
  clinetlogo_colored_21: {
    image: require("../assets/Clients/colored/ADNOC.png"),
    alt: "ADNOC",
  },
  clinetlogo_bw_21: {
    image: require("../assets/Clients/white/ADNOC white.png"),
    alt: "ADNOC",
  },
  clinetlogo_colored_22: {
    image: require("../assets/Clients/colored/orpic.png"),
    alt: "ADNOC",
  },
  clinetlogo_bw_22: {
    image: require("../assets/Clients/white/orpic white.png"),
    alt: "ADNOC",
  },
  clinetlogo_colored_23: {
    image: require("../assets/Clients/colored/adma_1.png"),
    alt: "ADNOC",
  },
  clinetlogo_bw_23: {
    image: require("../assets/Clients/white/adma white.png"),
    alt: "ADNOC",
  },
  clinetlogo_colored_24: {
    image: require("../assets/Clients/colored/apt.png"),
    alt: "ADNOC",
  },
  clinetlogo_bw_24: {
    image: require("../assets/Clients/white/apt white.png"),
    alt: "ADNOC",
  },
  clinetlogo_colored_25: {
    image: require("../assets/Clients/colored/complexul energetic_1.png"),
    alt: "ADNOC",
  },
  clinetlogo_bw_25: {
    image: require("../assets/Clients/white/complexul energetic white.png"),
    alt: "ADNOC",
  },
  clinetlogo_colored_26: {
    image: require("../assets/Clients/colored/drydocks world.png"),
    alt: "ADNOC",
  },
  clinetlogo_bw_26: {
    image: require("../assets/Clients/white/drydocks world white.png"),
    alt: "ADNOC",
  },
  clinetlogo_colored_27: {
    image: require("../assets/Clients/colored/energomiport_1.png"),
    alt: "ADNOC",
  },
  clinetlogo_bw_27: {
    image: require("../assets/Clients/white/energomiport white.png"),
    alt: "ADNOC",
  },
  clinetlogo_colored_28: {
    image: require("../assets/Clients/colored/gt technologies_1.png"),
    alt: "ADNOC",
  },
  clinetlogo_bw_28: {
    image: require("../assets/Clients/white/gt technologies white.png"),
    alt: "ADNOC",
  },
  clinetlogo_colored_29: {
    image: require("../assets/Clients/colored/knpc.png"),
    alt: "ADNOC",
  },
  clinetlogo_bw_29: {
    image: require("../assets/Clients/white/knpc white.png"),
    alt: "ADNOC",
  },
  clinetlogo_colored_30: {
    image: require("../assets/Clients/colored/Kuwait oil company.png"),
    alt: "ADNOC",
  },
  clinetlogo_bw_30: {
    image: require("../assets/Clients/white/Kuwait oil company white.png"),
    alt: "ADNOC",
  },
  clinetlogo_colored_31: {
    image: require("../assets/Clients/colored/Sabic.png"),
    alt: "ADNOC",
  },
  clinetlogo_colored_32: {
    image: require("../assets/Clients/colored/apt_1.png"),
    alt: "ADNOC",
  },

  
  // certificate
  certificate1: {
    image: require("../assets/Quality/certificate/1.jpg"),
    alt: "place holder",
  },
  certificate2: {
    image: require("../assets/Quality/certificate/2_1.png"),
    alt: "place holder",
  },
  certificate3: {
    image: require("../assets/Quality/certificate/3.jpg"),
    alt: "place holder",
  },
  certificate4: {
    image: require("../assets/Quality/certificate/4.jpg"),
    alt: "place holder",
  },
  certificate5: {
    image: require("../assets/Quality/certificate/5.jpg"),
    alt: "place holder",
  },
  certificate6: {
    image: require("../assets/Quality/certificate/6.jpg"),
    alt: "place holder",
  },
  certificate7: {
    image: require("../assets/Quality/certificate/7.jpg"),
    alt: "place holder",
  },
  certificate8: {
    image: require("../assets/Quality/certificate/8.jpg"),
    alt: "place holder",
  },
  certificate9: {
    image: require("../assets/Quality/certificate/9.jpg"),
    alt: "place holder",
  },
  certificate10: {
    image: require("../assets/Quality/certificate/10.jpg"),
    alt: "place holder",
  },
  certificate11: {
    image: require("../assets/Quality/certificate/11.jpg"),
    alt: "place holder",
  },
  certificate12: {
    image: require("../assets/Quality/certificate/12.jpg"),
    alt: "place holder",
  },
  certificate13: {
    image: require("../assets/Quality/certificate/13.jpg"),
    alt: "place holder",
  },
  certificate14: {
    image: require("../assets/Quality/certificate/14.jpg"),
    alt: "place holder",
  },
  certificate15: {
    image: require("../assets/Quality/certificate/15.jpg"),
    alt: "place holder",
  },
  certificate16: {
    image: require("../assets/Quality/certificate/16.jpg"),
    alt: "place holder",
  },
  certificate17: {
    image: require("../assets/Quality/certificate/17.jpg"),
    alt: "place holder",
  },
  certificate18: {
    image: require("../assets/Quality/certificate/18.jpg"),
    alt: "place holder",
  },
  certificate19: {
    image: require("../assets/Quality/certificate/19.jpg"),
    alt: "place holder",
  },
  certificate20: {
    image: require("../assets/Quality/certificate/20.jpg"),
    alt: "place holder",
  },
  certificate21: {
    image: require("../assets/Quality/certificate/21.jpg"),
    alt: "place holder",
  },
  certificate22: {
    image: require("../assets/Quality/certificate/22.jpg"),
    alt: "place holder",
  },
  certificate23: {
    image: require("../assets/Quality/certificate/23.jpg"),
    alt: "place holder",
  },

 

  // awards certificate
  awardscertificate1: {
    image: require("../assets/image/awards/Certificates/Certifications (1).jpg"),
    alt: "place holder",
  },
  awardscertificate2: {
    image: require("../assets/image/awards/Certificates/Certifications (2).jpg"),
    alt: "place holder",
  },
  awardscertificate3: {
    image: require("../assets/image/awards/Certificates/Certifications (3).jpg"),
    alt: "place holder",
  },
  awardscertificate4: {
    image: require("../assets/image/awards/Certificates/Certifications (4).jpg"),
    alt: "place holder",
  },
  awardscertificate5: {
    image: require("../assets/image/awards/Certificates/Certifications (5).jpg"),
    alt: "place holder",
  },
  awardscertificate6: {
    image: require("../assets/image/awards/Certificates/Certifications (6).jpg"),
    alt: "place holder",
  },
  awardscertificate7: {
    image: require("../assets/image/awards/Certificates/Certifications (7).jpg"),
    alt: "place holder",
  },
  awardscertificate8: {
    image: require("../assets/image/awards/Certificates/Certifications (8).jpg"),
    alt: "place holder",
  },
  awardscertificate9: {
    image: require("../assets/image/awards/Certificates/Certifications (9).jpg"),
    alt: "place holder",
  },
  awardscertificate10: {
    image: require("../assets/image/awards/Certificates/Certifications (10).jpg"),
    alt: "place holder",
  },
  awardscertificate11: {
    image: require("../assets/image/awards/Certificates/Certifications (11).jpg"),
    alt: "place holder",
  },
  awardscertificate12: {
    image: require("../assets/image/awards/Certificates/Certifications (12).jpg"),
    alt: "place holder",
  },
  awardscertificate13: {
    image: require("../assets/image/awards/Certificates/Certifications_(13).jpg"),
    alt: "place holder",
  },
  


  // manufacturing process
  contact_bg: {
    image: require("../assets/image/contactus/contact_banner.jpg"),
    alt: "manufacturing banner",
  },
  manufacturing_bg: {
    image: require("../assets/image/manufacturing/manufacturing_bg.jpg"),
    alt: "manufacturing banner",
  },
  manufacturing1: {
    image: require("../assets/image/manufacturing/image1-(big) (1).jpg"),
    alt: "lab peeps",
  },
  manufacturing1_thumbnil: {
    image: require("../assets/image/manufacturing/image1-(small) (1).jpg"),
    alt: "lab peeps",
  },
  manufacturing2_thumbnil: {
    image: require("../assets/image/manufacturing/image2-(small) (1).jpg"),
    alt: "lab peeps",
  },
  manufacturing3_thumbnil: {
    image: require("../assets/image/manufacturing/Image3-(small) (1).jpg"),
    alt: "lab peeps",
  },
  manufacturing4_thumbnil: {
    image: require("../assets/image/manufacturing/image4-(small) (1).jpg"),
    alt: "lab peeps",
  },
  manufacturing5_thumbnil: {
    image: require("../assets/image/manufacturing/image5-(small) (1).jpg"),
    alt: "lab peeps",
  },
  manufacturing6_thumbnil: {
    image: require("../assets/image/manufacturing/image6-(small) (1).jpg"),
    alt: "lab peeps",
  },
  manufacturing2: {
    image: require("../assets/image/manufacturing/image2-(big) (1) (1).jpg"),
    alt: "FURNACE Night",
  },
  manufacturing3: {
    image: require("../assets/image/manufacturing/image3-(big) (1) (1).jpg"),
    alt: "pilpil",
  },
  manufacturing4: {
    image: require("../assets/image/manufacturing/image4-(big) (1) (1).jpg"),
    alt: "lab peeps",
  },
  manufacturing5: {
    image: require("../assets/image/manufacturing/image5-(big) (1) (1).jpg"),
    alt: "FURNACE Night",
  },
  manufacturing6: {
    image: require("../assets/image/manufacturing/image6-(big) (1) (1).jpg"),
    alt: "pilpil",
  },
};
