import React, { useState, useEffect, useRef } from "react";
import Banner from "../assets/about/about-baner.jpg";
import mobileBanner from "../assets/about/about-bg.jpg";
import Banner2 from "../assets/about2.png";
import { Breadcrumbs } from "@mui/material";
import { Link } from "react-router-dom";
import Home from "../assets/image/img31.png";
import cropVideo from "../assets/image/about_video_img.png";
import mbCropVideo from "../assets/image/mb_about_video_img.png";
import playIcon from "../assets/image/Play_Icon_white.svg";
import { isTablet, isSafari } from "react-device-detect";
import Missionbg from "../assets/award.png";
import mobileMissionBg from "../assets/about/about-mission-bg.png";
import { useWindowSize } from "react-use";
import { aboutData, heroData, historyData, missionData } from "../data";
import { motion } from "framer-motion";
// import Video from "../assets/video.mp4";
import indicator from "../assets/Rectangle 389.svg";
import { textVariants } from "../Header/Motion";
import LocomotiveScroll from "locomotive-scroll";
import Footer from "../Footer/Footer";
import PreLoader from "../loader/preloader";
import AnimatedText from "../common/AnimatedText";
const imagesLoaded = require("imagesloaded");
import Spinner from "react-bootstrap/Spinner";
import FsLightbox from "fslightbox-react";

const About = () => {
  const { width } = useWindowSize();
  const [data, setData] = useState(heroData);
  const [handleDrop, setHandleDrop] = useState(false);

  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [loading, setLoading] = useState(true);
  const [toggler, setToggler] = useState(false);

  const handlePlay = () => {
    videoRef.current.play();
  };

  const handlePause = () => {
    videoRef.current.pause();
  };

  const handleVideoStatus = () => {
    setIsPlaying(!videoRef.current.paused);
  };

  const handleVideoReady = () => {
    setLoading(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // smooth scroll
  const scrollRef = useRef(null);

  useEffect(() => {
    let scrollContainer = document.querySelector("[data-scroll-container]");
    const scroll = new LocomotiveScroll({
      el: scrollRef.current,
      smooth: true,
      getSpeed: true,
      getDirection: true,
      multiplier: 0.4, // Adjust the scroll speed here
    });

    scroll.update(); // Manually update the scroll height

    imagesLoaded(scrollContainer, { background: true }, function () {
      scroll.update();
    });

    const resizeObserver = new ResizeObserver(() => {
      scroll.update();
    });

    // start observing a DOM node
    resizeObserver.observe(document.body);

    return () => {
      resizeObserver.unobserve(document.body);

      scroll.destroy();
    };
  }, []);

  return (
    <>
      <PreLoader />
      <div
        data-scroll-container
        ref={scrollRef}
        style={{
          background: "#141414",
          backgroundImage: `url(${width > 767 ? Banner : mobileBanner})`,
          backgroundSize: width < 1024 || isTablet ? "100%" : "cover",
          overflow: "hidden",
          backgroundPosition: width < 1024 || isTablet ? "top" : "center",
          backgroundAttachment: "fixed",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div data-scroll-section>
          <section className="about_section" style={{ overflow: "hidden" }}>
            <div
              id="scrolltop"
              className="about_bg"
              // style={{ backgroundImage: `url(${Banner})` }}
            >
              <div className="about_breadcrumbs ">
                <Breadcrumbs separator=">" aria-label="breadcrumb">
                  <Link to="/">
                    <img src={Home} width={20} />{" "}
                  </Link>
                  <Link to="/about">ABOUT US</Link>
                </Breadcrumbs>
              </div>

              <div
                className="row"
                style={{ height: "100%" }}
                data-scroll
                data-scroll-direction="vertical"
                data-scroll-class="appear"
                data-scroll-repeat="true"
                data-scroll-speed="0.25"
              >
                <div className="col-12 col-md-6">
                  <div className="about_heading ">
                    <motion.div
                      variants={textVariants("left", 0.2)}
                      initial="hidden"
                      whileInView="show"
                      viewport={{ once: true, amount: 0.1 }}
                      transition={{ duration: 0.5 }}
                    >
                      <h1>ABOUT US</h1>
                      <div className="heading-d"></div>
                    </motion.div>
                  </div>
                </div>
                <div className="col-12 col-md-6 about_content">
                  <div className="about_right_section">
                    <motion.div
                      variants={textVariants("right", 0.2)}
                      initial="hidden"
                      whileInView="show"
                      viewport={{ once: true, amount: 0.1 }}
                      transition={{ duration: 0.5 }}
                    >
                      <div className="about_right_section_heading">
                        <h2>{aboutData.title}</h2>
                      </div>
                      <div className="about_right_section_content">
                        <p>{aboutData.content}</p>
                      </div>
                    </motion.div>
                  </div>
                </div>
              </div>

              <div className="scroll-down-d" style={{ zIndex: "5" }}>
                <div class="spinner scroll-down">
                  <a
                    style={{ cursor: "pointer" }}
                    href="#our_history_section"
                    data-scroll-to
                    class="animate"
                  ></a>
                </div>
              </div>
            </div>
          </section>

          <div className="about_sec_mob">
            <div className="about_right_section mobile_">
              <motion.div
                variants={textVariants("up", 0.2)}
                initial="hidden"
                whileInView="show"
                viewport={{ once: true, amount: 0.1 }}
                transition={{ duration: 0.5 }}
              >
                <div className="about_right_section_heading">
                  <h2>{aboutData.title}</h2>
                </div>
                <div className="about_right_section_content">
                  <p>{aboutData.content}</p>
                </div>
              </motion.div>
            </div>
          </div>

          <section
            className="our_history_section"
            id="our_history_section"
            style={{ overflow: "hidden" }}
            // data-scroll-section
          >
            <div className="our_history_bg">
              <div
                className=""
                data-scroll
                data-scroll-direction="vertical"
                data-scroll-class="appear"
                data-scroll-repeat="true"
                data-scroll-speed="0.25"
              >
                <div className="our_history_heading">
                  <motion.div
                    variants={textVariants("up", 0.2)}
                    initial="hidden"
                    whileInView="show"
                    viewport={{ once: true, amount: 0.1 }}
                    transition={{ duration: 0.5 }}
                  >
                    <h1>OUR HISTORY</h1>
                  </motion.div>
                </div>

                <div className="d-flex our-hist-main">
                  {/* <div className="main_h-se"> */}
                  {historyData?.map((data, index) => {
                    if ((index + 1) % 2 == 0) {
                      return (
                        <>
                          <div className="d-flex our-history-d">
                            <div className="main_h-se images_">
                              <motion.div
                                variants={textVariants("left", 0.2)}
                                initial="hidden"
                                whileInView="show"
                                viewport={{ once: true, amount: 0.1 }}
                                transition={{ duration: 0.5 }}
                                style={{ position: "relative", zIndex: "1" }}
                              >
                                <div className="our_history_right_section_img history_img_right_overlay sect-ssmain">
                                  <AnimatedText text={data.year} />
                                  {/* <h1 class="text-outline" data-text={data.year}>
                                  {data.year}
                                </h1> */}
                                  <img src={data.image} />
                                </div>
                              </motion.div>
                            </div>
                            <div
                              className="divider_radius_top"
                              style={{
                                width: "8.333333333333334vw",
                                overflow: "hidden",
                              }}
                            >
                              {/* <img src={indicator} /> */}
                            </div>
                            <div className="main_h-se content_">
                              <motion.div
                                variants={textVariants("right", 0.2)}
                                initial="hidden"
                                whileInView="show"
                                viewport={{ once: true, amount: 0.1 }}
                                transition={{ duration: 0.5 }}
                                style={{ position: "relative", zIndex: "1" }}
                              >
                                <div className="our_history_left_section section_twosec">
                                  <div className="our_history_left_heading">
                                    <h1>{data.title}</h1>
                                  </div>
                                  <div className="our_history_left_content">
                                    <p>{data.content}</p>
                                  </div>
                                </div>
                              </motion.div>
                            </div>
                          </div>
                        </>
                      );
                    } else {
                      return (
                        <>
                          <div className="d-flex our-history-d">
                            <div className="main_h-se content_">
                              <motion.div
                                variants={textVariants("left", 0.2)}
                                initial="hidden"
                                whileInView="show"
                                viewport={{ once: true, amount: 0.1 }}
                                transition={{ duration: 0.5 }}
                                style={{ position: "relative", zIndex: "1" }}
                              >
                                <div className="our_history_left_section">
                                  <div className="our_history_left_heading">
                                    <h1>{data.title}</h1>
                                  </div>
                                  <div className="our_history_left_content">
                                    <p>{data.content}</p>
                                  </div>
                                </div>
                              </motion.div>
                            </div>
                            <div
                              className="divider_radius_top"
                              style={{
                                width: "8.333333333333334vw",
                                overflow: "hidden",
                              }}
                            >
                              {/* <img src={indicator} /> */}
                            </div>
                            <div className="main_h-se images_">
                              <motion.div
                                variants={textVariants("right", 0.2)}
                                initial="hidden"
                                whileInView="show"
                                viewport={{ once: true, amount: 0.1 }}
                                transition={{ duration: 0.5 }}
                                style={{ position: "relative", zIndex: "1" }}
                              >
                                <div className="our_history_right_section_img history_img_left_overlay">
                                  <AnimatedText text={data.year} />
                                  {/* <h1 class="text-outline" data-text={data.year}>
                                  {data.year}
                                </h1> */}
                                  <img src={data.image} />
                                </div>
                              </motion.div>
                            </div>
                          </div>
                        </>
                      );
                    }
                  })}

                  <img src={indicator} className="history_divider" />
                </div>
              </div>
            </div>
          </section>

          <section
            className="mission_section"
            style={{ overflow: "hidden" }}
            // data-scroll-section
          >
            <div
              className={`mission_section_bg ${isSafari ? "ios-safari" : ""}`}
              style={
                {
                  // backgroundImage: `url(${
                  //   width > 767 ? Missionbg : mobileMissionBg
                  // })`,
                }
              }
            >
              <div
                className=" mission_section_content"
                data-scroll
                data-scroll-direction="vertical"
                data-scroll-class="appear"
                data-scroll-repeat="true"
                data-scroll-speed="0.25"
              >
                {missionData?.map((data, index) => {
                  if ((index + 1) % 2 == 0) {
                    return (
                      <>
                        <div className="row even_row">
                          <div className="mission_section_two_left col-lg-6 col-md-6 col-sm-12 col-12 ">
                            <motion.div
                              variants={textVariants("lrft", 0.2)}
                              initial="hidden"
                              whileInView="show"
                              viewport={{ once: true, amount: 0.1 }}
                              transition={{ duration: 0.5 }}
                            >
                              <h3>{data.title}</h3>
                              <p>{data.content}</p>
                            </motion.div>
                          </div>
                          <div className="mission_section_two_right col-lg-6 col-md-6 col-sm-12 col-12">
                            <motion.div
                              variants={textVariants("right", 0.2)}
                              initial="hidden"
                              whileInView="show"
                              viewport={{ once: true, amount: 0.1 }}
                              transition={{ duration: 0.5 }}
                            >
                              <div className="mission_section_two_right_heading">
                                <AnimatedText text={data.title} />
                                {/* <h1 className="text-outline" data-text={data.title}>{data.title}</h1> */}
                              </div>
                              <div className="mission_section_two_right_img">
                                <img src={data.image} />
                              </div>
                            </motion.div>
                          </div>
                        </div>
                      </>
                    );
                  } else {
                    return (
                      <>
                        <div className="row">
                          <div className="mission_section_one_left col-lg-6 col-md-6 col-sm-12 col-12 ">
                            <motion.div
                              variants={textVariants("left", 0.2)}
                              initial="hidden"
                              whileInView="show"
                              viewport={{ once: true, amount: 0.1 }}
                              transition={{ duration: 0.5 }}
                            >
                              <div className="mission_section_one_left_heading">
                                <AnimatedText text={data.title} />
                                {/* <h1 className="text-outline" data-text={data.title}>{data.title}</h1> */}
                              </div>
                              <div className="mission_section_one_left_img">
                                <img src={data.image} />
                              </div>
                            </motion.div>
                          </div>
                          <div className="mission_section_one_right col-lg-6 col-md-6 col-sm-12 col-12">
                            <motion.div
                              variants={textVariants("right", 0.2)}
                              initial="hidden"
                              whileInView="show"
                              viewport={{ once: true, amount: 0.1 }}
                              transition={{ duration: 0.5 }}
                            >
                              <h3>{data.title}</h3>
                              <p>{data.content}</p>
                            </motion.div>
                          </div>
                        </div>
                      </>
                    );
                  }
                })}
              </div>
            </div>
          </section>

          <section
            className="corporate_section"
            id="corporate"
            style={{ overflow: "hidden" }}
            // data-scroll-section
          >
            <div className="corporate_bg">
              <div className="">
                <motion.div
                  variants={textVariants("up", 0.2)}
                  initial="hidden"
                  whileInView="show"
                  viewport={{ once: true, amount: 0.1 }}
                  transition={{ duration: 0.5 }}
                >
                  <div className="corporate_section_heading">
                    <h1>Corporate Video</h1>
                  </div>
                </motion.div>
                {/* <Player poster={Banner} src={data[0]?.media} /> */}
                <motion.div
                  variants={textVariants("up", 0.2)}
                  initial="hidden"
                  whileInView="show"
                  viewport={{ once: true, amount: 0.1 }}
                  transition={{ duration: 0.5 }}
                >
                  {/* <figure class="bg figure" style={{ position: "relative" }}>
                    <video
                      ref={videoRef}
                      onPlay={handleVideoStatus}
                      onPause={handleVideoStatus}
                      onEnded={handleVideoStatus}
                      onReady={handleVideoReady}
                      class="img-cover"
                      style={{
                        opacity: `${isPlaying || loading ? "1" : "0.5"}`,
                      }}
                      loop
                      autoPlay
                      muted
                      playsInline
                      preload="auto"
                    >
                      <source src={aboutData.corporateVideo} />
                      Your browser does not support the video tag.
                    </video>

                    {loading ? (
                      <div className="controls_d">
                        {!isPlaying ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="97"
                            height="97"
                            viewBox="0 0 97 97"
                            onClick={handlePlay}
                          >
                            <g
                              id="Group_3448"
                              data-name="Group 3448"
                              transform="translate(-912 -7919)"
                            >
                              <circle
                                id="Ellipse_21"
                                data-name="Ellipse 21"
                                cx="48.5"
                                cy="48.5"
                                r="48.5"
                                transform="translate(912 7919)"
                                fill="#fff"
                                opacity="0.31"
                              />
                            </g>
                            <path
                              id="Polygon_2"
                              data-name="Polygon 2"
                              d="M10.5,0,21,18H0Z"
                              transform="translate(60, 38) rotate(90)"
                              fill="#fff"
                            />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="97"
                            height="97"
                            viewBox="0 0 97 97"
                            onClick={handlePause}
                          >
                            <g
                              id="Group_3448"
                              data-name="Group 3448"
                              transform="translate(-912 -7919)"
                            >
                              <circle
                                id="Ellipse_21"
                                data-name="Ellipse 21"
                                cx="48.5"
                                cy="48.5"
                                r="48.5"
                                transform="translate(912 7919)"
                                fill="#fff"
                                opacity="0.31"
                              />
                              <g id="push">
                                <line
                                  id="Line_115"
                                  data-name="Line 115"
                                  y2="21"
                                  transform="translate(957.5 7958.5)"
                                  fill="none"
                                  stroke="#fff"
                                  stroke-width="5"
                                />
                                <line
                                  id="Line_116"
                                  data-name="Line 116"
                                  y2="21"
                                  transform="translate(966.5 7958.5)"
                                  fill="none"
                                  stroke="#fff"
                                  stroke-width="5"
                                />
                              </g>
                            </g>
                          </svg>
                        )}
                      </div>
                    ) : (
                      <div className="controls_d">
                        <Spinner
                          animation="border"
                          role="status"
                          style={{ color: "#FFFFFF" }}
                        >
                          <span className="visually-hidden">Loading...</span>
                        </Spinner>
                      </div>
                    )}
                  </figure> */}
                  {/* <iframe
                    width="100%"
                    height={width > 850 ? 850 : 300}
                    src="https://www.youtube.com/embed/l8E1BvbXlUw?si=KA-kmM18EsKdYuB7&amp;controls=0?autoplay=1?muted=1"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe> */}
                  {/* Fslightbox start */}
                  <div
                    className="about_video_wrapper"
                    onClick={() => setToggler(!toggler)}
                  >
                    <img
                      src={width > 600 ? cropVideo : mbCropVideo}
                      alt="video banner"
                      className="video_banner"
                    />
                    <img
                      src={playIcon}
                      alt="play button"
                      className="play_btn"
                    />
                  </div>
                </motion.div>
                {/* <Player poster={Banner} src={data[0]?.media} >
              <BigPlayButton position="center" />
            </Player> */}
              </div>
            </div>
          </section>
        </div>
        <section
          className="footer_area"
          data-scroll-section
          style={{
            overflowY: "hidden",
          }}
        >
          <Footer />
        </section>
      </div>
      <FsLightbox
        toggler={toggler}
        sources={[
          "https://www.youtube.com/watch?v=l8E1BvbXlUw&t=1s&ab_channel=Multimetals",
        ]}
      />
    </>
  );
};

export default About;
