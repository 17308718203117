import React, { useEffect, useRef } from "react";
import "./privacypolicyv2.scss";
import { useWindowSize } from "react-use";
import Commonbanner from "../../Common Banner/CommonBanner";
import PreLoader from "../../loader/preloader";
import Footer from "../../Footer/Footer";
import { images } from "../../assets";

const PrivacyPolicyV2 = () => {
  const scrollRef = useRef(null);
  const { width } = useWindowSize();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <PreLoader />
      <div
        data-scroll-container
        ref={scrollRef}
        style={{
          background: "#141414",
          backgroundImage: `url(${images.contact_bg.image})`,
          backgroundSize: width > 600 ? "cover" : "contain",
          overflow: "hidden",
          backgroundPosition: "center",
          backgroundAttachment: "fixed",
        }}
      >
        <section
          id="scrolltop"
          className="privacy_sec1"
          style={{ overflow: "hidden" }}
          data-scroll-section
        >
          <Commonbanner
            linkText1="PRIVACY POLICY"
            link1="/privacy-policy"
            headingtext="Privacy Policy"
            scrollId="#privacy"
          />
        </section>

        <section className="privacy_sec2" id="privacy">
          <div className="my_container">
            <div className="para_wrapper">
              <p className="para">
                Welcome to Multi Metals India. We respect your privacy and are
                committed to protecting your personal data. This privacy policy
                will inform you about how we look after your personal data when
                you visit our website (regardless of where you visit it from)
                and tell you about your privacy rights and how the law protects
                you.
              </p>
            </div>

            <div className="para_wrapper">
              <h4 className="heading">1. Information We Collect</h4>
              <p className="para">
                We may collect, use, store, and transfer different kinds of
                personal data about you which we have grouped together as
                follows:
              </p>
              <ul className="list_wrapper">
                <li>
                  Identity Data includes first name, last name, username or
                  similar identifier.
                </li>
                <li>
                  Contact Data includes email address and telephone numbers.
                </li>
                <li>
                  Technical Data includes internet protocol (IP) address, your
                  login data, browser type and version, time zone setting and
                  location, browser plug-in types and versions, operating system
                  and platform, and other technology on the devices you use to
                  access this website.
                </li>
                <li>
                  Usage Data includes information about how you use our website,
                  products, and services.
                </li>
                <li>
                  Marketing and Communications Data includes your preferences in
                  receiving marketing from us and our third parties and your
                  communication preferences.
                </li>
              </ul>
            </div>

            <div className="para_wrapper">
              <h4 className="heading">2. How We Use Your Personal Data</h4>
              <p className="para">
                We will only use your personal data when the law allows us to.
                Most commonly, we will use your personal data in the following
                circumstances:
              </p>
              <ul className="list_wrapper">
                <li>To register you as a new customer.</li>
                <li>To process and deliver your orders.</li>
                <li>To manage our relationship with you.</li>
                <li>
                  To enable you to partake in a prize draw, competition, or
                  complete a survey.
                </li>
                <li>
                  To improve our website, products/services, marketing, customer
                  relationships, and experiences.
                </li>
                <li>
                  To recommend products or services which may be of interest to
                  you.
                </li>
              </ul>
            </div>

            <div className="para_wrapper">
              <h4 className="heading">3. Cookies</h4>
              <p className="para">
                We use cookies and similar tracking technologies to track the
                activity on our website and hold certain information. Cookies
                are files with a small amount of data which may include an
                anonymous unique identifier. You can instruct your browser to
                refuse all cookies or to indicate when a cookie is being sent.
                However, if you do not accept cookies, you may not be able to
                use some portions of our website.
              </p>
            </div>
            <div className="para_wrapper">
              <h4 className="heading">4. Disclosure of Your Personal Data</h4>
              <p className="para">
                We may have to share your personal data with the parties set out
                below for the purposes set out in paragraph 2 above:
              </p>
              <ul className="list_wrapper">
                <li>
                  External Third Parties Service Providers who provide IT and
                  system administration services.
                </li>
                <li>
                  Professional advisers including lawyers, bankers, auditors,
                  and insurers.
                </li>
                <li>
                  Government bodies that require us to report processing
                  activities.
                </li>
              </ul>
            </div>
            <div className="para_wrapper">
              <h4 className="heading">5. Data Security</h4>
              <p className="para">
                We have put in place appropriate security measures to prevent
                your personal data from being accidentally lost, used, or
                accessed in an unauthorized way, altered, or disclosed. We limit
                access to your personal data to those employees, agents,
                contractors, and other third parties who have a business need to
                know.
              </p>
            </div>
            <div className="para_wrapper">
              <h4 className="heading">6. Your Legal Rights</h4>
              <p className="para">
                Under certain circumstances, you have rights under data
                protection laws in relation to your personal data, including the
                right to request access, correction, erasure, restriction,
                transfer, to object to processing, to portability of data, and
                (where the lawful ground of processing is consent) to withdraw
                consent.
              </p>
            </div>
            <div className="para_wrapper">
              {/* <h4 className="heading">7. How to Contact Us</h4>
              <p className="para">
                If you have any questions about this privacy policy or our
                privacy practices, please contact us in the following ways:
              </p>
              <ul className="list_wrapper">
                <li>
                  Email address:{" "}
                  <a
                    href="mailto:inquiry@multimetalsindia.com"
                    className="privacy_link"
                  >
                    inquiry@multimetalsindia.com
                  </a>
                  [3]
                </li>
                <li>
                  Postal address:{" "}
                  <a
                    href="https://maps.app.goo.gl/rCFtNopF9TT1whvt7"
                    className="privacy_link"
                    target="_blank"
                    rel="noreferrer"
                  >
                    57, C.P. Tank Rd., Shop No. 1, Old Sonawala Bldg’, Mumbai –
                    400 004. Maharashtra, INDIA
                  </a>
                  [8]
                </li>
                <li>
                  Telephone number: Office 1:{" "}
                  <a href="tel:+9102266362301" className="privacy_link">
                    +91 022 6636 2301
                  </a>
                  , Office 2:{" "}
                  <a href="tel:+9102266518926" className="privacy_link">
                    +91 022 66518926
                  </a>
                  [2]
                </li>
              </ul>
              <p className="para top_space">
                We may update this privacy policy from time to time by
                publishing a new version on our website.
              </p> */}
              <p className="para">
                This privacy policy was created using a template to provide a
                basic structure for Multi Metals India. It is essential to
                customize the policy to reflect the specific data collection,
                storage, and processing activities of the company.
              </p>
            </div>
          </div>
        </section>
      </div>

      <section
        className="footer_area"
        data-scroll-section
        style={{
          overflowY: "hidden",
        }}
      >
        <Footer />
      </section>
    </>
  );
};

export default PrivacyPolicyV2;
