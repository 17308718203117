import { React, useEffect, useRef } from "react";
import "./manufacturingprocessinner.scss";
import { annealingFurnace, leftArrow } from "../../assets";
import LocomotiveScroll from "locomotive-scroll";
import { textVariants } from "../../Header/Motion";
import { motion } from "framer-motion";
import Footer from "../../Footer/Footer";
import PreLoader from "../../loader/preloader";
import { Link } from "react-router-dom";
import LeftArrowCtaSvg from "../../common/leftarrowCtaSvg";
const imagesLoaded = require("imagesloaded");

const Annealingfurnace = () => {
  const scrollRef = useRef(null);

  useEffect(() => {
    let scrollContainer = document.querySelector("[data-scroll-container]");
    const scroll = new LocomotiveScroll({
      el: scrollRef.current,
      smooth: true,
      getSpeed: true,
      getDirection: true,
      multiplier: 0.4, // Adjust the scroll speed here
    });

    scroll.update(); // Manually update the scroll height

    imagesLoaded(scrollContainer, { background: true }, function () {
      scroll.update();
    });
    const resizeObserver = new ResizeObserver((entries) => {
      scroll.update();
    });

    // start observing a DOM node
    resizeObserver.observe(document.body);

    return () => {
      resizeObserver.unobserve(document.body);

      scroll.destroy();
    };
  }, []);
  return (
    <>
      <PreLoader />
      <div
        data-scroll-container
        ref={scrollRef}
        style={{
          background: "#141414",
          overflow: "hidden",
        }}
      >
        {/* <Commonbanner2
          linkText="ABOUT US"
          link="/about"
          linkText1="MANUFACTURING PROCESS"
          link1="/manufacturing-process"
          linkText2="ANNEALING FURNACE"
          link2="/annealing-furnace"
        /> */}
        <section id="scrolltop" className="annealing_furnace_sec1">
          <Link to="/manufacturing-process" className="back_btn_wrapper">
            <div className="back_cta_arrow">
              <LeftArrowCtaSvg />
            </div>
            <button className="back_btn"> Back</button>
          </Link>
          <div className="row annealing_furnace_container">
            <div className="col-md-5 annealing_content">
              <motion.div
                variants={textVariants("left", 0.2)}
                initial="hidden"
                whileInView="show"
                viewport={{ once: true, amount: 0.1 }}
                transition={{ duration: 0.5 }}
              >
                <h2 className="annealing_heading">Annealing Furnace</h2>
                <p className="annealing_details">
                  After the drawing or pilgering process, the tubes undergo
                  annealing, which is carried out in a specialized annealing
                  furnace. The annealing furnace heats the tubes to a specific
                  temperature and then slowly cools them to relieve any internal
                  stresses and improve their mechanical properties. This thermal
                  treatment process helps to enhance the ductility and toughness
                  of the tubes, making them more malleable and resistant to
                  deformation. The annealing process also improves the overall
                  stability and reliability of the tubes, ensuring their
                  long-term performance and durability.
                </p>
              </motion.div>
            </div>
            <div className="col-md-7 annealing_content">
              <motion.div
                variants={textVariants("right", 0.2)}
                initial="hidden"
                whileInView="show"
                viewport={{ once: true, amount: 0.1 }}
                transition={{ duration: 0.5 }}
              >
                <img
                  src={annealingFurnace}
                  alt="Annealing Furnace"
                  className="annealing_img"
                />
              </motion.div>
            </div>
          </div>
        </section>
        <section
          className="footer_area"
          style={{
            overflowY: "hidden",
          }}
        >
          <Footer />
        </section>
      </div>
    </>
  );
};

export default Annealingfurnace;
