import React, { useEffect, useRef } from "react";
import Banner from "../assets/Gallery/gallery-banner.jpg";
import mobileBg from '../assets/Gallery/gallery-bg.jpg'
import { Breadcrumbs } from "@mui/material";
import { Link } from "react-router-dom";
import { Form } from "react-bootstrap";
import Home from "../assets/image/img31.png";
import LabTabs from "./Tabs";
import { useWindowSize } from "react-use";
import { textVariants } from "../Header/Motion";
import { motion } from "framer-motion";
import LocomotiveScroll from "locomotive-scroll";
import Footer from "../Footer/Footer";
import CTASvg from "../common/CTASvg";
import PreLoader from "../loader/preloader";
const imagesLoaded = require("imagesloaded");
import { isTablet, isSafari } from 'react-device-detect';

const Gallery = () => {

  const { width } = useWindowSize();


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // smooth scroll
  const scrollRef = useRef(null);


  useEffect(() => {
    let scrollContainer = document.querySelector("[data-scroll-container]");
    const scroll = new LocomotiveScroll({
      el: scrollRef.current,
      smooth: true,
      getSpeed: true,
      getDirection: true,
      multiplier: 0.4, // Adjust the scroll speed here
    });

    scroll.update(); // Manually update the scroll height

    imagesLoaded(scrollContainer, { background: true }, function () {
      scroll.update();
    });

    const resizeObserver = new ResizeObserver(() => {

      scroll.update();
    });

    // start observing a DOM node
    resizeObserver.observe(document.body);

    return () => {
      resizeObserver.unobserve(document.body);
  
        scroll.destroy();
      }
      
  }, []);



  return (
    <>
      <PreLoader />
      <div
        data-scroll-container
        ref={scrollRef}
        style={{
          background: "#141414",
          backgroundImage: `url(${width > 767 ? Banner : mobileBg})`,
          backgroundSize: (width < 1024 || isTablet) ? "100%" : "cover",
          overflow: "hidden",
          backgroundPosition: (width < 1024 || isTablet) ? "top" : "center",
          backgroundAttachment: "fixed",
          backgroundRepeat: "no-repeat"
        }}
      >

        <div data-scroll-section>
          <section className="gallery_section" style={{ overflow: "hidden" }}>
            <div
              id="scrolltop"
              className="gallery_bg"
            // style={{ backgroundImage: `url(${Banner})` }}
            >
              <div className="gallery_breadcrumbs ">
                <Breadcrumbs separator=">" aria-label="breadcrumb">
                  <Link to="/">
                    <img src={Home} width={20} />{" "}
                  </Link>
                  <Link to="/about">About US</Link>
                  <Link to="/gallery">Gallery</Link>
                </Breadcrumbs>
              </div>
              <div className="gallery_heading text-center "
                data-scroll
                data-scroll-direction="vertical"
                data-scroll-class="appear"
                data-scroll-repeat="true"
                data-scroll-speed="0.25"
              >
                <motion.div
                  variants={textVariants("up", 0.2)}
                  initial="hidden"
                  whileInView="show"
                  viewport={{ once: true, amount: 0.1 }}
                  transition={{ duration: 0.5 }}
                  className=""
                >
                  <h1>Gallery</h1>
                  <div className="heading-d"></div>
                </motion.div>
                <h2 className="content_ heading">
                  STEP INTO MULTIMETALS WORLD
                </h2>
                <div className="banner_gallery_button">
                  <motion.div
                    variants={textVariants("up", 0.2)}
                    initial="hidden"
                    whileInView="show"
                    viewport={{ once: true, amount: 0.1 }}
                    transition={{ duration: 0.5 }}
                  >
                    <Link to="/contact-us">
                      <button classname="btn rounded-pill" style={{ width: '100%' }}>
                      Contact Us &nbsp; &nbsp;
                        <CTASvg />
                      </button>
                    </Link>
                  </motion.div>
                </div>
              </div>
              <div className="scroll-down-d" style={{ zIndex: "5" }}>
                <div class="spinner scroll-down">
                  <a
                    style={{ cursor: "pointer" }}
                    href="#gallery_section"
                    data-scroll-to
                    class="animate"

                  ></a>
                </div>
              </div>
            </div>
          </section>

          <section className="gallery_img_section" id="gallery_section" style={{ overflow: "hidden" }}>
            <div className="gallery_img_bg">
              <div className="">
                <LabTabs />
                {/* <div className="gallery_load_more_btn">
                  <button>LOAD MORE</button>
                </div> */}
              </div>
            </div>
          </section>
        </div>
        <section
          className="footer_area"
          data-scroll-section
          style={{
            overflowY: "hidden",
          }}
        >
          <Footer />
        </section>
      </div>
    </>
  );
};

export default Gallery;
