import React, { useState, useEffect } from "react";
import "./productlisting.scss";
import { images, productInnerBanner } from "../../assets";
import { Breadcrumbs } from "@mui/material";
import { Link, useParams, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { textVariants } from "../../Header/Motion";
import { Button } from "react-bootstrap";
import { useWindowSize } from "react-use";
import { findCategoryBySlug, findAllProducts } from "../../data/productsData";
import LocomotiveScroll from "locomotive-scroll";
import { useRef } from "react";
import Footer from "../../Footer/Footer";
import PreLoader from "../../loader/preloader";
import videoHome from "../../assets/PromoMM.mp4";
const imagesLoaded = require("imagesloaded");

// const productsArr = [
//   {
//     image: images.brassalloy.image,
//     title: "Phosphor Bronze Alloy",
//     description:
//       "Springs, Plated Wire, Musical Instruments, Washers, Connectors, Plated Wire, Anchor Wire for tooth Brush",
//     slug: "#",
//   },
//   {
//     image: images.brassalloy.image,
//     title: "Phosphor Bronze Alloy",
//     description:
//       "Springs, Plated Wire, Musical Instruments, Washers, Connectors, Plated Wire, Anchor Wire for tooth Brush",
//     slug: "#",
//   },
// ];

const ProductListing = () => {
  const { width: windowWidth } = useWindowSize();
  let { categorySlug } = useParams();
  const scrollRef = useRef(null);

  const [products, setProducts] = useState([]);
  const [category, setCategory] = useState({});
  let navigate = useNavigate();

  console.log({ categorySlug });
  console.log("category image", category?.backgroundImg);
  useEffect(() => {
    const categoryData = findCategoryBySlug(categorySlug);

    if (!categoryData) {
      navigate("/404");
    } else {
      const productsData = findAllProducts(categoryData?.slug);
      console.log({ productsData });
      setCategory(categoryData);
      setProducts(productsData);
    }
  }, [categorySlug]);

  const productList = products.map((item, i) => {
    if (i % 2 === 0) {
      return (
        <>
          <div className="product_row">
            <motion.div
              variants={textVariants("left", 0.2)}
              initial="hidden"
              whileInView="show"
              viewport={{ once: true, amount: 0.1 }}
              transition={{ duration: 0.5 }}
              className="content_box"
            >
              <h4 className="product_name">{item.title}</h4>
              <p className="product_subheading">Product Applications areas :</p>
              <p className="product_desc">{item.application_area}</p>
              <div className="cta_wrapper hero-btn-d">
                <motion.div
                  className="cta"
                  variants={textVariants("left", 0.2)}
                  initial="hidden"
                  whileInView="show"
                  viewport={{ once: true, amount: 0.1 }}
                  transition={{ duration: 0.5 }}
                >
                  <Link to={`/products/${category?.slug}/${item?.slug}`}>
                    <Button>
                      Know More
                      <svg
                        stroke="white"
                        fill="none"
                        stroke-width="2"
                        viewBox="0 0 24 24"
                        aria-hidden="true"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M17 8l4 4m0 0l-4 4m4-4H3"
                        ></path>
                      </svg>
                    </Button>
                  </Link>
                </motion.div>
              </div>
            </motion.div>
            <div className="img_wrapper">
              {windowWidth > 992 ? (
                <motion.img
                  variants={textVariants("right", 0.2)}
                  initial="hidden"
                  whileInView="show"
                  viewport={{ once: false, amount: 0.1 }}
                  transition={{ duration: 0.5 }}
                  src={item.listingImage}
                  alt="product"
                  className="product_img"
                  loading="lazy"
                />
              ) : (
                <img
                  src={item.listingImage}
                  alt="product"
                  className="product_img"
                  loading="lazy"
                />
              )}
            </div>
          </div>
        </>
      );
    } else {
      return (
        <div className="product_row product_row2">
          <div className="img_wrapper">
            {windowWidth > 992 ? (
              <motion.img
                variants={textVariants("left", 0.2)}
                initial="hidden"
                whileInView="show"
                viewport={{ once: false, amount: 0.1 }}
                transition={{ duration: 0.5 }}
                src={item.listingImage}
                alt="product"
                className="product_img"
                loading="lazy"
              />
            ) : (
              <img
                src={item.listingImage}
                alt="product"
                className="product_img"
                loading="lazy"
              />
            )}
          </div>
          <motion.div
            variants={textVariants("right", 0.2)}
            initial="hidden"
            whileInView="show"
            viewport={{ once: true, amount: 0.1 }}
            transition={{ duration: 0.5 }}
            className="content_box"
          >
            <h4 className="product_name">{item.title}</h4>
            <p className="product_subheading">Product Applications areas :</p>
            <p className="product_desc">{item.application_area}</p>
            <div className="cta_wrapper hero-btn-d">
              <motion.div
                className="cta"
                variants={textVariants("left", 0.2)}
                initial="hidden"
                whileInView="show"
                viewport={{ once: true, amount: 0.1 }}
                transition={{ duration: 0.5 }}
              >
                <Link to={`/products/${category?.slug}/${item?.slug}`}>
                  <Button>
                    Know More
                    <svg
                      stroke="white"
                      fill="none"
                      stroke-width="2"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M17 8l4 4m0 0l-4 4m4-4H3"
                      ></path>
                    </svg>
                  </Button>
                </Link>
              </motion.div>
            </div>
          </motion.div>
        </div>
      );
    }
  });

  useEffect(() => {
    let scrollContainer = document.querySelector("[data-scroll-container]");
    const categoryData = findCategoryBySlug(categorySlug);
    const scroll = new LocomotiveScroll({
      el: scrollRef.current,
      smooth: true,
      getSpeed: true,
      getDirection: true,
      multiplier: 0.4, // Adjust the scroll speed here
    });

    imagesLoaded(scrollContainer, { background: true }, function () {
      scroll.update();
    });

    const resizeObserver = new ResizeObserver((entries) => {
      scroll.update();
    });

    // start observing a DOM node
    resizeObserver.observe(document.body);

    return () => {
      resizeObserver.unobserve(document.body);
  
        scroll.destroy();
      }
      
  }, [categorySlug]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <PreLoader />
      <div
        data-scroll-container
        ref={scrollRef}
        style={{
          background: "#141414",
          backgroundImage: `url(${
            category.type === "image" ? category?.backgroundImg : ""
          })`,
          backgroundSize: windowWidth > 600 ? "cover" : "contain",
          overflow: "hidden",
          backgroundPosition: "center",
          backgroundAttachment: "fixed",
        }}
      >
        {category.type === "video" ? (
          <figure class="bg figure">
            <video
              style={{ position: "absolute" }}
              class="img-cover speciality-img-cover"
              src={category?.backgroundImg}
              loop
              autoPlay
              muted
              playsInline
              preload="auto"
            ></video>
          </figure>
        ) : (
          ""
        )}
        <section
          id="scrolltop"
          className="prodlist_sec1"
          style={{ overflow: "hidden" }}
          data-scroll-section
        >
          <div
            className="gallery_bg"
            style={{
              backgroundImage: `url(${
                windowWidth < 600 || category.type === "image"
                  ? category?.backgroundImg
                  : ""
              })`,
            }}
          >
            {/* {category.type === "video" ? (
              <figure id="scrolltop" class="bg figure">
                <video
                  style={{ position: "fixed" }}
                  class="img-cover speciality-img-cover"
                  src={category?.backgroundImg}
                  loop
                  autoPlay
                  muted
                  playsInline
                  preload="auto"
                ></video>
              </figure>
            ) : (
              ""
            )} */}
            <div className="gallery_breadcrumbs ">
              <Breadcrumbs separator=">" aria-label="breadcrumb">
                <Link to="/">
                  <img
                    src={images.homeicon.image}
                    alt={images.homeicon.alt}
                    loading="lazy"
                    width={20}
                  />{" "}
                </Link>
                <Link style={{ pointerEvents: "none" }}>Product</Link>
                <Link to={`/products/${category?.slug}`}>
                  {" "}
                  {category?.title}
                </Link>
              </Breadcrumbs>
            </div>
            <div className="row">
              <div className="col-12 col-md-6" style={{ height: "100%" }}>
                <div className="product_heading">
                  <motion.div
                    variants={textVariants("left", 0.2, 2.5)}
                    initial="hidden"
                    whileInView="show"
                    viewport={{ once: true, amount: 0.1 }}
                    transition={{ duration: 0.5 }}
                  >
                    <h1>{category?.title}</h1>
                  </motion.div>
                </div>
              </div>
              <div className={windowWidth > 600 ? "col-md-6" : ""}>
                {windowWidth > 600 ? (
                  <div className="about_wrapper">
                    <motion.div
                      variants={textVariants("right", 0.2, 2.5)}
                      initial="hidden"
                      whileInView="show"
                      viewport={{ once: true, amount: 0.1 }}
                      transition={{ duration: 0.5 }}
                    >
                      <h6 className="heading">About Product</h6>
                      <p className="description">{category?.description}</p>
                    </motion.div>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="scroll-down-d" style={{ zIndex: "5" }}>
              <div className="spinner scroll-down">
                <a
                  style={{ cursor: "pointer" }}
                  href="#productlisting"
                  data-scroll-to
                  className="animate"
                ></a>
              </div>
            </div>
          </div>
          {windowWidth < 600 ? (
            <div className="container mobile_color">
              <div className="about_wrapper">
                <motion.div
                  variants={textVariants("right", 0.2)}
                  initial="hidden"
                  whileInView="show"
                  viewport={{ once: true, amount: 0.1 }}
                  transition={{ duration: 0.5 }}
                >
                  <h6 className="heading">About Product</h6>
                  <p className="description">{category?.description}</p>
                </motion.div>
              </div>
            </div>
          ) : null}
        </section>
        <section
          id="productlisting"
          className="prodlist_sec2"
          style={{ overflow: "hidden" }}
          data-scroll-section
        >
          <div className="container">{productList}</div>
          {/* <div className="load_more_cta">
            <Link className="link">Load More</Link>
          </div> */}
        </section>{" "}
        <section
          className="footer_area"
          data-scroll-section
          style={{
            overflowY: "hidden",
          }}
        >
          <Footer />
        </section>
      </div>
    </>
  );
};

export default ProductListing;
