import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Image1 from "../assets/image/img40.png";
import Image2 from "../assets/image/img15.png";
import Image3 from "../assets/image/img2.png";
import Image4 from "../assets/image/img25.png";
import Image5 from "../assets/image/img40.png";
import Image6 from "../assets/image/img23.png";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import { textVariants } from "../Header/Motion";
import { motion } from "framer-motion";
import upArrow from '../assets/Gallery/up-arrow.png';
import downArrow from '../assets/Gallery/down-arrow.png';
import { galleryData } from "../data";
import LocomotiveScroll from "locomotive-scroll";
const imagesLoaded = require("imagesloaded");

export default function LabTabs() {


  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function srcset(image, size, rows = 1, cols = 1) {
    return {
      src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
      srcSet: `${image}?w=${size * cols}&h=${size * rows
        }&fit=crop&auto=format&dpr=2 2x`,
    };
  }

  // gallery image row col 
  let cols = [4, 2, 2, 2, 4, 2];
  let rows = [1, 2, 1, 1, 1, 1];

  const itemData = [
    {
      img: Image1,

    },
    {
      img: Image2,

    },
    {
      img: Image3,

    },
    {
      img: Image4,

    },
    {
      img: Image6,

    },
    {
      img: Image6,

    },
  ];



  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <TabContext value={value}>
        <motion.div
          variants={textVariants("up", 0.2)}
          initial="hidden"
          whileInView="show"
          viewport={{ once: true, amount: 0.1 }}
          transition={{ duration: 0.5 }}
        >
          <Box>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              {galleryData?.map((gallery) =>
                <Tab icon={<img src={value == gallery.id ? upArrow : downArrow} width="4vw" height="4vw" />} iconPosition="end" label={gallery.title} value={gallery.id.toString()} />
              )}
              {/* <Tab icon={<img src={downArrow} width="4vw" height="4vw" />} iconPosition="end" label="Infrastructure" value="2" />
              <Tab icon={<img src={downArrow} width="4vw" height="4vw" />} iconPosition="end" label="Exhibitions" value="3" />
              <Tab icon={<img src={downArrow} width="4vw" height="4vw" />} iconPosition="end" label="Other" value="4" /> */}
            </TabList>
          </Box>
        </motion.div>
        {galleryData?.map((gallery) => {
          return (
            <TabPanel value={gallery.id.toString()}>
              <motion.div
                variants={textVariants("up", 0.2)}
                initial="hidden"
                whileInView="show"
                viewport={{ once: true, amount: 0.1 }}
                transition={{ duration: 0.5 }}
              >
                <ImageList

                  variant="quilted"
                  cols={6}
                  rowHeight={121}
                  gap={"2.65625vw"}
                >
                  {gallery.images.map((item, index) => {
                    let rowVal = rows[index % rows.length];
                    let colVal = cols[index % cols.length];
                    return (
                      <ImageListItem key={item.img} cols={colVal || 1} rows={rowVal || 1} style={{ maxHeight: `${rowVal == 2 ? (24 * rowVal) + 2.65625 : 24 * rowVal}vw` }}>
                        <img
                          {...srcset(item.img, 121, rowVal, colVal)}
                          alt={item.title}
                          loading="lazy"
                          height={121}
                        />
                      </ImageListItem>
                    )
                  })}
                </ImageList>

              </motion.div>
            </TabPanel>
          )
        })
        }
        {/* <TabPanel value="1">
          <motion.div
            variants={textVariants("up", 0.2)}
            initial="hidden"
            whileInView="show"
            viewport={{ once: true, amount: 0.1 }}
            transition={{ duration: 0.5 }}
          >
            <ImageList

              variant="quilted"
              cols={6}
              rowHeight={121}
              gap={"2.65625vw"}
            >
              {galleryData[0].images.map((item, index) => {
                let rowVal = rows[index % rows.length];
                let colVal = cols[index % cols.length];
                return (
                  <ImageListItem key={item.img} cols={colVal || 1} rows={rowVal || 1}>
                    <img
                      {...srcset(item.img, 121, rowVal, colVal)}
                      alt={item.title}
                      loading="lazy"
                    />
                  </ImageListItem>
                )
              })}
            </ImageList>

          </motion.div>
        </TabPanel>
        <TabPanel value="2">
          <motion.div
            variants={textVariants("up", 0.2)}
            initial="hidden"
            whileInView="show"
            viewport={{ once: true, amount: 0.1 }}
            transition={{ duration: 0.5 }}
          >
            <ImageList

              variant="quilted"
              cols={6}
              rowHeight={121}
              gap={"2.65625vw"}
            >
              {galleryData[1].images.map((item, index) => {
                let rowVal = rows[index % rows.length];
                let colVal = cols[index % cols.length];
                return (
                  <ImageListItem key={item.img} cols={colVal || 1} rows={rowVal || 1}>
                    <img
                      {...srcset(item.img, 121, rowVal, colVal)}
                      alt={item.title}
                      loading="lazy"
                    />
                  </ImageListItem>
                )
              })}
            </ImageList>

          </motion.div>
        </TabPanel>
        <TabPanel value="3">
          <motion.div
            variants={textVariants("up", 0.2)}
            initial="hidden"
            whileInView="show"
            viewport={{ once: true, amount: 0.1 }}
            transition={{ duration: 0.5 }}
          >
            <ImageList

              variant="quilted"
              cols={6}
              rowHeight={121}
              gap={"2.65625vw"}
            >
              {galleryData[2].images.map((item, index) => {
                let rowVal = rows[index % rows.length];
                let colVal = cols[index % cols.length];
                return (
                  <ImageListItem key={item.img} cols={colVal || 1} rows={rowVal || 1}>
                    <img
                      {...srcset(item.img, 121, rowVal, colVal)}
                      alt={item.title}
                      loading="lazy"
                    />
                  </ImageListItem>
                )
              })}
            </ImageList>

          </motion.div>
        </TabPanel>
        <TabPanel value="4">
          <motion.div
            variants={textVariants("up", 0.2)}
            initial="hidden"
            whileInView="show"
            viewport={{ once: true, amount: 0.1 }}
            transition={{ duration: 0.5 }}
          >
            <ImageList

              variant="quilted"
              cols={6}
              rowHeight={121}
              gap={"2.65625vw"}
            >
              {galleryData[3].images.map((item, index) => {
                let rowVal = rows[index % rows.length];
                let colVal = cols[index % cols.length];
                return (
                  <ImageListItem key={item.img} cols={colVal || 1} rows={rowVal || 1}>
                    <img
                      {...srcset(item.img, 121, rowVal, colVal)}
                      alt={item.title}
                      loading="lazy"
                    />
                  </ImageListItem>
                )
              })}
            </ImageList>

          </motion.div>
        </TabPanel> */}
      </TabContext>
    </Box>
  );
}
