import React from "react";
import "./commonbanner.scss";
import { Breadcrumbs } from "@mui/material";
import Home from "../assets/image/img31.png";
import { Link } from "react-router-dom";
import { textVariants } from "../Header/Motion";
import { motion } from "framer-motion";

const Commonbanner = ({
  image,
  link,
  link1,
  link2,
  linkText,
  linkText1,
  linkText2,
  headingtext,
  scrollId,
}) => {
  return (
    <>
      {/* {image ? ( */}
      <div className="common_bg">
        <div className="about_breadcrumbs ">
          <Breadcrumbs separator=">" aria-label="breadcrumb">
            <Link to="/">
              <img src={Home} width={20} />{" "}
            </Link>
            <Link to={link1}>{linkText1}</Link>{" "}
            {link2 != undefined || "" ? (
              <Link to={link2}>
                {linkText2}
              </Link>
            ) : (
              ""
            )}
          </Breadcrumbs>
        </div>
        <motion.div
          variants={textVariants("up", 0.2, 2.5)}
          initial="hidden"
          whileInView="show"
          viewport={{ once: true, amount: 0.1 }}
          transition={{ duration: 0.5 }}
        >
          <div className="common_text text-center ">
            <h1 className="heading">{headingtext}</h1>
          </div>
        </motion.div>
        <div className="scroll-down-d" style={{ zIndex: "5" }}>
          <div className="spinner scroll-down">
            <a
              style={{ cursor: "pointer" }}
              href={scrollId}
              data-scroll-to
              className="animate"
            ></a>
          </div>
        </div>
      </div>
      {/* ) : (
        // <div className="common_bg">
        <div className="common_breadcrumb">
          <div className="about_breadcrumbs ">
            <Breadcrumbs separator=">" aria-label="breadcrumb">
              <Link to="/">
                <img src={Home} width={20} />{" "}
              </Link>
              <Link to={link}>{linkText}</Link>
              <Link to={link1}>{linkText1}</Link>
              <Link to={link2}>{linkText2}</Link>
            </Breadcrumbs>
          </div>
        </div> 
      )}*/}
    </>
  );
};

export default Commonbanner;
