import React, { useState, useEffect } from "react";
import Divider from "@mui/material/Divider";
import { motion } from "framer-motion";
import CountUp, { useCountUp } from "react-countup";
import { textVariants } from "../../Header/Motion";
import presence from "../../assets/image/img19.png";
import { images } from "../../assets";
import { Breadcrumbs } from "@mui/material";
import { Link } from "react-router-dom";
import "./OverseasPresence.scss";
import { leftArrow, rightArrow } from "../../assets";
import { useWindowSize } from "react-use";
import Presense from "../../assets/image/img19.png";
import { Navigation, Pagination, Scrollbar } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import LocomotiveScroll from "locomotive-scroll";
import { useRef } from "react";
import Footer from "../../Footer/Footer";
import PreLoader from "../../loader/preloader";
import ViewportWatcher from "../../common/ViewportWatcher";
import { globalPresenceData } from "../../data";
import Commonbanner from "../../Common Banner/CommonBanner";
const imagesLoaded = require("imagesloaded");

const indigeniousArr = [
  {
    id: 1,
    img: images.clinetlogo_colored_4.image,
    imgBlack: images.clinetlogo_bw_4.image,
    alt: images.clinetlogo_colored_4.alt,
  },
  {
    id: 2,
    img: images.clinetlogo_colored_8.image,
    imgBlack: images.clinetlogo_bw_8.image,
    alt: images.clinetlogo_colored_8.alt,
  },
  {
    id: 3,
    img: images.clinetlogo_colored_29.image,
    imgBlack: images.clinetlogo_bw_29.image,
    alt: images.clinetlogo_colored_29.alt,
  },
  {
    id: 4,
    img: images.rheinmetallclient.image,
    imgBlack: images.rheinmetallclient.image,
    alt: images.rheinmetallclient.alt,
  },
  {
    id: 5,
    img: images.clinetlogo_colored_21.image,
    imgBlack: images.clinetlogo_bw_21.image,
    alt: images.clinetlogo_colored_21.alt,
  },
  {
    id: 6,
    img: images.clinetlogo_colored_14.image,
    imgBlack: images.clinetlogo_bw_14.image,
    alt: images.clinetlogo_colored_14.alt,
  },
  {
    id: 7,
    img: images.clinetlogo_colored_19.image,
    imgBlack: images.clinetlogo_bw_19.image,
    alt: images.clinetlogo_colored_19.alt,
  },
  {
    id: 8,
    img: images.clinetlogo_colored_10.image,
    imgBlack: images.clinetlogo_bw_10.image,
    alt: images.clinetlogo_colored_10.alt,
  },
  // {
  //   id: 9,
  //   img: images.clinetlogo_colored_3.image,
  //   imgBlack: images.clinetlogo_bw_3.image,
  //   alt: images.clinetlogo_colored_3.alt,
  // },
  {
    id: 10,
    img: images.clinetlogo_colored_2.image,
    imgBlack: images.clinetlogo_bw_2.image,
    alt: images.clinetlogo_colored_2.alt,
  },
  {
    id: 11,
    img: images.clinetlogo_colored_22.image,
    imgBlack: images.clinetlogo_bw_22.image,
    alt: images.clinetlogo_colored_22.alt,
  },
  {
    id: 12,
    img: images.clinetlogo_colored_28.image,
    imgBlack: images.clinetlogo_bw_28.image,
    alt: images.clinetlogo_colored_28.alt,
  },
  {
    id: 13,
    img: images.clinetlogo_colored_23.image,
    imgBlack: images.clinetlogo_bw_23.image,
    alt: images.clinetlogo_colored_23.alt,
  },
  {
    id: 14,
    img: images.clinetlogo_colored_17.image,
    imgBlack: images.clinetlogo_bw_17.image,
    alt: images.clinetlogo_colored_17.alt,
  },
  {
    id: 15,
    img: images.clinetlogo_colored_18.image,
    imgBlack: images.clinetlogo_bw_18.image,
    alt: images.clinetlogo_colored_18.alt,
  },
  {
    id: 16,
    img: images.clinetlogo_colored_9.image,
    imgBlack: images.clinetlogo_bw_9.image,
    alt: images.clinetlogo_colored_9.alt,
  },
  {
    id: 17,
    img: images.clinetlogo_colored_26.image,
    imgBlack: images.clinetlogo_bw_26.image,
    alt: images.clinetlogo_colored_26.alt,
  },
  {
    id: 18,
    img: images.hyundaiclient.image,
    imgBlack: images.hyundaiclient.image,
    alt: images.hyundaiclient.alt,
  },
  {
    id: 19,
    img: images.clinetlogo_colored_5.image,
    imgBlack: images.clinetlogo_bw_5.image,
    alt: images.clinetlogo_colored_5.alt,
  },
  {
    id: 20,
    img: images.clinetlogo_colored_15.image,
    imgBlack: images.clinetlogo_bw_15.image,
    alt: images.clinetlogo_colored_15.alt,
  },
  {
    id: 21,
    img: images.clinetlogo_colored_indi_1.image,
    imgBlack: images.clinetlogo_bw_indi_1.image,
    alt: images.clinetlogo_colored_indi_1.alt,
  },
  {
    id: 22,
    img: images.clinetlogo_colored_7.image,
    imgBlack: images.clinetlogo_bw_7.image,
    alt: images.clinetlogo_colored_7.alt,
  },
  {
    id: 23,
    img: images.clinetlogo_colored_16.image,
    imgBlack: images.clinetlogo_bw_16.image,
    alt: images.clinetlogo_colored_16.alt,
  },
  {
    id: 24,
    img: images.clinetlogo_colored_6.image,
    imgBlack: images.clinetlogo_bw_6.image,
    alt: images.clinetlogo_colored_6.alt,
  },
  {
    id: 25,
    img: images.clinetlogo_colored_20.image,
    imgBlack: images.clinetlogo_bw_20.image,
    alt: images.clinetlogo_colored_20.alt,
  },
  {
    id: 26,
    img: images.clinetlogo_colored_31.image,
    imgBlack: images.clinetlogo_colored_31.image,
    alt: images.clinetlogo_colored_31.alt,
  },
  {
    id: 27,
    img: images.clinetlogo_colored_32.image,
    imgBlack: images.clinetlogo_colored_32.image,
    alt: images.clinetlogo_colored_32.alt,
  },
  {
    id: 28,
    img: images.clinetlogo_colored_27.image,
    imgBlack: images.clinetlogo_bw_27.image,
    alt: images.clinetlogo_colored_27.alt,
  },
  {
    id: 29,
    img: images.clinetlogo_colored_25.image,
    imgBlack: images.clinetlogo_bw_25.image,
    alt: images.clinetlogo_colored_25.alt,
  },
  {
    id: 30,
    img: images.clinetlogo_colored_30.image,
    imgBlack: images.clinetlogo_bw_30.image,
    alt: images.clinetlogo_colored_30.alt,
  },
  {
    id: 31,
    img: images.clinetlogo_colored_13.image,
    imgBlack: images.clinetlogo_bw_13.image,
    alt: images.clinetlogo_colored_13.alt,
  },
  // {
  //   id: 29,
  //   img: images.clinetlogo_colored_29.image,
  //   imgBlack: images.clinetlogo_bw_29.image,
  //   alt: images.clinetlogo_colored_29.alt,
  // },
  // {
  //   id: 30,
  //   img: images.clinetlogo_colored_30.image,
  //   imgBlack: images.clinetlogo_bw_30.image,
  //   alt: images.clinetlogo_colored_30.alt,
  // },
];

const OverseasPresence = () => {
  const indigeniousClient = indigeniousArr.map((item) => (
    <div className="col-md-2 col-sm-4 col-4 mobile_padding">
      <motion.div
        variants={textVariants("up", 0.2)}
        initial="hidden"
        whileInView="show"
        viewport={{ once: true, amount: 0.1 }}
        transition={{ duration: 0.5 }}
        className="clients-card"
      >
        <img src={item.img} alt={item.alt} className="blackWhite" />
        {/* <img src={item.img} className="color" alt={item.alt} /> */}
      </motion.div>
    </div>
  ));
  const { width: windowWidth } = useWindowSize();
  const scrollRef = useRef(null);
  const [showLocation, setShowLocation] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseOver = (mark) => {
    setIsHovered(mark);
  };

  const handleMouseOut = () => {
    setIsHovered(false);
  };
  useEffect(() => {
    let scrollContainer = document.querySelector("[data-scroll-container]");
    const scroll = new LocomotiveScroll({
      el: scrollRef.current,
      smooth: true,
      getSpeed: true,
      getDirection: true,
      multiplier: 0.4, // Adjust the scroll speed here
    });

    scroll.update(); // Manually update the scroll height

    imagesLoaded(scrollContainer, { background: true }, function () {
      scroll.update();
    });

    const resizeObserver = new ResizeObserver(() => {
      scroll.update();
    });

    // start observing a DOM node
    resizeObserver.observe(document.body);

    return () => {
      resizeObserver.unobserve(document.body);
  
        scroll.destroy();
      }
      
  }, []);

  const handleClick = (event, targetId) => {
    event.preventDefault();
    const targetElement = document.querySelector(targetId);
    if (targetElement) {
      const targetRect = targetElement.getBoundingClientRect();
      const centerOffset = (window.innerHeight - targetRect.height) / 2;

      const scroll = new LocomotiveScroll({
        el: scrollRef.current,
        smooth: true, // Enable smooth scrolling
      });

      scroll.scrollTo(targetElement, {
        offset: -centerOffset,
        duration: 800, // Adjust the duration as needed
        easing: [0.25, 0.46, 0.45, 0.94], // Adjust the easing as needed
      });

      setShowLocation(
        event.target.innerHTML === showLocation ? "" : event.target.innerHTML
      );
    }
  };

  return (
    <>
      <PreLoader />
      <div
        data-scroll-container
        ref={scrollRef}
        style={{
          background: "#141414",
          backgroundImage: `url(${images.overseasBg.image})`,
          backgroundSize: windowWidth > 600 ? "cover" : "contain",
          overflow: "hidden",
          backgroundPosition: "center",
          backgroundAttachment: "fixed",
        }}
      >
        <section
          id="scrolltop"
          className="overseas_sec1"
          style={{ overflow: "hidden" }}
          data-scroll-section
        >
          <Commonbanner
            // image={awardsBanner}
            linkText1="CLIENT"
            link1="/overseas-presence"
            linkText2="OVERSEAS PRESENCE"
            link2="/overseas-presence"
            headingtext="OVERSEAS PRESENCE"
            scrollId="#overseas"
          />
        </section>
        {/* <section
          id="scrolltop"
          className="overseas_sec1"
          style={{ height: "100%", overflow: "hidden" }}
          data-scroll-section
        >
          <div
            className="gallery_bg overseas_bg"
            // style={{ backgroundImage: `url(${images.overseasBg.image})` }}
          >
            <div className="certification_breadcrumbs ">
              <Breadcrumbs separator=">" aria-label="breadcrumb">
                <Link to="/">
                  <img
                    src={images.homeicon.image}
                    alt={images.homeicon.alt}
                    loading="lazy"
                    width={20}
                  />{" "}
                </Link>
                <Link to="/">CLIENT</Link>
                <Link to="/overseas-presence">OVERSEAS PRESENCE</Link>
              </Breadcrumbs>
            </div>
            <div className="overseas_heading">
              <motion.h1
                variants={textVariants("up", 0.2, 2.5)}
                initial="hidden"
                whileInView="show"
                viewport={{ once: true, amount: 0.1 }}
                transition={{ duration: 0.5 }}
              >
                OVERSEAS PRESENCE
              </motion.h1>
            </div>
            <div></div>
            <div className="scroll-down-d">
              <div className="spinner scroll-down">
                <a href="#overseas" data-scroll-to className="animate"></a>
              </div>
            </div>
          </div>
        </section> */}

        <section
          id="overseas"
          className="presence-s overseas-presence-s"
          style={{ height: "100%", overflow: "hidden" }}
          data-scroll-section
        >
          <div className="presence-s_bg">
            <div className="">
              <div className="presence-s_section_c overseas-presence-sec">
                <div className="presence-s-count_sec  col-lg-6 col-md-6 col-sm-12 col-12">
                  <motion.div
                    className="count-up"
                    variants={textVariants("left", 0.2)}
                    initial="hidden"
                    whileInView="show"
                    viewport={{ once: true, amount: 0.1 }}
                    transition={{ duration: 0.5 }}
                  >
                    <h1>
                      <CountUp end={60} duration={6} />+
                    </h1>
                    <p style={{ marginTop: "15px" }}>
                      WORLDWIDE <span>COUNTRIES</span>
                    </p>
                  </motion.div>
                </div>

                <motion.div
                  className="presence-s_content_section col-lg-6 col-md-6 col-sm-12 col-12"
                  variants={textVariants("right", 0.2)}
                  initial="hidden"
                  whileInView="show"
                  viewport={{ once: true, amount: 0.1 }}
                  transition={{ duration: 0.5 }}
                >
                  <p className="worldwide_content_para">
                    Multimetals concedes a strong global presence, with a
                    diverse clientele spanning across five continents and
                    encompassing over 60 countries. Our esteemed client list
                    includes industry giants such as Shell, QATAR PETROLEUM,
                    SABIC, and Hyundai, among others. With our unwavering
                    dedication to delivering high-quality products and
                    exceptional service, we have established enduring
                    partnerships with clients worldwide.
                  </p>
                </motion.div>
              </div>

              {/* <motion.div
                variants={textVariants("down", 0.2)}
                initial="hidden"
                whileInView="show"
                viewport={{ once: true, amount: 0.1 }}
                transition={{ duration: 0.5 }}
                className="presence_img_sec overseas_presence_img_sec"
              >
                <img src={presence} style={{ width: "100%" }} />
              </motion.div>

              <div className="locationBx">
                <h1>SLOVAKIA</h1>
              </div> */}

              <motion.div
                variants={textVariants("down", 0.2)}
                initial="hidden"
                whileInView="show"
                viewport={{ once: true, amount: 0.1 }}
                transition={{ duration: 0.5 }}
                className="map presence_img_sec"
              >
                <img src={Presense} style={{ width: "100%" }} />
                <div class="map-pins">
                  <ViewportWatcher className="bounceInDown">
                    {globalPresenceData.locationMarks.map((mark) => {
                      return (
                        <>
                          <div>
                            {mark.name != "India" && (
                              <a
                                onMouseOver={() => handleMouseOver(mark.id)}
                                onMouseOut={() => handleMouseOut()}
                                style={{
                                  left: `${mark.left}vw`,
                                  top: `${mark.top}%`,
                                  animationDelay: `${mark.delay}`,
                                  cursor: "pointer",
                                }}
                                class="map-location-marker animated"
                                data-map-title="San Francisco"
                                data-map-link="san-francisco"
                                data-map-image="san-francisco.jpg"
                                data-job-listings="https://westernunion.taleo.net/careersection/10045/jobsearch.ftl?&src=CWS-10230"
                              ></a>
                            )}

                            {/* {showLocation == mark.name && <> */}
                            <svg
                              className={`arrowSvg ${
                                isHovered == mark.id ? "d-block" : "d-none"
                              }`}
                              stroke="currentColor"
                              fill="currentColor"
                              stroke-width="0"
                              viewBox="0 0 24 24"
                              height="1em"
                              width="1em"
                              xmlns="http://www.w3.org/2000/svg"
                              style={{
                                color: "#483F3C",
                                left: `${mark.left - 0.3}vw`,
                                top: `${mark.top - 2.5}%`,
                                zIndex: "9",
                              }}
                            >
                              <path d="m4.431 12.822 13 9A1 1 0 0 0 19 21V3a1 1 0 0 0-1.569-.823l-13 9a1.003 1.003 0 0 0 0 1.645z"></path>
                            </svg>
                            <div
                              className={`locationBx ${
                                isHovered == mark.id ? "d-flex" : "d-none"
                              }`}
                              style={{
                                left: `${mark.left + 1}vw`,
                                top: `${mark.top - 4}%`,
                                zIndex: "9",
                              }}
                            >
                              <h1 className="m-0 text-center">{mark.name}</h1>
                            </div>
                            {/* </>} */}
                          </div>
                        </>
                      );
                    })}
                  </ViewportWatcher>
                </div>
              </motion.div>
              {windowWidth > 1023 ? (
                <div className="locationNameflex">
                  <div className="my_container">
                    <ul className="locationName1-desktop">
                      {globalPresenceData.locationMarks.map((mark, i) => (
                        <>{mark.name != "India" && <li>{mark.name}</li>}</>
                      ))}
                      {/* <a href="#country_2" style={{ pointerEvents: "none" }}>
                      Australia
                    </a>
                    <a href="#country_3" style={{ pointerEvents: "none" }}>
                      Austria
                    </a>
                    <a href="#country_4" style={{ pointerEvents: "none" }}>
                      Bahrain
                    </a>
                    <a href="#country_5" style={{ pointerEvents: "none" }}>
                      Bangladesh
                    </a>
                    <a href="#country_6" style={{ pointerEvents: "none" }}>
                      Brazil
                    </a>
                    <a href="#country_7" style={{ pointerEvents: "none" }}>
                      Belgium
                    </a>
                    <a href="#country_8" style={{ pointerEvents: "none" }}>
                      Canada
                    </a>
                    <a href="#country_9" style={{ pointerEvents: "none" }}>
                      Chile
                    </a>
                    <a href="#country_10" style={{ pointerEvents: "none" }}>
                      Czech Republic
                    </a>
                    <a href="#country_11" style={{ pointerEvents: "none" }}>
                      Denmark
                    </a> */}
                    </ul>
                  </div>
                  {/* <div className="locationName1">
                    <a href="#country_12" style={{ pointerEvents: "none" }}>
                      Egypt
                    </a>
                    <a href="#country_13" style={{ pointerEvents: "none" }}>
                      Finland
                    </a>
                    <a href="#country_14" style={{ pointerEvents: "none" }}>
                      Germany
                    </a>
                    <a href="#country_15" style={{ pointerEvents: "none" }}>
                      Greece
                    </a>
                    <a href="#country_16" style={{ pointerEvents: "none" }}>
                      Italy
                    </a>
                    <a href="#country_17" style={{ pointerEvents: "none" }}>
                      Jordan
                    </a>
                    <a href="#country_18" style={{ pointerEvents: "none" }}>
                      Kuwait
                    </a>
                    <a href="#country_19" style={{ pointerEvents: "none" }}>
                      Malaysia
                    </a>
                    <a href="#country_20" style={{ pointerEvents: "none" }}>
                      Mexico
                    </a>
                    <a href="#country_21" style={{ pointerEvents: "none" }}>
                      Morocco
                    </a>
                    <a href="#country_22" style={{ pointerEvents: "none" }}>
                      Netherlands
                    </a>
                  </div>
                  <div className="locationName1">
                    <a href="#country_23" style={{ pointerEvents: "none" }}>
                      New Zealand
                    </a>
                    <a href="#country_24" style={{ pointerEvents: "none" }}>
                      Oman
                    </a>
                    <a href="#country_25" style={{ pointerEvents: "none" }}>
                      Philippines
                    </a>
                    <a href="#country_26" style={{ pointerEvents: "none" }}>
                      Poland
                    </a>
                    <a href="#country_27" style={{ pointerEvents: "none" }}>
                      Portugal
                    </a>
                    <a href="#country_28" style={{ pointerEvents: "none" }}>
                      Qatar
                    </a>
                    <a href="#country_29" style={{ pointerEvents: "none" }}>
                      Romania
                    </a>
                    <a href="#country_30" style={{ pointerEvents: "none" }}>
                      Russia
                    </a>
                    <a href="#country_31" style={{ pointerEvents: "none" }}>
                      Saudi Arabia
                    </a>
                    <a href="#country_32" style={{ pointerEvents: "none" }}>
                      Singapore
                    </a>
                    <a href="#country_33" style={{ pointerEvents: "none" }}>
                      Slovakia
                    </a>
                  </div>
                  <div className="locationName1">
                    <a href="#country_34" style={{ pointerEvents: "none" }}>
                      South Africa
                    </a>
                    <a href="#country_35" style={{ pointerEvents: "none" }}>
                      Spain
                    </a>
                    <a href="#country_36" style={{ pointerEvents: "none" }}>
                      Sweden
                    </a>
                    <a href="#country_37" style={{ pointerEvents: "none" }}>
                      Thailand
                    </a>
                    <a href="#country_38" style={{ pointerEvents: "none" }}>
                      Trinidad and Tobago
                    </a>
                    <a href="#country_39" style={{ pointerEvents: "none" }}>
                      UAE
                    </a>
                    <a href="#country_40" style={{ pointerEvents: "none" }}>
                      UK
                    </a>
                    <a href="#country_41" style={{ pointerEvents: "none" }}>
                      USA
                    </a>
                    <a href="#country_42" style={{ pointerEvents: "none" }}>
                      Venezuela
                    </a>
                    <a href="#country_43" style={{ pointerEvents: "none" }}>
                      Zambia
                    </a>
                  </div> */}
                </div>
              ) : (
                <Swiper
                  modules={[Navigation, Pagination, Scrollbar]}
                  spaceBetween={30}
                  slidesPerView={4}
                  navigation={{
                    prevEl: ".left_arrow",
                    nextEl: ".right_arrow",
                  }}
                  onSwiper={(swiper) => console.log(swiper)}
                  onSlideChange={() => console.log("slide change")}
                  style={{ paddingBottom: "50px" }}
                  breakpoints={{
                    0: {
                      slidesPerView: 2,
                    },
                    600: {
                      slidesPerView: 4,
                    },
                  }}
                >
                  <SwiperSlide>
                    <div className="locationName1">
                      <a href="#country_1" style={{ pointerEvents: "none" }}>
                        Algeria
                      </a>
                      <a href="#country_2" style={{ pointerEvents: "none" }}>
                        Australia
                      </a>
                      <a href="#country_3" style={{ pointerEvents: "none" }}>
                        Austria
                      </a>
                      <a href="#country_4" style={{ pointerEvents: "none" }}>
                        Bahrain
                      </a>
                      <a href="#country_5" style={{ pointerEvents: "none" }}>
                        Bangladesh
                      </a>
                      <a href="#country_6" style={{ pointerEvents: "none" }}>
                        Brazil
                      </a>
                      <a href="#country_7" style={{ pointerEvents: "none" }}>
                        Belgium
                      </a>
                      <a href="#country_8" style={{ pointerEvents: "none" }}>
                        Canada
                      </a>
                      <a href="#country_9" style={{ pointerEvents: "none" }}>
                        Chile
                      </a>
                      <a href="#country_10" style={{ pointerEvents: "none" }}>
                        Czech Republic
                      </a>
                      <a href="#country_11" style={{ pointerEvents: "none" }}>
                        Denmark
                      </a>
                      <a href="#country_12" style={{ pointerEvents: "none" }}>
                        Egypt
                      </a>
                      <a href="#country_13" style={{ pointerEvents: "none" }}>
                        Finland
                      </a>
                      <a href="#country_14" style={{ pointerEvents: "none" }}>
                        Germany
                      </a>
                      <a href="#country_16" style={{ pointerEvents: "none" }}>
                        Italy
                      </a>
                      <a href="#country_17" style={{ pointerEvents: "none" }}>
                        Jordan
                      </a>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="locationName1">
                      {/* <a href="#country_15" style={{ pointerEvents: "none" }}>
                        Greece
                      </a> */}
                      <a href="#country_18" style={{ pointerEvents: "none" }}>
                        Kuwait
                      </a>
                      <a href="#country_19" style={{ pointerEvents: "none" }}>
                        Malaysia
                      </a>
                      <a href="#country_20" style={{ pointerEvents: "none" }}>
                        Mexico
                      </a>
                      <a href="#country_21" style={{ pointerEvents: "none" }}>
                        Morocco
                      </a>
                      <a href="#country_22" style={{ pointerEvents: "none" }}>
                        Netherland
                      </a>
                      <a href="#country_23" style={{ pointerEvents: "none" }}>
                        New Zealand
                      </a>
                      <a href="#country_24" style={{ pointerEvents: "none" }}>
                        Oman
                      </a>
                      <a href="#country_25" style={{ pointerEvents: "none" }}>
                        Philippines
                      </a>
                      <a href="#country_26" style={{ pointerEvents: "none" }}>
                        Poland
                      </a>
                      <a href="#country_27" style={{ pointerEvents: "none" }}>
                        Portugal
                      </a>
                      <a href="#country_28" style={{ pointerEvents: "none" }}>
                        Qatar
                      </a>
                      <a href="#country_29" style={{ pointerEvents: "none" }}>
                        Romania
                      </a>
                      <a href="#country_31" style={{ pointerEvents: "none" }}>
                        Saudi Arabia
                      </a>
                      <a href="#country_32" style={{ pointerEvents: "none" }}>
                        Singapore
                      </a>
                      <a href="#country_33" style={{ pointerEvents: "none" }}>
                        Slovakia
                      </a>
                      <a href="#country_34" style={{ pointerEvents: "none" }}>
                        South Africa
                      </a>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="locationName1">
                      <a href="#country_35" style={{ pointerEvents: "none" }}>
                        Spain
                      </a>
                      <a href="#country_36" style={{ pointerEvents: "none" }}>
                        Sweden
                      </a>
                      <a href="#country_37" style={{ pointerEvents: "none" }}>
                        Thailand
                      </a>
                      <a href="#country_39" style={{ pointerEvents: "none" }}>
                        UAE
                      </a>
                      <a href="#country_40" style={{ pointerEvents: "none" }}>
                        UK
                      </a>
                      <a href="#country_41" style={{ pointerEvents: "none" }}>
                        USA
                      </a>
                      <a href="#country_42" style={{ pointerEvents: "none" }}>
                        Venezuela
                      </a>
                      <a href="#country_43" style={{ pointerEvents: "none" }}>
                        Zambia
                      </a>
                      <a href="#" style={{ pointerEvents: "none" }}>
                        France
                      </a>
                      <a href="#" style={{ pointerEvents: "none" }}>
                        England
                      </a>
                      <a href="#" style={{ pointerEvents: "none" }}>
                        Japan
                      </a>
                      <a href="#" style={{ pointerEvents: "none" }}>
                        South Korea
                      </a>
                      <a href="#" style={{ pointerEvents: "none" }}>
                        Tunisia
                      </a>
                      <a href="#" style={{ pointerEvents: "none" }}>
                        Ivory Coast
                      </a>
                      <a href="#" style={{ pointerEvents: "none" }}>
                        Argentina
                      </a>
                      <a href="#" style={{ pointerEvents: "none" }}>
                        Armenia
                      </a>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="locationName1">
                      <a href="#" style={{ pointerEvents: "none" }}>
                        Bulgaria
                      </a>
                      <a href="#" style={{ pointerEvents: "none" }}>
                        Cuba
                      </a>
                      <a href="#" style={{ pointerEvents: "none" }}>
                        Ecuador
                      </a>
                      <a href="#" style={{ pointerEvents: "none" }}>
                        Estonia
                      </a>
                      <a href="#" style={{ pointerEvents: "none" }}>
                        Georgia
                      </a>
                      <a href="#" style={{ pointerEvents: "none" }}>
                        Greece
                      </a>
                      <a href="#" style={{ pointerEvents: "none" }}>
                        Indonesia
                      </a>
                      <a href="#" style={{ pointerEvents: "none" }}>
                        Norway
                      </a>
                      <a href="#" style={{ pointerEvents: "none" }}>
                        Panama
                      </a>
                      <a href="#" style={{ pointerEvents: "none" }}>
                        Peru
                      </a>
                      <a href="#" style={{ pointerEvents: "none" }}>
                        Slovenia
                      </a>
                      <a href="#" style={{ pointerEvents: "none" }}>
                        Switzerland
                      </a>
                      <a href="#" style={{ pointerEvents: "none" }}>
                        Turkey
                      </a>
                    </div>
                  </SwiperSlide>
                  {windowWidth <= 600 ? (
                  <div className="arrow_wrapper overseas_arrow_wrapper">
                    <img
                      src={leftArrow}
                      alt="left arrow"
                      className="left_arrow"
                    />
                    <img
                      src={rightArrow}
                      alt="right arrow"
                      className="right_arrow"
                    />
                  </div>
                  ) : null }
                </Swiper>
              )}

              <div className="overseas-clients-section">
                <h1 style={{ lineHeight: "3rem" }}>
                  From Continents to Clients: Multimetals Global Footprint
                </h1>
                <p>
                  From North America to Europe, the Middle East to Asia, and
                  Africa to Oceania, Multimetals has successfully catered to a
                  wide range of industries, including oil and gas,
                  petrochemicals, and automotive. Our extensive global reach
                  allows us to provide prompt support and tailored solutions to
                  meet the unique needs of clients in different regions.
                </p>
                <p>
                  With a strong network of trusted clients across the globe,
                  Multimetals continues to expand its presence and forge new
                  partnerships. We strive to exceed customer expectations by
                  offering comprehensive solutions, reliable delivery, and
                  unparalleled customer service. Choose Multimetals as your
                  global supplier and benefit from our commitment to excellence,
                  industry expertise, and unmatched international reach.
                  Experience the difference of working with a trusted partner
                  dedicated to your success.
                </p>

                <div className="row indigenious_row overseas_row">{indigeniousClient}</div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="footer_area"
          data-scroll-section
          style={{
            overflowY: "hidden",
          }}
        >
          <Footer />
        </section>
      </div>
    </>
  );
};

export default OverseasPresence;
