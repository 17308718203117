import { TfiAngleDown } from "react-icons/tfi";
import { investorURL } from "../helpers/paths";

export const QualityItem = [
  {
    page: "Quality Overview",
    url: "/quality-overview ",
  },
  {
    page: "Certifications",
    url: "/certifications",
  },
  {
    page: "Vendor Approval",
    url: "/vendor-approval",
  },
  {
    page: "Packaging & Dispatch",
    url: "/packaging-&-dispatch",
  },
  {
    page: "Testing Facilities",
    url: "/testing-facilities",
  },
];
export const ClientItem = [
  {
    page: "Indigenous",
    url: "/indigenous",
  },
  {
    page: "Overseas Presence",
    url: "/overseas-presence",
  },
];
export const ProductItems = [
  {
    page: "Tubes & Pipes",
    url: "/products/tube-and-pipes",
  },
  {
    page: "Speciality & Finned Tube",
    url: "/products/speciality-and-finned-tubes",
  },
  {
    page: "Cast, Extruded",
    url: "/products/cast-extruded-and-drawn-semis",
  },
];
export const AboutItems = [
  {
    page: "Our Journey",
    url: "/about",
  },
  {
    page: "Gallery",
    url: "/gallery",
  },
  {
    page: "Investor Relation",
    url: investorURL,
  },
  {
    page: "Awards & Recognition",
    url: "/awards-&-recognition",
  },
  {
    page: "Manufacturing Process",
    url: "/manufacturing-process",
  },
  // {
  //   page: "Career",
  //   url: "/career",
  // },
];
