import React, { useState } from "react";
import LogoBlack from "../assets/Latest logo MML Black.png";
import { Link, NavLink } from "react-router-dom";
import "./footer.scss";
import {
  ProductItems,
  AboutItems,
  QualityItem,
  ClientItem,
} from "../Header/MenuLinks";
import footerBg from "../assets/Footer.jpg";
import Call from "../assets/callline.png";
import Msg from "../assets/mail.png";
import { useWindowSize } from "react-use";
import { Accordion } from "react-bootstrap";
import downArrow from "../assets/down-arrow@3x.png";
import { footerscroll, footerscrollhover } from "../assets";
import { investorURL } from "../helpers/paths";

// const FooterDataArr = [
//   {
//     id: 1,
//     status: "Quality",
//     title: "Quality Overview",
//     url: "quality-overview",
//   },
//   {
//     id: 2,
//     status: "Quality",
//     title: "Certifications",
//     url: "certifications",
//   },
//   {
//     id: 3,
//     status: " ",
//     title: "Vendor Approval",
//     url: "vendor-approval",
//   },
//   {
//     id: 4,
//     status: "Quality",
//     title: "Packaging & Dispatch",
//     url: "packaging-&-dispatch",
//   },
//   {
//     id: 5,
//     status: "Quality",
//     title: "Testing Facilities",
//     url: "testing-facilities",
//   },
//   {
//     id: 6,
//     status: "Products",
//     title: "Tube & Pipes",
//     url: "tubes-&-pipes",
//   },
//   {
//     id: 7,
//     status: "Products",
//     title: "speciality-&-finned-tube",
//     url: "blog",
//   },
//   {
//     id: 8,
//     status: "Products",
//     title: "Cast, Extruded",
//     url: "faq",
//   },
//   {
//     id: 9,
//     status: "Products",
//     title: "Wires",
//     url: "wiresy",
//   },
//   {
//     id: 10,
//     status: "About",
//     title: "Company Profile",
//     url: "about",
//   },
//   {
//     id: 11,
//     status: "About",
//     title: "Gallery",
//     url: "gallery",
//   },
//   {
//     id: 12,
//     status: "About",
//     title: "Manufacturing Process",
//     url: "manufacturing-process",
//   },
//   {
//     id: 13,
//     status: "About",
//     title: "Investor Relation",
//     url: investorURL,
//   },
//   {
//     id: 14,
//     status: "About",
//     title: "Awards & Recognition",
//     url: "awards-&-recognition",
//   },
// ];

const Footer = () => {
  const { width: windowWidth } = useWindowSize();
  const [hover, setHover] = useState(false);

  return (
    <>
      {windowWidth > 1023 ? (
        <footer>
          <div
            className="footer"
            style={{ backgroundImage: `url(${footerBg})` }}
          >
            <div style={{ position: "relative", zIndex: "2" }}>
              <div className="footer_set">
                <div className="footer_div_main">
                  <div className="row" style={{ rowGap: "2rem" }}>
                    <div className="col-lg-2 col-md-6 col-sm-12 col-12 d-flex flex-column align-items-center">
                      <div className="logo">
                        <h1 className="text-light">
                          <NavLink to="/">
                            <span>
                              <img src={LogoBlack} />
                            </span>
                          </NavLink>
                        </h1>
                      </div>
                      <div className="about-d">
                        <p>Engineered for Excellence</p>
                      </div>
                    </div>
                    <div className="col-lg-2 col-md-6 col-sm-12 col-12 quick-links">
                      <div>
                        <span>About Us</span>
                      </div>
                      <ul style={{ listStyle: "none" }}>
                        {AboutItems.map((data) => {
                          return (
                            <li>
                              <NavLink to={data.url}>{data.page}</NavLink>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                    <div className="col-lg-2 col-md-6 col-sm-12 col-12 about_us-links">
                      <span>Quality</span>
                      <ul style={{ listStyle: "none" }}>
                        {QualityItem.map((data) => {
                          return (
                            <li>
                              <NavLink to={data.url}>{data.page}</NavLink>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                    <div className="col-lg-2 col-md-6 col-sm-12 col-12 about_us-links">
                      <span>Client</span>
                      <ul style={{ listStyle: "none" }}>
                        {ClientItem.map((data) => {
                          return (
                            <li>
                              <NavLink to={data.url}>{data.page}</NavLink>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                    <div className="col-lg-2 col-md-6 col-sm-12 col-12 product-links">
                      <span>Products</span>
                      <ul style={{ listStyle: "none" }}>
                        {ProductItems.map((data) => {
                          return (
                            <li>
                              <NavLink to={data.url}>{data.page}</NavLink>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                    <div className="col-lg-2 col-md-6 col-sm-12 col-12 product-links">
                      <span>Connect with us</span>
                      <ul style={{ listStyle: "none" }}>
                        <li>
                          <NavLink to="/contact-us">Contact Us</NavLink>
                        </li>
                        <li>
                          <a
                            href="https://www.google.com/maps?ll=25.154669,75.865143&z=15&t=m&hl=en&gl=IN&mapclient=embed&q=Multimetals+Kota+Industrial+Area+Kota,+Rajasthan+324007"
                            target="main"
                            className="address"
                          >
                            Multimetals Limited, Heavy Industries Area, Kansua
                            Road, Kota-324003, Rajasthan, India
                          </a>
                        </li>
                      </ul>
                    </div>
                    {/* <div className="col-lg-3 col-md-6 col-sm-12 col-12 Address_link">
                      <span>Address</span>
                      <div className="addres-d">
                        <p>
                          Multimetals Limited Heavy, Road, Kansuwa, Kota
                          Industrial Area, Kota, Rajasthan 324003
                        </p>
                      </div>
                      <div className="contact-d ">
                        <a
                          href="tel:+91-744-2798550"
                          className="d-flex align-items-center"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            id="callline"
                            width="19.165"
                            height="19.238"
                            viewBox="0 0 19.165 19.238"
                          >
                            <g
                              id="Group_53"
                              data-name="Group 53"
                              transform="translate(0 0)"
                            >
                              <path
                                id="Path_17"
                                data-name="Path 17"
                                d="M4.8,12.788a20.409,20.409,0,0,0,6.789,5.318,10.566,10.566,0,0,0,3.8,1.124c.092,0,.179.008.271.008a3.146,3.146,0,0,0,2.44-1.048.087.087,0,0,0,.016-.02,10.271,10.271,0,0,1,.769-.8c.187-.179.379-.367.562-.558a1.925,1.925,0,0,0-.008-2.866l-2.4-2.4a1.939,1.939,0,0,0-1.4-.646,2,2,0,0,0-1.419.642l-1.427,1.427c-.132-.076-.267-.144-.395-.207a4.929,4.929,0,0,1-.438-.239A15.1,15.1,0,0,1,8.347,9.244,8.7,8.7,0,0,1,7.127,7.3c.375-.339.726-.694,1.064-1.04.12-.124.243-.247.367-.371A2.044,2.044,0,0,0,9.22,4.453a2.027,2.027,0,0,0-.662-1.435L7.37,1.83c-.14-.14-.271-.275-.407-.415-.263-.271-.538-.55-.809-.8A1.987,1.987,0,0,0,4.751,0,2.046,2.046,0,0,0,3.332.618L1.841,2.109A3.063,3.063,0,0,0,.929,4.07a7.378,7.378,0,0,0,.554,3.189A18.553,18.553,0,0,0,4.8,12.788ZM1.9,4.154A2.109,2.109,0,0,1,2.535,2.8L4.018,1.315A1.074,1.074,0,0,1,4.751.977a1.019,1.019,0,0,1,.718.347c.267.247.518.506.789.781.136.14.275.279.415.423L7.861,3.715a1.082,1.082,0,0,1,.375.745,1.082,1.082,0,0,1-.375.745c-.124.124-.247.251-.371.375-.371.375-.718.729-1.1,1.068l-.02.02a.779.779,0,0,0-.2.885c0,.012.008.02.012.032a9.382,9.382,0,0,0,1.4,2.276,15.941,15.941,0,0,0,3.843,3.5,5.707,5.707,0,0,0,.526.287,4.929,4.929,0,0,1,.438.239l.044.024a.833.833,0,0,0,.387.1.845.845,0,0,0,.594-.271L14.9,12.25a1.055,1.055,0,0,1,.729-.355,1,1,0,0,1,.706.355l2.4,2.4a.961.961,0,0,1-.012,1.5c-.167.179-.343.351-.53.53a11.479,11.479,0,0,0-.833.865,2.189,2.189,0,0,1-1.71.726c-.068,0-.14,0-.207-.008a9.6,9.6,0,0,1-3.436-1.028,19.389,19.389,0,0,1-6.462-5.063A17.758,17.758,0,0,1,2.4,6.932,6.429,6.429,0,0,1,1.9,4.154Z"
                                transform="translate(-0.912 0)"
                                fill="#fff"
                              />
                            </g>
                          </svg>
                          <p>+91-744-2798550</p>
                        </a>
                        <a
                          href="mailto:1234567890"
                          className="d-flex align-items-center"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            id="mail"
                            width="20.237"
                            height="14.229"
                            viewBox="0 0 20.237 14.229"
                          >
                            <g id="Group_59" data-name="Group 59">
                              <g id="Group_58" data-name="Group 58">
                                <path
                                  id="Path_20"
                                  data-name="Path 20"
                                  d="M18.458,76H1.779A1.781,1.781,0,0,0,0,77.779V88.45a1.781,1.781,0,0,0,1.779,1.779h16.68a1.781,1.781,0,0,0,1.779-1.779V77.779A1.78,1.78,0,0,0,18.458,76Zm-.249,1.186-6.833,6.8a1.779,1.779,0,0,1-2.516,0l-6.832-6.8ZM1.186,88.209V78.021l5.124,5.1Zm.843.834L7.15,83.954l.872.867a2.964,2.964,0,0,0,4.191,0l.873-.868,5.122,5.089Zm17.023-.834-5.124-5.091,5.124-5.1Z"
                                  transform="translate(0 -76)"
                                  fill="#fff"
                                />
                              </g>
                            </g>
                          </svg>
                          <p>contact@multimetals.in</p>
                        </a>
                      </div>

                      <div
                        className="Scroller_button"
                        onClick={() => window.scrollTo(0, 0)}
                      >
                        <div
                          style={{
                            border: "none",
                            borderRadius: "0px",
                            bottom: "40px",
                            background: "transparent",
                            cursor: "pointer",
                          }}
                        >
                          <img
                            style={{
                              cursor: "pointer",
                            }}
                            src={Scroll}
                            onClick={() => {
                              window.scrollTo({
                                top: 0,
                                left: 0,
                                behavior: "smooth",
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>

              <div className="copy-right-d text-center text-white">
                <div className="">
                  <div className="d-flex justify-content-between">
                    <div>
                      <p className="">
                        All Rights reserved 2023 by{" "}
                        <Link to="/" style={{ color: "#fff" }}>
                          Multimetal
                        </Link>
                      </p>
                    </div>
                    <div>
                      <p className="">
                        Crafted by{" "}
                        <a
                          style={{ color: "#fff" }}
                          href="https://www.togglehead.in/"
                          target="_blank"
                        >
                          Togglehead
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="Scroller_button"
              onClick={() => window.scrollTo(0, 0)}
            >
              <a
                href="#scrolltop"
                data-scroll-to
                style={{
                  border: "none",
                  borderRadius: "0px",
                  bottom: "40px",
                  background: "transparent",
                  cursor: "pointer",
                }}
              >
                <img
                  style={{
                    cursor: "pointer",
                  }}
                  src={hover ? footerscrollhover : footerscroll}
                  onMouseOver={() => setHover(true)}
                  onMouseOut={() => setHover(false)}
                  onClick={() => {
                    window.scrollTo({
                      top: 0,
                      left: 0,
                      behavior: "smooth",
                    });
                  }}
                />
              </a>
            </div>
          </div>
        </footer>
      ) : (
        <footer>
          <div
            className="footer"
            style={{ backgroundImage: `url(${footerBg})` }}
          >
            <div style={{ position: "relative", zIndex: "2" }}>
              <div className="footer_set">
                <div className="footer_div_main">
                  <div className="row" style={{ rowGap: "2rem" }}>
                    <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                      <div className="logo">
                        <h1 className="text-light">
                          <NavLink to="/">
                            <span>
                              <img src={LogoBlack} />
                            </span>
                          </NavLink>
                        </h1>
                      </div>
                      <div className="about-d">
                        <p>Engineered for Excellence</p>
                      </div>
                    </div>
                    <div className="projects">
                      <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                          <Accordion.Header>
                            <p className="ong_pr" style={{ color: "var(--primary-color)" }}>
                              About Us
                            </p>
                            <img
                              className="float-right icon"
                              src={downArrow}
                              alt="arrow icon"
                            ></img>
                          </Accordion.Header>
                          <Accordion.Body>
                            <ul style={{ listStyle: "none" }}>
                              {AboutItems.map((data) => {
                                return (
                                  <li>
                                    <NavLink
                                      to={data.url}
                                      style={{
                                        textDecoration: "none",
                                        color: "var(--text-color)",
                                      }}
                                    >
                                      {data.page}
                                    </NavLink>
                                  </li>
                                );
                              })}
                            </ul>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                          <Accordion.Header>
                            <p className="ong_pr" style={{ color: "var(--primary-color)" }}>
                              Quality
                            </p>
                            <img
                              className="float-right icon"
                              src={downArrow}
                              alt="arrow icon"
                            ></img>
                          </Accordion.Header>
                          <Accordion.Body>
                            <ul style={{ listStyle: "none" }}>
                              {QualityItem.map((data) => {
                                return (
                                  <li>
                                    <NavLink
                                      to={data.url}
                                      style={{
                                        textDecoration: "none",
                                        color: "var(--text-color)",
                                      }}
                                    >
                                      {data.page}
                                    </NavLink>
                                  </li>
                                );
                              })}
                            </ul>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                          <Accordion.Header>
                            <p className="ong_pr" style={{ color: "var(--primary-color)" }}>
                              Client
                            </p>
                            <img
                              className="float-right icon"
                              src={downArrow}
                              alt="arrow icon"
                            ></img>
                          </Accordion.Header>
                          <Accordion.Body>
                            <ul style={{ listStyle: "none" }}>
                              {ClientItem.map((data) => {
                                return (
                                  <li>
                                    <NavLink
                                      to={data.url}
                                      style={{
                                        textDecoration: "none",
                                        color: "var(--text-color)",
                                      }}
                                    >
                                      {data.page}
                                    </NavLink>
                                  </li>
                                );
                              })}
                            </ul>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                          <Accordion.Header>
                            <p className="ong_pr" style={{ color: "var(--primary-color)" }}>
                              Products
                            </p>
                            <img
                              className="float-right icon"
                              src={downArrow}
                              alt="arrow icon"
                            ></img>
                          </Accordion.Header>
                          <Accordion.Body>
                            <ul style={{ listStyle: "none" }}>
                              {ProductItems.map((data) => {
                                return (
                                  <li>
                                    <NavLink
                                      to={data.url}
                                      style={{
                                        textDecoration: "none",
                                        color: "var(--text-color)",
                                      }}
                                    >
                                      {data.page}
                                    </NavLink>
                                  </li>
                                );
                              })}
                            </ul>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="4">
                          <Accordion.Header>
                            <p className="ong_pr" style={{ color: "var(--primary-color)" }}>
                              Connect with us
                            </p>
                            <img
                              className="float-right icon"
                              src={downArrow}
                              alt="arrow icon"
                            ></img>
                          </Accordion.Header>
                          <Accordion.Body>
                            <ul style={{ listStyle: "none" }}>
                              <li>
                                <NavLink
                                  to="/contact-us"
                                  style={{
                                    textDecoration: "none",
                                    color: "var(--text-color)",
                                  }}
                                >
                                  Contact Us
                                </NavLink>
                              </li>
                              <li>
                                <a
                                  style={{
                                    textDecoration: "none",
                                    color: "var(--text-color)",
                                  }}
                                  href="https://www.google.com/maps?ll=25.154669,75.865143&z=15&t=m&hl=en&gl=IN&mapclient=embed&q=Multimetals+Kota+Industrial+Area+Kota,+Rajasthan+324007"
                                  target="main"
                                  className="address"
                                >
                                  Multimetals Limited, Heavy Industries Area,
                                  Kansua Road, Kota-324003, Rajasthan, India
                                </a>
                              </li>
                            </ul>
                          </Accordion.Body>
                        </Accordion.Item>
                        {/* <Accordion.Item eventKey="3">
                          <Accordion.Header>
                            <p className="ong_pr" style={{ color: "#FFC295" }}>
                              Address
                            </p>
                            <img
                              className="float-right icon"
                              src={downArrow}
                              alt="arrow icon"
                            ></img>
                          </Accordion.Header>
                          <Accordion.Body>
                            <p
                              style={{
                                fontSize: "14px",
                                fontFamily: "Poppins",
                              }}
                            >
                              Multimetals Limited Heavy, Road, Kansuwa, Kota
                              Industrial Area, Kota, Rajasthan 324003
                            </p>
                          </Accordion.Body>
                        </Accordion.Item> */}
                      </Accordion>
                    </div>
                  </div>
                </div>
              </div>

              <div className="copy-right-d text-center text-white">
                <div className="">
                  <div className="d-flex justify-content-between">
                    <div>
                      <p className="">
                        All Rights reserved 2023 by{" "}
                        <Link to="/" style={{ color: "#fff" }}>
                          Multimetal
                        </Link>
                      </p>
                    </div>
                    <div>
                      <p className="">
                        Crafted by{" "}
                        <a
                          style={{ color: "#fff" }}
                          href="https://www.togglehead.in/"
                          target="_blank"
                        >
                          Togglehead
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div>
            <a href="mailto:1234567890">
              <button
                style={{
                  borderRadius: "5.745744096650192vw",
                  transition: "auto",
                }}
                className="mail_btn"
              >
                <img src={Msg} />
              </button>
            </a>
            <a href="tel:+91-744-2798550">
              <button
                style={{
                  borderRadius: "5.745744096650192vw",
                  transition: "auto",
                }}
                className="scrollTop show_btn QUICK_QUOTE"
              >
                <img src={Call} />
              </button>
            </a>
          </div> */}
        </footer>
      )}
    </>
  );
};

export default Footer;
