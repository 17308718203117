import Slide1 from "./assets/image/img12.png";
import Slide2 from "./assets/image/img16.png";
import Image1 from "./assets/Careers/image1.jpg";
import Image2 from "./assets/Careers/image2.jpg";
import Image3 from "./assets/Careers/image3.jpg";
import Image4 from "./assets/Careers/image4.jpg";
import ourStory from "./assets/home/homepage-about-us.jpg";
import ourStorySecondImg from "./assets/home/about-small-image.jpg";
import csrImg from "./assets/home/csrImg.png";
import Video from "./assets/MultimetalsAboutUs.mov";

// product section data at hom page

import UBendTubes from "./assets/home/product/UBendTubes/UBend.jpg";
import UBendTubesPreview from "./assets/home/product/UBendTubes/UBendPreview.png";
import FinnedTubes from "./assets/home/product/FinnedTubes/FinnedTube.jpg";
import FinnedTubesPreview from "./assets/home/product/FinnedTubes/FinnedTubePreview.png";
import CopperRoad from "./assets/home/product/CopperRoad/FRTPRod.jpg";
import CopperRoadPreview from "./assets/home/product/CopperRoad/FRTPRodPreview.png";
import Rings from "./assets/home/product/MachinedComponents/Rings 2.jpg";
import RingsPreview from "./assets/home/product/MachinedComponents/RingsPreview.png";
import AluminiumBrass from "./assets/home/product/Copper_CopperAlloysTube/AluminiumBrass.jpg";
import TubePreview from "./assets/home/product/Copper_CopperAlloysTube/Tube.png";

// trusted partners
import hyundai from "./assets/home/Trusted by client/Hyundai_1.png";
import knpc from "./assets/home/Trusted by client/knpc_1.png";
import kohler from "./assets/home/Trusted by client/kohler_1.png";
import Lukoil from "./assets/home/Trusted by client/lukoil_1.png";
import Md from "./assets/home/Trusted by client/MD_1.png";
import NPCIL from "./assets/home/Trusted by client/NPCIL_1.png";
import ntpc from "./assets/home/Trusted by client/ntpc_1.png";
import Petrobras from "./assets/home/Trusted by client/petrobras_1.png";
import reliance from "./assets/home/Trusted by client/reliance_1.png";
import Samsung from "./assets/home/Trusted by client/Samsung_logo_blue_1.png";
import SaudiAramco from "./assets/home/Trusted by client/Saudi_aramco_1.png";
import thermax from "./assets/home/Trusted by client/thermax_1.png";
import thyssenkrup from "./assets/home/Trusted by client/thyssenkrup_1.png";

// certificate
import AD2000MERKBLATTW0TUVNORD from "./assets/home/QUALITY CERTIFICATES - NAMES/AD2000-MERKBLATT-W0-TUV-NORD.png";
import AS9100DDNVGL from "./assets/home/QUALITY CERTIFICATES - NAMES/AS9100D-DNVGL.png";
import ENGINEERSINDIALIMITED from "./assets/home/QUALITY CERTIFICATES - NAMES/ENGINEERS-INDIA-LIMITED.png";
import ISO90012015 from "./assets/home/QUALITY CERTIFICATES - NAMES/ISO-90012015.png";
import ISO140012015 from "./assets/home/QUALITY CERTIFICATES - NAMES/ISO-140012015.png";
import ISOIEC170252017NABL from "./assets/home/QUALITY CERTIFICATES - NAMES/ISO-IEC-170252017-NABL.png";
import LLOYDWORKREGISTER from "./assets/home/QUALITY CERTIFICATES - NAMES/LLOYD’S-WORK-REGISTER.png";
import PEDTUVNORD from "./assets/home/QUALITY CERTIFICATES - NAMES/PED-TUV-NORD.png";

// awards
import EEPCINDIAExportExcellence from "./assets/home/Awards _ Recognition names/EEPCINDIAExport-Excellence2002-03.jpg";
import EEPCINDIAStarPerformer from "./assets/home/Awards _ Recognition names/EEPCINDIAStar-Performer2014-15.jpg";
import EEPCINDIAStarPerformer1 from "./assets/home/Awards _ Recognition names/EEPCINDIAStar-Performer2016-17.jpg";
import EEPCINDIAStarPerformer2 from "./assets/home/Awards _ Recognition names/EEPCINDIAStar-Performer2017-18.jpg";
import RajasthanStateExportAwardExportExcellence1 from "./assets/home/Awards _ Recognition names/Rajasthan State Export Award Export Excellence  1995-96.jpg";
import RajasthanStateExportAwardExportExcellence2 from "./assets/home/Awards _ Recognition names/Rajasthan-State-Export-Award-Export-Excellence-2003-04.jpg";
import RajasthanStateExportAwardExportExcellence3 from "./assets/home/Awards _ Recognition names/Rajasthan-State-Export-Award-Highest-Exports2021.jpg";
import RajasthanStateExportAwardExportExcellence4 from "./assets/home/Awards _ Recognition names/Rajasthan-State-Export-Award-Highest-Growth2021.jpg";

// upcoming exibition
import locationIcon from "./assets/location-icon.png";
import timeIcon from "./assets/timeIcon.png";
import Apr15 from "./assets/upcoming-date.png";

// gallery images
// Plant & Machinery
import plantMachineryImg1 from "./assets/Gallery/Plant_Machinery/image1.jpg";
import plantMachineryImg2 from "./assets/Gallery/Plant_Machinery/image2.jpg";
import plantMachineryImg3 from "./assets/Gallery/Plant_Machinery/image3.jpg";
import plantMachineryImg4 from "./assets/Gallery/Plant_Machinery/image4.jpg";
import plantMachineryImg5 from "./assets/Gallery/Plant_Machinery/image5.jpg";
import plantMachineryImg6 from "./assets/Gallery/Plant_Machinery/image6.jpg";
import plantMachineryImg7 from "./assets/Gallery/Plant_Machinery/image7.jpg";
import plantMachineryImg8 from "./assets/Gallery/Plant_Machinery/image8.jpg";
import plantMachineryImg9 from "./assets/Gallery/Plant_Machinery/image9.jpg";
import plantMachineryImg10 from "./assets/Gallery/Plant_Machinery/image10.jpg";
import plantMachineryImg11 from "./assets/Gallery/Plant_Machinery/image11.jpg";
import plantMachineryImg12 from "./assets/Gallery/Plant_Machinery/image12.jpg";
import plantMachineryImg13 from "./assets/Gallery/Plant_Machinery/image13.jpg";
import plantMachineryImg14 from "./assets/Gallery/Plant_Machinery/image14.jpg";

// Infrastructure
import infrastructureImg1 from "./assets/Gallery/Infrastructure/image1.jpg";
import infrastructureImg2 from "./assets/Gallery/Infrastructure/image2.jpg";
import infrastructureImg3 from "./assets/Gallery/Infrastructure/image3.jpg";
import infrastructureImg4 from "./assets/Gallery/Infrastructure/image4.jpg";
import infrastructureImg5 from "./assets/Gallery/Infrastructure/image5.jpg";
import infrastructureImg6 from "./assets/Gallery/Infrastructure/image6.jpg";
import infrastructureImg7 from "./assets/Gallery/Infrastructure/image7.jpg";
import infrastructureImg8 from "./assets/Gallery/Infrastructure/image8.jpg";
import infrastructureImg9 from "./assets/Gallery/Infrastructure/image9.jpg";
import infrastructureImg10 from "./assets/Gallery/Infrastructure/image10.jpg";
import infrastructureImg11 from "./assets/Gallery/Infrastructure/image11.jpg";
import infrastructureImg12 from "./assets/Gallery/Infrastructure/image12.jpg";
import infrastructureImg13 from "./assets/Gallery/Infrastructure/image13.jpg";
import infrastructureImg14 from "./assets/Gallery/Infrastructure/image14.jpg";
// Exhibitions
import exhibitionsImg1 from "./assets/Gallery/Exhibitions/image1.jpg";
import exhibitionsImg2 from "./assets/Gallery/Exhibitions/image2.jpg";
import exhibitionsImg3 from "./assets/Gallery/Exhibitions/image3.jpg";
import exhibitionsImg4 from "./assets/Gallery/Exhibitions/image4.jpg";
import exhibitionsImg5 from "./assets/Gallery/Exhibitions/image5.jpg";
import exhibitionsImg6 from "./assets/Gallery/Exhibitions/image6.jpg";
import exhibitionsImg7 from "./assets/Gallery/Exhibitions/image7.jpg";
import exhibitionsImg8 from "./assets/Gallery/Exhibitions/image8.jpg";
import exhibitionsImg9 from "./assets/Gallery/Exhibitions/image9.jpg";
import exhibitionsImg10 from "./assets/Gallery/Exhibitions/image10.jpg";
import exhibitionsImg11 from "./assets/Gallery/Exhibitions/image11.jpg";
import exhibitionsImg12 from "./assets/Gallery/Exhibitions/image12.jpg";
import exhibitionsImg13 from "./assets/Gallery/Exhibitions/image13.jpg";
import exhibitionsImg14 from "./assets/Gallery/Exhibitions/image14.jpg";
import exhibitionsImg15 from "./assets/Gallery/Exhibitions/image15.jpg";

// other
import otherImg1 from "./assets/Gallery/Other/image1.jpg";
import otherImg2 from "./assets/Gallery/Other/image2.jpg";
import otherImg3 from "./assets/Gallery/Other/image3.jpg";
import otherImg4 from "./assets/Gallery/Other/image4.jpg";
import otherImg5 from "./assets/Gallery/Other/image5.jpg";
import otherImg6 from "./assets/Gallery/Other/image6.jpg";
import otherImg7 from "./assets/Gallery/Other/image7.jpg";
import otherImg8 from "./assets/Gallery/Other/image8.jpg";
import otherImg9 from "./assets/Gallery/Other/image9.jpg";
import otherImg10 from "./assets/Gallery/Other/image10.jpg";
import otherImg11 from "./assets/Gallery/Other/image11.jpg";

// about us images
import timeline1 from "./assets/about/1st-timeline.jpg";
import timeline2 from "./assets/about/2nd-timeline.jpg";
import timeline3 from "./assets/about/3rd-timeline.jpg";
import timeline4 from "./assets/about/4th-timeline.jpg";
import timeline5 from "./assets/about/5th-timeline.jpg";
import mission from "./assets/about/mission.jpg";
import vision from "./assets/about/vision.jpg";
import { mmlcoporateVideo } from "./assets";

export const heroData = {
  content: "Engineered for excellence",
  // videoHome: mmlcoporateVideo
  media:
    "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
  // "media": "https://www.starmatik.com/wp-content/uploads/2022/04/Home-Clip-04.mp4",
};

export const ourStoryData = {
  title:
    "ENGINEERING SUPERIOR QUALITY AND SUSTAINABLE COPPER/COPPER ALLOYS FOR A LEADING INDUSTRY FUTURE",
  content:
    "With a legacy dating back to 1962, Multimetals has established itself as a premier provider and renowned manufacturer of copper/copper alloys in the industry. Our commitment to quality is reflected in our extensive product range with state of the art testing, all of which conform to international standards. Trusted by diverse industries like Petroleum Refineries, Nuclear, aerospace, Thermal Power Plants, Defence establishment, and Ship Building & Repairs, we are committed to delivering excellence and reliability in every product.",
  quality: [
    {
      id: 1,
      count: 60,
      text: "years of experience",
    },
    {
      id: 2,
      count: 20,
      text: "Types of Copper Alloys we deal",
    },
    {
      id: 3,
      count: 60,
      text: "Worldwide Countries",
    },
    {
      id: 4,
      count: 19,
      text: "Export Excellence Awards",
    },
  ],
  image1: ourStory,
  image2: ourStorySecondImg,
};

export const globalPresenceData = {
  title: "GLOBAL<br>PRESENCE",
  content:
    "Multimetals has established a global presence, spanning across 60-plus countries, encompassing continents such as Asia, Europe, North America, South America, Oceania and Middle East.",
  count: 60,
  locationMarks: [
    {
      id: 1,
      name: "Algeria",
      left: 46,
      top: 36,
      delay: "1s",
    },
    {
      id: 2,
      name: "Australia",
      left: 77,
      top: 78,
      delay: "1.9s",
    },
    {
      id: 3,
      name: "Austria",
      left: 50,
      top: 28,
      delay: "0.5s",
    },
    {
      id: 4,
      name: "Bahrain",
      left: 58,
      top: 39.5,
      delay: "0.7s",
    },
    {
      id: 5,
      name: "Bangladesh",
      left: 67,
      top: 44,
      delay: "1.3s",
    },
    {
      id: 6,
      name: "Brazil",
      left: 37,
      top: 63,
      delay: "1.7s",
    },
    {
      id: 7,
      name: "Belgium",
      left: 47.5,
      top: 36,
      delay: "1s",
    },
    {
      id: 8,
      name: "Canada",
      left: 24,
      top: 41,
      delay: "1s",
    },
    {
      id: 9,
      name: "Chile",
      left: 33.5,
      top: 75,
      delay: "1.9s",
    },
    {
      id: 10,
      name: "Czech Republic",
      left: 51,
      top: 27,
      delay: "0.6s",
    },
    {
      id: 11,
      name: "Denmark",
      left: 48.7,
      top: 22,
      delay: "0.3s",
    },
    {
      id: 12,
      name: "Egypt",
      left: 53,
      top: 38,
      delay: "1.1",
    },
    {
      id: 13,
      name: "Finland",
      left: 52,
      top: 19,
      delay: "0.2s",
    },
    {
      id: 14,
      name: "Germany",
      left: 49,
      top: 25,
      delay: "0.6s",
    },
    {
      id: 15,
      name: "Italy",
      left: 49.4,
      top: 30,
      delay: "0.8s",
    },
    {
      id: 16,
      name: "Jordan",
      left: 55,
      top: 37,
      delay: "1s",
    },
    {
      id: 17,
      name: "Kuwait",
      left: 59,
      top: 38,
      delay: "1.1s",
    },
    {
      id: 18,
      name: "Malaysia",
      left: 70.1,
      top: 56,
      delay: "1.5s",
    },
    {
      id: 19,
      name: "Mexico",
      left: 25,
      top: 62,
      delay: "1.7s",
    },
    {
      id: 20,
      name: "Morocco",
      left: 45,
      top: 37,
      delay: "1s",
    },
    {
      id: 21,
      name: "NetherLand",
      left: 48,
      top: 24.4,
      delay: "0.5s",
    },
    {
      id: 22,
      name: "New ZeaLand",
      left: 84,
      top: 96.4,
      delay: "2s",
    },
    {
      id: 23,
      name: "Oman",
      left: 59.7,
      top: 42,
      delay: "1.2s",
    },
    {
      id: 24,
      name: "Philippines",
      left: 74.5,
      top: 54,
      delay: "1.5s",
    },
    {
      id: 25,
      name: "Poland",
      left: 49.5,
      top: 24,
      delay: "0.4s",
    },
    {
      id: 26,
      name: "Portugal",
      left: 44.5,
      top: 33.6,
      delay: "0.9s",
    },
    {
      id: 27,
      name: "Qatar",
      left: 58.1,
      top: 40,
      delay: "1.2s",
    },
    {
      id: 28,
      name: "Romania",
      left: 51.2,
      top: 30,
      delay: "0.8s",
    },

    {
      id: 29,
      name: "Saudi Arabia",
      left: 57,
      top: 42,
      delay: "1.2s",
    },
    {
      id: 30,
      name: "Singapore",
      left: 70.3,
      top: 57.5,
      delay: "1.6s",
    },

    {
      id: 31,
      name: "Slovakia",
      left: 50,
      top: 26,
      delay: "0.6s",
    },
    {
      id: 32,
      name: "South Africa",
      left: 52,
      top: 68,
      delay: "1.8s",
    },
    {
      id: 33,
      name: "Spain",
      left: 46,
      top: 32,
      delay: "0.9s",
    },

    {
      id: 34,
      name: "Sweden",
      left: 51,
      top: 15.5,
      delay: "0.1s",
    },

    {
      id: 35,
      name: "Thailand",
      left: 70.5,
      top: 52,
      delay: "1.4s",
    },

    {
      id: 36,
      name: "UAE",
      left: 59,
      top: 40,
      delay: "1.1s",
    },

    {
      id: 37,
      name: "UK",
      left: 46,
      top: 25,
      delay: "0.5s",
    },

    {
      id: 38,
      name: "USA",
      left: 25,
      top: 49,
      delay: "1.4s",
    },

    {
      id: 39,
      name: "Venezuela",
      left: 32,
      top: 59,
      delay: "1.6s",
    },
    {
      id: 40,
      name: "Zambia",
      left: 54,
      top: 58,
      delay: "1.6s",
    },
    {
      id: 41,
      name: "France",
      left: 46.8,
      top: 27,
      delay: "0.5s",
    },
    {
      id: 42,
      name: "England",
      left: 46.5,
      top: 25,
      delay: "0.5s",
    },
    {
      id: 43,
      name: "Japan",
      left: 79,
      top: 49,
      delay: "1.4s",
    },
    {
      id: 44,
      name: "South Korea",
      left: 76.2,
      top: 46,
      delay: "1.4s",
    },
    {
      id: 45,
      name: "Tunisia",
      left: 48.7,
      top: 34,
      delay: "0.9s",
    },
    {
      id: 46,
      name: "Ivory Coast",
      left: 45.2,
      top: 50,
      delay: "1.7s",
    },
    {
      id: 47,
      name: "Argentina",
      left: 35.5,
      top: 79.1,
      delay: "1.9s",
    },
    {
      id: 48,
      name: "Armenia",
      left: 57,
      top: 31,
      delay: "0.8s",
    },
    {
      id: 49,
      name: "Bulgaria",
      left: 52,
      top: 30,
      delay: "0.8s",
    },
    {
      id: 50,
      name: "Cuba",
      left: 29.5,
      top: 55,
      delay: "1.5s",
    },
    {
      id: 51,
      name: "Ecuador",
      left: 30.2,
      top: 65.5,
      delay: "1.6s",
    },
    {
      id: 52,
      name: "Estonia",
      left: 52.2,
      top: 21,
      delay: "0.3s",
    },
    {
      id: 53,
      name: "Georgia",
      left: 56.5,
      top: 29,
      delay: "0.7s",
    },
    {
      id: 54,
      name: "Greece",
      left: 50.5,
      top: 29,
      delay: "0.8s",
    },
    {
      id: 55,
      name: "Indonesia",
      left: 73,
      top: 61,
      delay: "1.6s",
    },
    {
      id: 56,
      name: "India",
      left: 64.3,
      top: 46,
      delay: "1.4s",
    },
    {
      id: 57,
      name: "Norway",
      left: 49.2,
      top: 18,
      delay: "0.2s",
    },
    {
      id: 58,
      name: "Panama",
      left: 29.2,
      top: 62,
      delay: "1.8s",
    },
    {
      id: 59,
      name: "Peru",
      left: 30.5,
      top: 68,
      delay: "1.9s",
    },
    {
      id: 60,
      name: "Slovenia",
      left: 50,
      top: 29,
      delay: "0.6s",
    },
    {
      id: 61,
      name: "Switzerland",
      left: 49,
      top: 28,
      delay: "0.6s",
    },
    {
      id: 62,
      name: "Turkey",
      left: 53,
      top: 32,
      delay: "0.8s",
    },
  ],
};

export const trustedPartnersData = {
  title: "TRUSTED PARTNERS<br>IN SUCCESS",
  content:
    "Multimetals proudly serves a renowned clientele in different industry segments, including prestigious names like ThyssenKrupp, Saudi Aramco, Qatar Petroleum, Hyundai, ExxonMobil, Kohler, Samsung, Voltas, Reliance, and many more, reaffirming our commitment to excellence and trust.",
  partners: [
    {
      id: 1,
      images: [
        {
          id: 1,
          image: hyundai,
        },
        {
          id: 2,
          image: knpc,
        },
        {
          id: 3,
          image: kohler,
        },
      ],
    },
    {
      id: 2,
      images: [
        {
          id: 1,
          image: Lukoil,
        },
        {
          id: 2,
          image: Md,
        },
        {
          id: 3,
          image: NPCIL,
        },
      ],
    },
    {
      id: 3,
      images: [
        {
          id: 1,
          image: ntpc,
        },
        {
          id: 2,
          image: Petrobras,
        },
        {
          id: 3,
          image: reliance,
        },
      ],
    },
    {
      id: 4,
      images: [
        {
          id: 1,
          image: Samsung,
        },
        {
          id: 2,
          image: SaudiAramco,
        },
        {
          id: 3,
          image: thermax,
        },
        {
          id: 1,
          image: thyssenkrup,
        },
      ],
    },
  ],
};

export const crsData = {
  title: "NURTURING COMMUNITIES, INSPIRING CHANGE THROUGH OUR CSR PROGRAMS",
  content:
    "We believe in giving back to the community and making a positive impact through our Corporate Social Responsibility (CSR) initiatives. We have actively conducted various initiatives, including blood donation drives, where our employees enthusiastically contribute to saving lives. Additionally, our plantation drives have helped us contribute to environmental sustainability by creating green spaces and promoting a healthier ecosystem. We take pride in our food donation efforts, supporting the underprivileged by providing essential sustenance and nourishment. Through these CSR initiatives, we strive to make a positive and lasting impact on society, fostering a spirit of compassion and social responsibility.",
  image: csrImg,
};

export const certificationsData = {
  title: "MULTIMETALS ACCREDITATION AND CERTIFICATIONS",
  content:
    "Multimetals takes immense pride in its renowned and rare certifications, including the prestigious AS9100D certificate for Aerospace and Defence sectors, ISO 9001:2015 certification with Lloyds Register approval, PED Certification, self-certification AD 2000 Merkblatt W6/2 from TUV Nord, and NABL certification. These certifications demonstrate Multimetals authority, compliance, and commitment to delivering high-quality products that exceed industry standards. Additionally, as approved vendors for renowned petrochemical companies worldwide, Multimetals solidifies its position as a trusted provider of exceptional quality products.",
  images: [
    {
      id: 1,
      image: AD2000MERKBLATTW0TUVNORD,
    },
    {
      id: 2,
      image: AS9100DDNVGL,
    },
    {
      id: 3,
      image: ENGINEERSINDIALIMITED,
    },
    {
      id: 4,
      image: ISO90012015,
    },
    {
      id: 5,
      image: ISO140012015,
    },
    {
      id: 6,
      image: ISOIEC170252017NABL,
    },
    {
      id: 7,
      image: LLOYDWORKREGISTER,
    },
    {
      id: 8,
      image: PEDTUVNORD,
    },
    {
      id: 9,
      image: AD2000MERKBLATTW0TUVNORD,
    },
    {
      id: 10,
      image: AS9100DDNVGL,
    },
    {
      id: 11,
      image: ENGINEERSINDIALIMITED,
    },
    {
      id: 12,
      image: ISO90012015,
    },
    {
      id: 13,
      image: ISO140012015,
    },
    {
      id: 14,
      image: ISOIEC170252017NABL,
    },
    {
      id: 15,
      image: LLOYDWORKREGISTER,
    },
    {
      id: 16,
      image: PEDTUVNORD,
    },
  ],
};

export const awardsData = {
  title: "AWARDS AND RECOGNITION/AWARD WINNING EXCELLENCE",
  content:
    "We’re the Industry Leader with Over 19 Export Excellence Awards, a testament to our commitment to delivering exceptional quality and service to our valued clients.",
  images: [
    {
      id: 1,
      image: EEPCINDIAExportExcellence,
    },
    {
      id: 2,
      image: EEPCINDIAStarPerformer,
    },
    {
      id: 3,
      image: EEPCINDIAStarPerformer1,
    },
    {
      id: 4,
      image: EEPCINDIAStarPerformer2,
    },
    {
      id: 5,
      image: RajasthanStateExportAwardExportExcellence1,
    },
    {
      id: 6,
      image: RajasthanStateExportAwardExportExcellence2,
    },
    {
      id: 7,
      image: RajasthanStateExportAwardExportExcellence3,
    },
    {
      id: 8,
      image: RajasthanStateExportAwardExportExcellence4,
    },
  ],
};

export const upcomingExibitions = {
  title: "UPCOMING EXIBITION",
  content: [
    {
      id: 1,
      dateIcon: Apr15,
      heading: "Tube 2024 – Düsseldorf",
      timing: "10.00 am onwards",
      date: "15 April to 19 April",
      description1: "Hall: 03,",
      description2: "Stand no.: 3B29",
      locationIcon: locationIcon,
      timeIcon: timeIcon,
    },
    // {
    //     id: 2,
    //     dateIcon: Apr15,
    //     heading: "Lorem Ipsum simply dummy",
    //     timing: "10.00 am onwards",
    //     description: "Hall: 03, Stand no.: 3B29",
    //     locationIcon: locationIcon,
    //     timeIcon: timeIcon
    // },
    // {
    //     id: 3,
    //     dateIcon: Apr15,
    //     heading: "Lorem Ipsum simply dummy",
    //     timing: "10.00 am onwards",
    //     description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    //     locationIcon: locationIcon,
    //     timeIcon: timeIcon
    // },
    // {
    //     id: 4,
    //     dateIcon: Apr15,
    //     heading: "Lorem Ipsum simply dummy",
    //     timing: "10.00 am onwards",
    //     description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    //     locationIcon: locationIcon,
    //     timeIcon: timeIcon
    // },
    // {
    //     id: 5,
    //     dateIcon: Apr15,
    //     heading: "Lorem Ipsum simply dummy",
    //     timing: "10.00 am onwards",
    //     description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    //     locationIcon: locationIcon,
    //     timeIcon: timeIcon
    // },
    // {
    //     id: 6,
    //     dateIcon: Apr15,
    //     heading: "Lorem Ipsum simply dummy",
    //     timing: "10.00 am onwards",
    //     description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    //     locationIcon: locationIcon,
    //     timeIcon: timeIcon
    // },
    // {
    //     id: 7,
    //     dateIcon: Apr15,
    //     heading: "Lorem Ipsum simply dummy",
    //     timing: "10.00 am onwards",
    //     description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    //     locationIcon: locationIcon,
    //     timeIcon: timeIcon
    // }
  ],
};

// product section
export const productData = [
  {
    id: 1,
    slug: "tube-and-pipes",
    title: "Copper & Copper Alloys Tube",
    content:
      "Multimetals Copper & Copper Alloys Tubes are renowned for their high thermal conductivity, corrosion resistance, and excellent formability, making them the ideal choice for critical applications requiring reliability and efficiency across various industries such as HVAC, refrigeration, power generation, and more",
    image1: AluminiumBrass,
    image2: TubePreview,
  },
  {
    id: 2,
    slug: "cast-extruded-and-drawn-semis",
    title: "Copper & Copper Alloys Rod",
    content:
      "Copper & Copper Alloys Rods embody precision and reliability; with excellent electrical conductivity and remarkable strength, these rods are crafted to withstand demanding environments and deliver outstanding performance. From electrical wiring to components in marine vessels, our Copper & Copper Alloys Rods excel in various applications, ensuring optimal results and customer satisfaction.",
    image1: CopperRoad,
    image2: CopperRoadPreview,
  },
  {
    id: 3,
    slug: "speciality-and-finned-tubes",
    title: "Finned Tube",
    content:
      "Finned Tubes are designed to meet specific requirements in various industries, offering enhanced heat transfer and efficiency. These tubes are engineered with advanced techniques and specialized materials to cater to unique applications.",
    image1: FinnedTubes,
    image2: FinnedTubesPreview,
  },
  {
    id: 4,
    slug: "speciality-and-finned-tubes",
    title: "Machined Components",
    content:
      "From intricate parts in aircraft engines to critical components in automotive assemblies, our Machined Components demonstrate exceptional performance, durability, and reliability. With our state-of-the-art manufacturing facility and skilled team, we offer tailor-made solutions that meet the most stringent requirements of our valued customers. It’s a perfect amalgamation of advanced engineering and precision craftsmanship.",
    image1: Rings,
    image2: RingsPreview,
  },
  {
    id: 5,
    slug: "speciality-and-finned-tubes",
    title: "U Bend Tubes",
    content:
      "U Bend Tubes are versatile components used in heat transfer applications across various industries. Their unique U-shaped design allows for efficient routing of fluids and gasses, making them essential in heat exchangers, condensers, and boiler systems.",
    image1: UBendTubes,
    image2: UBendTubesPreview,
  },
];

// about data
export const aboutData = {
  title: "INTRODUCTION",
  content:
    "The unrivaled industry leader with a rich legacy spanning over 60 years. With our extensive expertise and unwavering commitment to excellence, we have established ourselves as a trusted name in the market, catering to a diverse range of industries. From power generation to Petrochemicals and aerospace, we deliver top-notch metal products and solutions that meet the highest standards of quality and performance. Our success lies in our ability to understand and adapt to the evolving needs of our clients, providing them with innovative and customized solutions. With our state-of-the-art manufacturing facilities, cutting-edge technology, and a team of seasoned professionals, we strive to exceed customer expectations at every step. Experience the Multimetals advantage and discover why we are the preferred choice of industry leaders worldwide.",
  corporateVideo: Video,
};

export const historyData = [
  {
    id: 1,
    title: "Formation and Collaboration",
    content:
      "Founded in 1962 as a joint venture with Revere Copper and Brass Inc., USA, Multimetals embarked on a journey driven by a passion for eminence. This strategic partnership laid the foundation for our commitment to delivering high-quality products and innovative solutions to the industry.",
    year: "1962",
    image: timeline1,
  },
  {
    id: 2,
    title: "Technological Advancement",
    content:
      "In 1990, Multimetals entered into a groundbreaking technical collaboration with M/s Hitachi Cable Ltd. Japan, a global leader in Air Conditioning and Refrigeration Copper tube technology. This collaboration propelled us to the forefront of technological advancements, enabling us to offer cutting-edge solutions and stay ahead of the competition.",
    year: "1990",
    image: timeline2,
  },
  {
    id: 3,
    title: "Export Excellence and Recognition",
    content:
      "Since 1993, Multimetals has been honored with more than 13 prestigious Export Excellence awards, recognizing our unwavering dedication to exceptional quality and customer satisfaction. These accolades reinforce our position as a trusted industry leader and motivate us to continuously raise the bar in everything we do.",
    year: "1993",
    image: timeline3,
  },
  {
    id: 4,
    title: "Accreditation for Quality",
    content:
      "In 2010, Multimetals achieved the National Accreditation Board for Testing and Calibration Laboratories (NABL) accreditation as per ISO 17025:2005. This accreditation signifies our commitment to precision and accuracy in testing and calibration processes. Currently, we hold the ISO 17025:2017 accreditation, underscoring our dedication to maintaining the highest quality standards.",
    year: "2010",
    image: timeline4,
  },
  {
    id: 5,
    title: "Quality Management System Approval",
    content:
      "In 2015, Multimetals received approval for our Quality Management System as per AS9100D/ISO 9001:2015 from DNV Germany. This esteemed certification validates our commitment to quality, process efficiency, and continuous improvement. It further reinforces our ability to meet the stringent requirements of the aerospace and defense sectors.<br>Throughout our journey, Multimetals has remained at the forefront of innovation and brilliance, revolutionizing the industry with our cutting-edge solutions. We are proud of our rich history and the milestones we have achieved. As the supreme leader in the industry, we continue to push boundaries, exceed expectations, and shape the future of our industry.",
    year: "2015",
    image: timeline5,
  },
];

export const missionData = [
  {
    id: 1,
    title: "MISSION",
    content:
      "Multimetals is dedicated to revolutionizing the industry through unwavering commitment to excellence, innovation, and environmental sustainability. We inspire innovation, empower industries, and set international benchmarks with exceptional products and dedication to perfection.",
    image: mission,
  },
  {
    id: 2,
    title: "VISION",
    content:
      "Multimetals envisions a world where honesty, integrity, and ethics guide the creation of technically advanced, environmentally responsible copper solutions. As a dynamic global leader, we are committed to delivering cost-efficient, innovative products that consistently exceed customer expectations.",
    image: vision,
  },
];

// gallery page
export const galleryData = [
  {
    id: 1,
    title: "Plant & Machinery",
    images: [
      {
        img: plantMachineryImg1,
      },
      {
        img: plantMachineryImg2,
      },
      {
        img: plantMachineryImg3,
      },
      {
        img: plantMachineryImg4,
      },
      {
        img: plantMachineryImg5,
      },
      {
        img: plantMachineryImg6,
      },
      {
        img: plantMachineryImg7,
      },
      {
        img: plantMachineryImg8,
      },
      {
        img: plantMachineryImg9,
      },
      // {
      //   img: plantMachineryImg10,
      // },
      {
        img: plantMachineryImg14,
      },
      {
        img: plantMachineryImg11,
      },
      {
        img: plantMachineryImg12,
      },
      {
        img: plantMachineryImg13,
      },
    ],
  },
  {
    id: 2,
    title: "Infrastructure",
    images: [
      {
        img: infrastructureImg1,
      },
      {
        img: infrastructureImg2,
      },
      {
        img: infrastructureImg3,
      },
      {
        img: infrastructureImg4,
      },
      {
        img: infrastructureImg5,
      },
      {
        img: infrastructureImg6,
      },
      {
        img: infrastructureImg7,
      },
      {
        img: infrastructureImg8,
      },
      {
        img: infrastructureImg9,
      },
      {
        img: infrastructureImg10,
      },
      {
        img: infrastructureImg11,
      },
      {
        img: infrastructureImg12,
      },
      {
        img: infrastructureImg13,
      },
      {
        img: infrastructureImg14,
      },
    ],
  },
  {
    id: 3,
    title: "Exhibitions",
    images: [
      {
        img: exhibitionsImg1,
      },
      {
        img: exhibitionsImg2,
      },
      {
        img: exhibitionsImg3,
      },
      // {
      //   img: exhibitionsImg4,
      // },
      {
        img: exhibitionsImg5,
      },
      {
        img: exhibitionsImg6,
      },
      {
        img: exhibitionsImg7,
      },
      {
        img: exhibitionsImg8,
      },
      {
        img: exhibitionsImg9,
      },
      {
        img: exhibitionsImg10,
      },
      // {
      //   img: exhibitionsImg11,
      // },
      {
        img: exhibitionsImg12,
      },
      {
        img: exhibitionsImg13,
      },
      {
        img: exhibitionsImg14,
      },
      {
        img: exhibitionsImg15,
      },
    ],
  },
  {
    id: 4,
    title: "Products",
    images: [
      {
        img: otherImg1,
      },
      // {
      //     img: otherImg2,

      // },
      {
        img: otherImg3,
      },
      {
        img: otherImg4,
      },
      {
        img: otherImg5,
      },
      {
        img: otherImg6,
      },
      {
        img: otherImg7,
      },
      {
        img: otherImg9,
      },
      {
        img: otherImg8,
      },
      {
        img: otherImg10,
      },
      // {
      //     img: otherImg11,

      // },
    ],
  },
];

// career page

export const careerHeroData = {
  id: 1,
  title: "CAREERS AT MULTIMETALS",
  content:
    "At Multimetals, we offer a dynamic and inclusive work environment that fosters growth, innovation, and career development. We value our employees as our greatest asset and provide them with opportunities to enhance their skills, expand their knowledge, and contribute to meaningful projects. With a strong emphasis on work-life balance, employee well-being, and a culture of collaboration, Multimetals is the ideal place to build a rewarding and fulfilling career.",
  image1: Image1,
  image2: Image2,
  image3: Image3,
  image4: Image4,
};

export const careerBenefits = [
  {
    id: 1,
    content: `Growth Opportunities: At Multimetals, we believe in nurturing and developing our employee's talents. We provide ample opportunities for professional growth and advancement through training programs, mentorship, and challenging assignments.`,
  },
  {
    id: 2,
    content:
      "Collaborative Work Environment: We foster a collaborative and inclusive work environment where teamwork is valued. Our employees have the opportunity to collaborate with talented professionals from diverse backgrounds, allowing for rich learning experiences and the development of valuable networks.",
  },
  {
    id: 3,
    content:
      "Technological Advancements: Multimetals stays at the forefront of technological advancements in the industry. As an employee, you will have access to cutting-edge technologies and tools, enabling you to enhance your skills and stay ahead in your field.",
  },
  {
    id: 4,
    content:
      "Work-Life Balance: We understand the importance of maintaining a healthy work-life balance. We offer flexible work arrangements, wellness programs, and initiatives to promote employee well-being, ensuring that you can thrive both personally and professionally.",
  },
  {
    id: 5,
    content: `Competitive Compensation and Benefits: We recognize and reward our employee's contributions through competitive compensation packages and a comprehensive range of benefits. From healthcare coverage to retirement plans, we strive to provide our employees with financial security and peace of mind.`,
  },
  {
    id: 6,
    content:
      "Commitment to Diversity and Inclusion: Multimetals values diversity and inclusion as key drivers of innovation and success. We embrace a culture that respects and appreciates individual differences, fostering a welcoming and inclusive environment where everyone's voice is heard and valued.",
  },
];

export const careerApplyForJob = [
  {
    id: 1,
    position: "Business Head",
    department: "Human Resources",
    location: "Mumbai, India",
    jobType: "	Intern",
    jobDescription:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    skills: [
      {
        id: 1,
        skill:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      },
      {
        id: 2,
        skill:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      },
      {
        id: 3,
        skill:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      },
      {
        id: 4,
        skill:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      },
      {
        id: 5,
        skill:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      },
      {
        id: 6,
        skill:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      },
    ],
    qualifications: [
      {
        id: 1,
        qualification:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      },
      {
        id: 2,
        qualification:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      },
      {
        id: 3,
        qualification:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      },
      {
        id: 4,
        qualification:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      },
      {
        id: 5,
        qualification:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      },
      {
        id: 6,
        qualification:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      },
    ],
  },
  {
    id: 2,
    position: "Business Head",
    department: "Human Resources",
    location: "Mumbai, India",
    jobType: "	Intern",
    jobDescription:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    skills: [
      {
        id: 1,
        skill:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      },
      {
        id: 2,
        skill:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      },
      {
        id: 3,
        skill:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      },
      {
        id: 4,
        skill:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      },
      {
        id: 5,
        skill:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      },
      {
        id: 6,
        skill:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      },
    ],
    qualifications: [
      {
        id: 1,
        qualification:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      },
      {
        id: 2,
        qualification:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      },
      {
        id: 3,
        qualification:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      },
      {
        id: 4,
        qualification:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      },
      {
        id: 5,
        qualification:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      },
      {
        id: 6,
        qualification:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      },
    ],
  },
  {
    id: 3,
    position: "Business Head",
    department: "Human Resources",
    location: "Mumbai, India",
    jobType: "	Intern",
    jobDescription:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    skills: [
      {
        id: 1,
        skill:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      },
      {
        id: 2,
        skill:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      },
      {
        id: 3,
        skill:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      },
      {
        id: 4,
        skill:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      },
      {
        id: 5,
        skill:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      },
      {
        id: 6,
        skill:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      },
    ],
    qualifications: [
      {
        id: 1,
        qualification:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      },
      {
        id: 2,
        qualification:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      },
      {
        id: 3,
        qualification:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      },
      {
        id: 4,
        qualification:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      },
      {
        id: 5,
        qualification:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      },
      {
        id: 6,
        qualification:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      },
    ],
  },
];

export const DataTable = [
  {
    id: 1,
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has been the industry's standard dummy Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    image: Slide1,
    name: "Sylvain Saloman1,",
    position: "Engineer",
  },
  {
    id: 2,
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has been the industry's standard dummy Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    image: Slide2,
    name: "Sylvain Saloman2,",
    position: "Engineer",
  },
  {
    id: 3,
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has been the industry's standard dummy Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    image: Slide1,
    name: "Sylvain Saloman3,",
    position: "Engineer",
  },
  {
    id: 4,
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has been the industry's standard dummy Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    image: Slide2,
    name: "Sylvain Saloman4,",
    position: "Engineer",
  },
  {
    id: 5,
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has been the industry's standard dummy Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    image: Slide1,
    name: "Sylvain Saloman5,",
    position: "Engineer",
  },
  {
    id: 6,
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has been the industry's standard dummy Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    image: Slide2,
    name: "Sylvain Saloman6,",
    position: "Engineer",
  },
];
