import { React, useEffect, useRef } from "react";
import "./manufacturingprocessinner.scss";
import { billetsaw, leftArrow } from "../../assets";
import LocomotiveScroll from "locomotive-scroll";
import { textVariants } from "../../Header/Motion";
import { motion } from "framer-motion";
import Footer from "../../Footer/Footer";
import PreLoader from "../../loader/preloader";
import { Link } from "react-router-dom";
import LeftArrowCtaSvg from "../../common/leftarrowCtaSvg";
const imagesLoaded = require("imagesloaded");

const Billetsaw = () => {
  const scrollRef = useRef(null);

  useEffect(() => {
    let scrollContainer = document.querySelector("[data-scroll-container]");
    const scroll = new LocomotiveScroll({
      el: scrollRef.current,
      smooth: true,
      getSpeed: true,
      getDirection: true,
      multiplier: 0.4, // Adjust the scroll speed here
    });

    scroll.update(); // Manually update the scroll height

    imagesLoaded(scrollContainer, { background: true }, function () {
      scroll.update();
    });
    const resizeObserver = new ResizeObserver((entries) => {
      scroll.update();
    });

    // start observing a DOM node
    resizeObserver.observe(document.body);
    return () => {
      resizeObserver.unobserve(document.body);

      scroll.destroy();
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <PreLoader />
      <div
        data-scroll-container
        ref={scrollRef}
        style={{
          background: "#141414",
          overflow: "hidden",
        }}
      >
        <section id="scrolltop" className="billet_saw_sec">
          {/* <Commonbanner2
            linkText="ABOUT US"
            link="/about"
            linkText1="MANUFACTURING PROCESS"
            link1="/manufacturing-process"
            linkText2="BILLET SAW"
            link2="/billet-saw"
          /> */}
        </section>
        <section className="billet_saw_sec1">
          <Link to="/manufacturing-process" className="back_btn_wrapper">
            <div className="back_cta_arrow">
              <LeftArrowCtaSvg />
            </div>
            <button className="back_btn"> Back</button>
          </Link>
          <div className="billet_saw_container">
            <div className="billet_saw_content">
              <motion.div
                variants={textVariants("left", 0.2)}
                initial="hidden"
                whileInView="show"
                viewport={{ once: true, amount: 0.1 }}
                transition={{ duration: 0.5 }}
              >
                <h2 className="billet_saw_heading">BILLET SAW</h2>
                <p className="billet_saw_details">
                  Once the billets have formed through the solidification
                  process, they are cut into the desired lengths using a billet
                  saw. The billet saw is a precision cutting machine that allows
                  for accurate and clean cuts of the billets. This ensures that
                  the billets are cut to the required length with high precision
                  and minimal wastage. The billet saw plays a crucial role in
                  the manufacturing process by providing the billet with the
                  desired length, ensuring easy machinability while going
                  through the Extrusion Press in the next step of the process.
                </p>
              </motion.div>
            </div>
            <div className="billet_saw_image_wrapper">
              <motion.div
                variants={textVariants("right", 0.2)}
                initial="hidden"
                whileInView="show"
                viewport={{ once: true, amount: 0.1 }}
                transition={{ duration: 0.5 }}
              >
                <img
                  src={billetsaw}
                  alt="billet saw"
                  className="billet_saw_img"
                />
              </motion.div>
            </div>
          </div>
        </section>
        <section
          className="footer_area"
          style={{
            overflowY: "hidden",
          }}
        >
          <Footer />
        </section>
      </div>
    </>
  );
};

export default Billetsaw;
