import React, { useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import {
  financial2014,
  downloadicon,
  annual2015,
  annual2016,
  annual2017,
  annual2018,
  annual2019,
  annual2020,
  annual2021,
  annual2022,
  financial2022,
  financial2021,
  financial2020,
  financial2019,
  financial2018,
  financial2017,
  financial2016,
  financial2015,
  financial2023,
  annual2023,
} from "../../assets";
import { useWindowSize } from "react-use";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { ExpandMore } from "@material-ui/icons";
import { textVariants } from "../../Header/Motion";
import { motion } from "framer-motion";
import { useRef } from "react";
import { useEffect } from "react";

const financialArr = [
  {
    id: 1,
    tabName: "Annual Return",
    description:
      "An overview of our Annual Meetings, where we gather to celebrate our achievements, set new goals, and engage in productive discussions for the future of Multimetals and the industry.",
    documents: [
      {
        title: "Annual Return 2023",
        file: annual2023,
      },
      {
        title: "Annual Return 2022",
        file: annual2022,
      },
      {
        title: "Annual Return 2021",
        file: annual2021,
      },
      {
        title: "Annual Return 2020",
        file: annual2020,
      },
      {
        title: "Annual Return 2019",
        file: annual2019,
      },
      {
        title: "Annual Return 2018",
        file: annual2018,
      },
      {
        title: "Annual Return 2017",
        file: annual2017,
      },
      {
        title: "Annual Return 2016",
        file: annual2016,
      },
      {
        title: "Annual Return 2015",
        file: annual2015,
      },
    ],
  },
  {
    id: 2,
    tabName: "Notice of General Meetings",
    description:
      "We provide comprehensive insights into our company's financial performance, demonstrating our commitment to transparency and accountability. <br > Explore our financial reports, statements, and analysis to gain a deeper understanding of Multimetals financial strength, growth, and strategic initiatives.",
    documents: [
      {
        title: "Notice of AGM - 2023",
        file: financial2023,
      },
      {
        title: "Notice of AGM - 2022",
        file: financial2022,
      },
      {
        title: "Notice of AGM - 2021",
        file: financial2021,
      },
      {
        title: "Notice of AGM - 2020",
        file: financial2020,
      },
      {
        title: "Notice of AGM - 2019",
        file: financial2019,
      },
      {
        title: "Notice of AGM - 2018",
        file: financial2018,
      },
      {
        title: "Notice of AGM - 2017",
        file: financial2017,
      },
      {
        title: "Notice of AGM - 2016",
        file: financial2016,
      },
      {
        title: "Notice of AGM - 2015",
        file: financial2015,
      },
      {
        title: "Notice of AGM - 2014",
        file: financial2014,
      },
    ],
  },
];

export default function FinanceTabs() {
  const [value, setValue] = useState(1);
  const { width: windowWidth } = useWindowSize();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSelectChange = (event) => {
    setValue(event.target.value);
  };

  const tabList = financialArr.map((item, i) => (
    <Tab label={item.tabName} value={item.id} />
  ));
  const mbOptionList = financialArr.map((item, i) => (
    <MenuItem value={item.id}>{item.tabName}</MenuItem>
  ));
  const tabContentList = financialArr.map((item, i) => (
    <TabPanel value={item.id}>
      <motion.div
        variants={textVariants("up", 0.2)}
        initial="hidden"
        whileInView="show"
        viewport={{ once: true, amount: 0.1 }}
        transition={{ duration: 0.5 }}
      >
        <p
          className="category_desc"
          dangerouslySetInnerHTML={{ __html: item.description }}
        />
      </motion.div>
      <div className="row documents_row">
        {item.documents.map((item) => (
          <div className="col-6 col-sm-6 col-md-3 p-0">
            <motion.div
              variants={textVariants("up", 0.2)}
              initial="hidden"
              whileInView="show"
              viewport={{ once: true, amount: 0.1 }}
              transition={{ duration: 0.5 }}
            >
              <a href={item.file} target="_blank">
                <div className="document_box">
                  <img
                    className="download_icon"
                    src={downloadicon}
                    alt="download icon"
                    width={62}
                    height={62}
                    loading="lazy"
                  />
                  <h6 className="document_title">{item.title}</h6>
                </div>
              </a>
            </motion.div>
          </div>
        ))}
      </div>
    </TabPanel>
  ));
  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <TabContext value={value}>
        <Box>
          {windowWidth > 600 ? (
            <motion.div
              variants={textVariants("up", 0.2)}
              initial="hidden"
              whileInView="show"
              viewport={{ once: true, amount: 0.1 }}
              transition={{ duration: 0.5 }}
            >
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                {tabList}
              </TabList>
            </motion.div>
          ) : (
            <motion.div
              variants={textVariants("up", 0.2)}
              initial="hidden"
              whileInView="show"
              viewport={{ once: true, amount: 0.1 }}
              transition={{ duration: 0.5 }}
            >
              <FormControl>
                <Select
                  IconComponent={ExpandMore}
                  value={value}
                  onChange={handleSelectChange}
                  inputProps={{ "aria-label": "Without label" }}
                  sx={{
                    width: "90vw",
                    color: "#FFC295",
                    fontSize: "24px",
                    paddingLeft: "0",
                    textTransform: "uppercase",
                    fontFamily: "Secular One",
                    ".MuiOutlinedInput-input": {
                      paddingLeft: "0",
                      paddingTop: "5px",
                      paddingBottom: "5px",
                    },
                    ".MuiOutlinedInput-notchedOutline": {
                      borderRadius: "0",
                      border: "none",
                      borderBottom: "1px solid #FFC295",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderRadius: "0",
                      border: "none",
                      borderBottom: "1px solid #FFC295",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderRadius: "0",
                      border: "none",
                      borderBottom: "1px solid #FFC295",
                    },
                    ".MuiSvgIcon-root ": {
                      fill: "#FFC295 !important",
                      float: "right !important",
                    },
                  }}
                >
                  {mbOptionList}
                </Select>
              </FormControl>
            </motion.div>
          )}
        </Box>
        {tabContentList}
      </TabContext>
    </Box>
  );
}
