import React, { useEffect, useRef, useState } from "react";
import FsLightbox from "fslightbox-react";
import "../QualityV2/CustomerApprovalV2.scss";
import { images, leftArrow, shareicon } from "../../assets";
import { Breadcrumbs } from "@mui/material";
import { Link } from "react-router-dom";
import { textVariants } from "../../Header/Motion";
import { motion } from "framer-motion";
import Banner from "../../assets/Quality/customerapp.jpg";
import LocomotiveScroll from "locomotive-scroll";
import { useWindowSize } from "react-use";
import Footer from "../../Footer/Footer";
import PreLoader from "../../loader/preloader";
const imagesLoaded = require("imagesloaded");
import Lightbox from "yet-another-react-lightbox";
import Captions from "yet-another-react-lightbox/plugins/captions";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import slides from "yet-another-react-lightbox";
import Video from "yet-another-react-lightbox/plugins/video";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Counter from "yet-another-react-lightbox/plugins/counter";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/captions.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import { PrevICon } from "./PrevIcon";
import { NextICon } from "./NextIcon";

export const CustomerApprovalV2 = () => {
  const scrollRef = useRef(null);
  const { width } = useWindowSize();

  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [open5, setOpen5] = useState(false);
  const [open6, setOpen6] = useState(false);
  const [open7, setOpen7] = useState(false);
  const [open8, setOpen8] = useState(false);
  const [open9, setOpen9] = useState(false);
  const [open10, setOpen10] = useState(false);

  useEffect(() => {
    let scrollContainer = document.querySelector("[data-scroll-container]");
    const scroll = new LocomotiveScroll({
      el: scrollRef.current,
      smooth: true,
      getSpeed: true,
      getDirection: true,
      multiplier: 0.4, // Adjust the scroll speed here
    });

    scroll.update(); // Manually update the scroll height

    imagesLoaded(scrollContainer, { background: true }, function () {
      scroll.update();
    });
    const resizeObserver = new ResizeObserver((entries) => {
      scroll.update();
    });

    // start observing a DOM node
    resizeObserver.observe(document.body);

    return () => {
      resizeObserver.unobserve(document.body);

      scroll.destroy();
    };
  }, []);

  return (
    <>
      <PreLoader />
      <div
        className="customer_approval_container"
        ref={scrollRef}
        data-scroll-container
        style={{
          background: "#141414",
          backgroundImage: `url(${Banner})`,
          backgroundSize: width > 600 ? "cover" : "contain",
          overflow: "hidden",
          backgroundPosition: "center",
          backgroundAttachment: "fixed",
        }}
      >
        <section
          id="scrolltop"
          className="CustomerApproval_sec1"
          style={{
            overflow: "hidden",
          }}
          data-scroll-section
        >
          <div
            className="certification_bg"
            // style={{ backgroundImage: `url(${Banner})` }}
          >
            <div className="certification_breadcrumbs ">
              <Breadcrumbs separator=">" aria-label="breadcrumb">
                <Link to="/">
                  <img
                    src={images.homeicon.image}
                    alt={images.homeicon.alt}
                    loading="lazy"
                    width={20}
                  />{" "}
                </Link>
                <Link to="/quality-overview">QUALITY</Link>
                <Link to="/customer-approval">VENDOR APPROVAL</Link>
              </Breadcrumbs>
            </div>
            <div className="customer-heading">
              <motion.h2
                variants={textVariants("up", 0.2, 2.5)}
                initial="hidden"
                whileInView="show"
                viewport={{ once: true, amount: 0.1 }}
                transition={{ duration: 0.5 }}
              >
                VENDOR APPROVAL
              </motion.h2>
            </div>
            <div className="scroll-down-d" style={{ zIndex: "5" }}>
              <div className="spinner scroll-down">
                {" "}
                <a
                  style={{ cursor: "pointer" }}
                  href="#vendor"
                  data-scroll-to
                  className="animate"
                ></a>{" "}
              </div>
            </div>
          </div>
        </section>

        <section
          id="vendor"
          className="CustomerApproval_sec2"
          style={{
            background: "var(--bg-white-color)",
            height: "100%",
            overflow: "hidden",
          }}
          data-scroll-section
        >
          <div className="quality-assurance-section">
            {/* <motion.div
              variants={textVariants("up", 0.2)}
              initial="hidden"
              whileInView="show"
              viewport={{ once: true, amount: 0.1 }}
              transition={{ duration: 0.5 }}
            > */}
            <h2>VENDOR APPROVAL</h2>
            {/* </motion.div> */}
            <div>
              <motion.p
                variants={textVariants("up", 0.2)}
                initial="hidden"
                whileInView="show"
                viewport={{ once: true, amount: 0.1 }}
                transition={{ duration: 0.5 }}
              >
                We are honored to have earned vendor approval from renowned
                industry leaders at Multimetals. Our valued partnerships with
                esteemed organizations such as Petrobras, Abu Dhabi Marine
                Operating Company, Samsung, Kuwait National Petroleum Company,
                Saudi Aramco and Sabic exemplify our unwavering dedication to
                upholding stringent quality standards and adhering to rigorous
                quality control protocols.
                <br />
              </motion.p>
              <motion.p
                variants={textVariants("up", 0.2)}
                initial="hidden"
                whileInView="show"
                viewport={{ once: true, amount: 0.1 }}
                transition={{ duration: 0.5 }}
              >
                Being a vendor to these renowned companies is not only an honor
                but also a testament to our unwavering dedication to provide
                eminent quality. By aligning our practices with their quality
                standards and adhering to their strict QC protocols, we have
                established ourselves as a trusted partner in the industry.{" "}
                <br />
              </motion.p>
              <motion.p
                variants={textVariants("up", 0.2)}
                initial="hidden"
                whileInView="show"
                viewport={{ once: true, amount: 0.1 }}
                transition={{ duration: 0.5 }}
              >
                Our commitment to reliability, consistency, and customer
                satisfaction sets us apart and ensures that we consistently meet
                the expectations of our esteemed clients. Being their trusted
                partner is a sign of our capabilities and expertise, and we look
                forward to further strengthening these relationships as we work
                together to achieve mutual success.
              </motion.p>
            </div>

            <div className="row certificate_row approval_row">
              <div className="col-6 col-sm-6 col-md-4 p-0">
                <motion.div
                  variants={textVariants("up", 0.2)}
                  initial="hidden"
                  whileInView="show"
                  viewport={{ once: true, amount: 0.1 }}
                  transition={{ duration: 0.5 }}
                >
                  <div className="application_box">
                    <img
                      src={images.vendor1.image}
                      alt=""
                      style={{
                        width: "100%",
                        maxWidth: "",
                        height: "100%",
                        maxHeight: "375px",
                      }}
                      onClick={() => setOpen1(true)}
                    />
                  </div>
                </motion.div>
              </div>
              <div className="col-6 col-sm-6 col-md-4 p-0">
                <motion.div
                  variants={textVariants("up", 0.2)}
                  initial="hidden"
                  whileInView="show"
                  viewport={{ once: true, amount: 0.1 }}
                  transition={{ duration: 0.5 }}
                >
                  <div className="application_box">
                    <img
                      src={images.vendor3.image}
                      alt=""
                      style={{
                        width: "100%",
                        maxWidth: "",
                        height: "100%",
                        maxHeight: "375px",
                      }}
                      onClick={() => setOpen2(true)}
                    />
                  </div>
                </motion.div>
              </div>
              <div className="col-6 col-sm-6 col-md-4 p-0">
                <motion.div
                  variants={textVariants("up", 0.2)}
                  initial="hidden"
                  whileInView="show"
                  viewport={{ once: true, amount: 0.1 }}
                  transition={{ duration: 0.5 }}
                >
                  <div className="application_box">
                    <img
                      src={images.vendor4.image}
                      alt=""
                      style={{
                        width: "100%",
                        maxWidth: "",
                        height: "100%",
                        maxHeight: "375px",
                      }}
                      onClick={() => setOpen3(true)}
                    />
                  </div>
                </motion.div>
              </div>
              <div className="col-6 col-sm-6 col-md-4 p-0">
                <motion.div
                  variants={textVariants("up", 0.2)}
                  initial="hidden"
                  whileInView="show"
                  viewport={{ once: true, amount: 0.1 }}
                  transition={{ duration: 0.5 }}
                >
                  <div className="application_box">
                    <img
                      src={images.vendor7.image}
                      alt=""
                      style={{
                        width: "100%",
                        maxWidth: "",
                        height: "100%",
                        maxHeight: "375px",
                      }}
                      onClick={() => setOpen4(true)}
                    />
                  </div>
                </motion.div>
              </div>
              <div className="col-6 col-sm-6 col-md-4 p-0">
                <motion.div
                  variants={textVariants("up", 0.2)}
                  initial="hidden"
                  whileInView="show"
                  viewport={{ once: true, amount: 0.1 }}
                  transition={{ duration: 0.5 }}
                >
                  <div className="application_box">
                    <img
                      src={images.vendor8.image}
                      alt=""
                      style={{
                        width: "100%",
                        maxWidth: "",
                        height: "100%",
                        maxHeight: "375px",
                      }}
                      onClick={() => setOpen5(true)}
                    />
                  </div>
                </motion.div>
              </div>
              <div className="col-6 col-sm-6 col-md-4 p-0">
                <motion.div
                  variants={textVariants("up", 0.2)}
                  initial="hidden"
                  whileInView="show"
                  viewport={{ once: true, amount: 0.1 }}
                  transition={{ duration: 0.5 }}
                >
                  <div className="application_box">
                    <img
                      src={images.vendor9.image}
                      alt=""
                      style={{
                        width: "100%",
                        maxWidth: "",
                        height: "100%",
                        maxHeight: "375px",
                      }}
                      onClick={() => setOpen6(true)}
                    />
                  </div>
                </motion.div>
              </div>
              <div className="col-6 col-sm-6 col-md-4 p-0">
                <motion.div
                  variants={textVariants("up", 0.2)}
                  initial="hidden"
                  whileInView="show"
                  viewport={{ once: true, amount: 0.1 }}
                  transition={{ duration: 0.5 }}
                >
                  <div className="application_box">
                    <img
                      src={images.vendor10.image}
                      alt=""
                      style={{
                        width: "100%",
                        maxWidth: "",
                        height: "100%",
                        maxHeight: "375px",
                      }}
                      onClick={() => setOpen7(true)}
                    />
                  </div>
                </motion.div>
              </div>
              <div className="col-6 col-sm-6 col-md-4 p-0">
                <motion.div
                  variants={textVariants("up", 0.2)}
                  initial="hidden"
                  whileInView="show"
                  viewport={{ once: true, amount: 0.1 }}
                  transition={{ duration: 0.5 }}
                >
                  <div className="application_box">
                    <img
                      src={images.vendor11.image}
                      alt=""
                      style={{
                        width: "100%",
                        maxWidth: "",
                        height: "100%",
                        maxHeight: "375px",
                      }}
                      onClick={() => setOpen8(true)}
                    />
                  </div>
                </motion.div>
              </div>
              <div className="col-6 col-sm-6 col-md-4 p-0">
                <motion.div
                  variants={textVariants("up", 0.2)}
                  initial="hidden"
                  whileInView="show"
                  viewport={{ once: true, amount: 0.1 }}
                  transition={{ duration: 0.5 }}
                >
                  <div className="application_box">
                    <img
                      src={images.vendor12.image}
                      alt=""
                      style={{
                        width: "100%",
                        maxWidth: "",
                        height: "100%",
                        maxHeight: "375px",
                      }}
                      onClick={() => setOpen9(true)}
                    />
                  </div>
                </motion.div>
              </div>
              <div className="col-6 col-sm-6 col-md-4 p-0">
                <motion.div
                  variants={textVariants("up", 0.2)}
                  initial="hidden"
                  whileInView="show"
                  viewport={{ once: true, amount: 0.1 }}
                  transition={{ duration: 0.5 }}
                >
                  <div className="application_box">
                    <img
                      src={images.vendor13.image}
                      alt=""
                      style={{
                        width: "100%",
                        maxWidth: "",
                        height: "100%",
                        maxHeight: "375px",
                      }}
                      onClick={() => setOpen10(true)}
                    />
                  </div>
                </motion.div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="footer_area"
          data-scroll-section
          style={{
            overflowY: "hidden",
          }}
        >
          <Footer />
        </section>
      </div>

      <Lightbox
        open={open1}
        close={() => setOpen1(false)}
        slides={[{ src: images.vendor1.image }, { src: images.vendor2.image }]}
        // plugins={[Captions, Fullscreen, Slideshow, Video, Zoom, Counter]}
        plugins={[Zoom, Counter]}
        zoom={{
          // scrollToZoom:true,
          maxZoomPixelRatio: 5,
        }}
        animation={{
          zoom: 500,
        }}
        // counter={{ container: { style: { top: "unset", bottom: 0 } } }}
        // carousel={{ finite: slides.length <= 1 }}
        render={{
          iconPrev: () => <PrevICon />,
          iconNext: () => <NextICon />,
          // buttonPrev: slides.length <= 1 ? () => null : undefined,
          // buttonNext: slides.length <= 1 ? () => null : undefined,
        }}
      />

      <Lightbox
        open={open2}
        close={() => setOpen2(false)}
        slides={[{ src: images.vendor3.image }]}
        plugins={[Zoom, Counter]}
        zoom={{
          maxZoomPixelRatio: 5,
        }}
        animation={{
          zoom: 500,
        }}
        carousel={{ finite: slides.length <= 1 }}
        render={{
          iconPrev: () => <PrevICon />,
          iconNext: () => <NextICon />,
          buttonPrev: slides.length <= 1 ? () => null : undefined,
          buttonNext: slides.length <= 1 ? () => null : undefined,
        }}
      />

      <Lightbox
        open={open3}
        close={() => setOpen3(false)}
        slides={[
          { src: images.vendor4.image },
          { src: images.vendor5.image },
          { src: images.vendor6.image },
        ]}
        plugins={[Zoom, Counter]}
        zoom={{
          maxZoomPixelRatio: 5,
        }}
        animation={{
          zoom: 500,
        }}
        // carousel={{ finite: slides.length <= 1 }}
        render={{
          iconPrev: () => <PrevICon />,
          iconNext: () => <NextICon />,
          // buttonPrev: slides.length <= 1 ? () => null : undefined,
          // buttonNext: slides.length <= 1 ? () => null : undefined,
        }}
      />

      <Lightbox
        open={open4}
        close={() => setOpen4(false)}
        slides={[{ src: images.vendor7.image }]}
        plugins={[Zoom, Counter]}
        zoom={{
          maxZoomPixelRatio: 5,
        }}
        animation={{
          zoom: 500,
        }}
        carousel={{ finite: slides.length <= 1 }}
        render={{
          iconPrev: () => <PrevICon />,
          iconNext: () => <NextICon />,
          buttonPrev: slides.length <= 1 ? () => null : undefined,
          buttonNext: slides.length <= 1 ? () => null : undefined,
        }}
      />

      <Lightbox
        open={open5}
        close={() => setOpen5(false)}
        slides={[{ src: images.vendor8.image }]}
        plugins={[Zoom, Counter]}
        zoom={{
          maxZoomPixelRatio: 5,
        }}
        animation={{
          zoom: 500,
        }}
        carousel={{ finite: slides.length <= 1 }}
        render={{
          iconPrev: () => <PrevICon />,
          iconNext: () => <NextICon />,
          buttonPrev: slides.length <= 1 ? () => null : undefined,
          buttonNext: slides.length <= 1 ? () => null : undefined,
        }}
      />

      <Lightbox
        open={open6}
        close={() => setOpen6(false)}
        slides={[{ src: images.vendor9.image }]}
        plugins={[Zoom, Counter]}
        zoom={{
          maxZoomPixelRatio: 5,
        }}
        animation={{
          zoom: 500,
        }}
        carousel={{ finite: slides.length <= 1 }}
        render={{
          iconPrev: () => <PrevICon />,
          iconNext: () => <NextICon />,
          buttonPrev: slides.length <= 1 ? () => null : undefined,
          buttonNext: slides.length <= 1 ? () => null : undefined,
        }}
      />

      <Lightbox
        open={open7}
        close={() => setOpen7(false)}
        slides={[{ src: images.vendor10.image }]}
        plugins={[Zoom, Counter]}
        zoom={{
          maxZoomPixelRatio: 5,
        }}
        animation={{
          zoom: 500,
        }}
        carousel={{ finite: slides.length <= 1 }}
        render={{
          iconPrev: () => <PrevICon />,
          iconNext: () => <NextICon />,
          buttonPrev: slides.length <= 1 ? () => null : undefined,
          buttonNext: slides.length <= 1 ? () => null : undefined,
        }}
      />

      <Lightbox
        open={open8}
        close={() => setOpen8(false)}
        slides={[{ src: images.vendor11.image }]}
        plugins={[Zoom, Counter]}
        zoom={{
          maxZoomPixelRatio: 5,
        }}
        animation={{
          zoom: 500,
        }}
        carousel={{ finite: slides.length <= 1 }}
        render={{
          iconPrev: () => <PrevICon />,
          iconNext: () => <NextICon />,
          buttonPrev: slides.length <= 1 ? () => null : undefined,
          buttonNext: slides.length <= 1 ? () => null : undefined,
        }}
      />

      <Lightbox
        open={open9}
        close={() => setOpen9(false)}
        slides={[{ src: images.vendor12.image }]}
        plugins={[Zoom, Counter]}
        zoom={{
          maxZoomPixelRatio: 5,
        }}
        animation={{
          zoom: 500,
        }}
        carousel={{ finite: slides.length <= 1 }}
        render={{
          iconPrev: () => <PrevICon />,
          iconNext: () => <NextICon />,
          buttonPrev: slides.length <= 1 ? () => null : undefined,
          buttonNext: slides.length <= 1 ? () => null : undefined,
        }}
      />

      <Lightbox
        open={open10}
        close={() => setOpen10(false)}
        slides={[{ src: images.vendor14.image }]}
        plugins={[Zoom, Counter]}
        zoom={{
          maxZoomPixelRatio: 5,
        }}
        animation={{
          zoom: 500,
        }}
        carousel={{ finite: slides.length <= 1 }}
        render={{
          iconPrev: () => <PrevICon />,
          iconNext: () => <NextICon />,
          buttonPrev: slides.length <= 1 ? () => null : undefined,
          buttonNext: slides.length <= 1 ? () => null : undefined,
        }}
      />
    </>
  );
};
