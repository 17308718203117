import React, { useState, useEffect } from "react";
import "./productinner.scss";
import {
  demopdf,
  downloadicon,
  images,
  locationicon,
  proddownloadicon,
  shareicon,
  broucher,
  productSize,
  productInnerBanner,
} from "../../assets";
import share1 from "../../assets/productinner/whatsapp@2x.png";
import share2 from "../../assets/productinner/mail.svg";
import share3 from "../../assets/productinner/link@2x.png";
import { Breadcrumbs, MenuItem, Select } from "@mui/material";
import { Link, useParams, useNavigate } from "react-router-dom";
import FormControl from "@mui/material/FormControl";
import {
  ExpandMore,
  ArrowForwardIosSharp,
  ArrowDownward,
  KeyboardArrowDown,
} from "@material-ui/icons";
import { motion } from "framer-motion";
import {
  fixedContainer,
  pathCircleVariants,
  textVariants,
} from "../../Header/Motion";
import {
  findCategoryBySlug,
  findProductBySlug,
  productsArr,
  findSuperCategoryWhere,
} from "../../data/productsData";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { useWindowSize } from "react-use";
import { useRef } from "react";
import LocomotiveScroll from "locomotive-scroll";
import Footer from "../../Footer/Footer";
import PreLoader from "../../loader/preloader";
import { EmailShareButton, WhatsappShareButton } from "react-share";
import http from "../../helpers/http";
import config from "../../config/config";
const imagesLoaded = require("imagesloaded");

const ProductInner = () => {
  const [value, setValue] = useState(0);
  const [childIndex, setChildIndex] = useState(0);
  const [expanded, setExpanded] = useState("panel1");
  const { width: windowWidth } = useWindowSize();
  const scrollRef = useRef(null);
  const specsheetRef = useRef(null);

  let { categorySlug, productSlug } = useParams();

  const [product, setProduct] = useState({});
  const [dynamicProduct, setDynamicProduct] = useState({});
  const [detail, setDetail] = useState({});
  const [copied, setCopied] = useState(false);
  const [category, setCategory] = useState({});
  const standardLenght = detail?.standards?.length;
  console.log("check lenght", standardLenght);
  let base_url = config.api_url + "/products";

  let navigate = useNavigate();

  const getDynamicProduct = async (slug) => {
    const { data } = await http.get(`${base_url}/${slug}`);
    console.log({ dynamicData: data });
    return data;
  };

  // useEffect(async () => {
  //   const scroll = new LocomotiveScroll({
  //     el: scrollRef.current,
  //     smooth: true,
  //     getSpeed: true,
  //     getDirection: true,
  //     multiplier: 0.4, // Adjust the scroll speed here
  //   });

  //   const productData = findProductBySlug(productSlug);
  //   const dynamicProductData = await getDynamicProduct(productSlug);
  //   setDynamicProduct(dynamicProductData);

  //   if (!productData) {
  //     navigate("/404");
  //   } else {
  //     let categoryData;
  //     if (productData?.super_category_id) {
  //       categoryData = findSuperCategoryWhere({
  //         id: productData?.super_category_id,
  //       })[0];
  //     } else {
  //       categoryData = findCategoryBySlug(categorySlug);
  //     }

  //     setCategory(categoryData);
  //     setProduct(productData);

  //     if (productData.have_child) {
  //       setDetail(productData?.childs?.[childIndex]);
  //       setTimeout(() => {
  //         scroll.update(); // Update scroll calculations
  //       }, 100);
  //       scroll.update(); // Manually update the scroll height
  //     } else {
  //       setDetail(productData);
  //       setTimeout(() => {
  //         scroll.update(); // Update scroll calculations
  //       }, 100);
  //       scroll.update(); // Manually update the scroll height
  //     }

  //     setTimeout(() => {
  //       scroll.update(); // Update scroll calculations
  //     }, 100);
  //     scroll.update(); // Manually update the scroll height

  //     // return () => {
  //     //   scroll.destroy(); // Clean up Locomotive Scroll instance
  //     // };
  //   }
  // }, [categorySlug, productSlug]);

  useEffect(() => {
    const fetchData = async () => {
      const scroll = new LocomotiveScroll({
        el: scrollRef.current,
        smooth: true,
        getSpeed: true,
        getDirection: true,
        multiplier: 0.4, // Adjust the scroll speed here
      });

      const productData = findProductBySlug(productSlug);
      const dynamicProductData = await getDynamicProduct(productSlug);
      setDynamicProduct(dynamicProductData);

      if (!productData) {
        navigate("/404");
      } else {
        let categoryData;
        if (productData?.super_category_id) {
          categoryData = findSuperCategoryWhere({
            id: productData?.super_category_id,
          })[0];
        } else {
          categoryData = findCategoryBySlug(categorySlug);
        }

        setCategory(categoryData);
        setProduct(productData);

        if (productData.have_child) {
          setDetail(productData?.childs?.[childIndex]);
          setTimeout(() => {
            scroll.update(); // Update scroll calculations
          }, 100);
        } else {
          setDetail(productData);
          setTimeout(() => {
            scroll.update(); // Update scroll calculations
          }, 100);
        }

        setTimeout(() => {
          scroll.update(); // Update scroll calculations
        }, 100);
        scroll.update(); // Manually update the scroll height

        return () => {
          scroll.destroy(); // Clean up Locomotive Scroll instance
        };
      }
    };

    fetchData();
  }, [categorySlug, productSlug]);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleSelectChange = (event) => {
    setChildIndex(event.target.value);

    setDetail(product?.childs?.[event.target.value]);
    setExpanded("panel1");
    if (specsheetRef.current) {
      specsheetRef.current.scrollLeft = 0;
    }
  };

  const optionList = product?.childs?.map((item, i) => (
    <MenuItem
      value={i}
      sx={{
        width: "100%",
        // maxWidth: "100%",
        // maxWidth: "41.1875em",
        // maxWidth: {xs: "30vw", md: "30vw", lg: "35vw"},
        whiteSpace: "initial",
        // ".MuiPaper-root.MuiPopover-paper.MuiMenu-paper": {
        //   maxWidth: "15em",
        // },
      }}
    >
      {item?.title}
    </MenuItem>
  ));

  const productDetails = (
    <div className="col-md-12">
      <div className="text_container">
        <motion.div
          className="title_wrap"
          variants={textVariants("up", 0.2)}
          initial="hidden"
          whileInView="show"
          viewport={{ once: true, amount: 0.1 }}
          transition={{ duration: 0.5 }}
        >
          <h4 className="title">{detail?.title}</h4>
        </motion.div>
        <div className="download_location">
          <div
            className="location_box"
            style={{
              display: detail?.size != "" ? "block" : "none",
            }}
          >
            {detail?.size ? (
              <motion.p
                className="description"
                variants={textVariants("up", 0.2)}
                initial="hidden"
                whileInView="show"
                viewport={{ once: true, amount: 0.1 }}
                transition={{ duration: 0.5 }}
                href={() => false}
              >
                <div>
                  <br />
                  {detail?.size?.map((item) => {
                    return (
                      <>
                        <span>{item}</span>
                        <br />
                      </>
                    );
                  })}
                </div>
              </motion.p>
            ) : null}
          </div>
        </div>
        <motion.p
          className="description"
          variants={textVariants("up", 0.2)}
          initial="hidden"
          whileInView="show"
          viewport={{ once: true, amount: 0.1 }}
          transition={{ duration: 0.5 }}
        >
          {detail?.description}
        </motion.p>
        {detail?.spec_one_title && (
          <motion.div
            className="title_wrap"
            variants={textVariants("up", 0.2)}
            initial="hidden"
            whileInView="show"
            viewport={{ once: true, amount: 0.1 }}
            transition={{ duration: 0.5 }}
          >
            <Accordion
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
              className="accordion_wrap"
            >
              <AccordionSummary
                expandIcon={<KeyboardArrowDown fontSize="large" />}
                aria-controls="panel1d-content"
                id="panel1d-header"
                className="accordion_summary"
              >
                <Typography className="accordion_typo">
                  {detail?.spec_one_title}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <p
                    dangerouslySetInnerHTML={{ __html: detail?.spec_one_desc }}
                  ></p>
                </Typography>
              </AccordionDetails>
            </Accordion>
          </motion.div>
        )}
        {detail?.spec_two_title && (
          <motion.div
            className="title_wrap"
            variants={textVariants("up", 0.2)}
            initial="hidden"
            whileInView="show"
            viewport={{ once: true, amount: 0.1 }}
            transition={{ duration: 0.5 }}
          >
            <Accordion
              expanded={expanded === "panel2"}
              onChange={handleChange("panel2")}
              className="accordion_wrap"
            >
              <AccordionSummary
                expandIcon={<KeyboardArrowDown fontSize="large" />}
                aria-controls="panel2d-content"
                id="panel2d-header"
                className="accordion_summary"
              >
                <Typography>{detail?.spec_two_title}</Typography>
              </AccordionSummary>
              {detail?.spec_two_desc == "DYNAMIC" ? (
                <AccordionDetails
                  ref={specsheetRef}
                  className="dynamic_specsheet_box"
                  style={{ overflowX: "scroll", width: "100%" }}
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html: product.have_child
                        ? _.filter(dynamicProduct?.childs, {
                            title: detail?.title,
                          })[0]?.spec_two_desc
                        : dynamicProduct?.spec_two_desc,
                    }}
                    className="specsheet_box"
                  ></div>
                </AccordionDetails>
              ) : (
                <AccordionDetails>
                  <img
                    className="specsheet"
                    src={detail?.spec_two_desc}
                    alt=""
                  />
                </AccordionDetails>
              )}
              {/* <Typography>
                  <p
                    dangerouslySetInnerHTML={{ __html: detail?.spec_two_desc }}
                  ></p>
                </Typography> */}
              {/* <img className="specsheet" src={detail?.spec_two_desc} alt="" /> */}
            </Accordion>
          </motion.div>
        )}
        {detail?.spec_three_title && (
          <motion.div
            className="title_wrap"
            variants={textVariants("up", 0.2)}
            initial="hidden"
            whileInView="show"
            viewport={{ once: true, amount: 0.1 }}
            transition={{ duration: 0.5 }}
          >
            <Accordion
              expanded={expanded === "panel3"}
              onChange={handleChange("panel3")}
              className="accordion_wrap"
            >
              <AccordionSummary
                expandIcon={<KeyboardArrowDown fontSize="large" />}
                aria-controls="panel3d-content"
                id="panel3d-header"
                className="accordion_summary"
              >
                <Typography>{detail?.spec_three_title}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: detail?.spec_three_desc,
                    }}
                  ></p>
                </Typography>
              </AccordionDetails>
            </Accordion>
          </motion.div>
        )}
      </div>
    </div>
  );

  const copyUrl = () => {
    const el = document.createElement("input");
    el.value = window.location.href;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    setCopied(true);
  };

  useEffect(() => {
    if (copied !== false) {
      setTimeout(() => {
        setCopied(false);
      }, 3000);
    }
  }, [copied]);

  useEffect(() => {
    let scrollContainer = document.querySelector("[data-scroll-container]");
    const scroll = new LocomotiveScroll({
      el: scrollRef.current,
      smooth: true,
      getSpeed: true,
      getDirection: true,
      multiplier: 0.4, // Adjust the scroll speed here
    });

    scroll.update(); // Manually update the scroll height

    imagesLoaded(scrollContainer, { background: true }, function () {
      scroll.update();
    });
    const resizeObserver = new ResizeObserver((entries) => {
      scroll.update();
    });

    // start observing a DOM node
    resizeObserver.observe(document.body);

    return () => {
      resizeObserver.unobserve(document.body);

      // scroll.destroy();
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <PreLoader />
      <div
        data-scroll-container
        ref={scrollRef}
        style={{
          background: "#141414",
          backgroundImage: `url(${productInnerBanner})`,
          backgroundSize: windowWidth > 600 ? "cover" : "contain",
          overflow: "hidden",
          backgroundPosition: "center",
          backgroundAttachment: "fixed",
        }}
      >
        <section
          id="scrolltop"
          className="prodinner_sec1"
          style={{
            background: "transparent",
            overflow: "hidden",
          }}
          data-scroll-section
        >
          <div
            className="gallery_bg"
            style={{
              background: "transparent",
              // backgroundImage: `url(${images.financialbanner.image})`,
            }}
          >
            <div className="gallery_breadcrumbs ">
              <Breadcrumbs separator=">" aria-label="breadcrumb">
                <Link to="/">
                  <img
                    src={images.homeicon.image}
                    alt={images.homeicon.alt}
                    loading="lazy"
                    width={20}
                  />{" "}
                </Link>
                <Link
                  to={
                    product.super_category_id
                      ? `/categories/${category?.slug}`
                      : `/products/${category?.slug}`
                  }
                >
                  {category?.title}
                </Link>
                <Link to={`/products/${category?.slug}/${product?.slug}`}>
                  {product?.title}
                </Link>
              </Breadcrumbs>
            </div>
            <div className="product_banner_wrapper">
              <div className="my_container">
                <div className="row productinner_row">
                  <div className="col-md-7">
                    <motion.div
                      className="title_wrap"
                      variants={textVariants("left", 0.2)}
                      initial="hidden"
                      whileInView="show"
                      viewport={{ once: true, amount: 0.1 }}
                      transition={{ duration: 0.5 }}
                    >
                      <div className="product_heading">
                        <h1>{detail?.title}</h1>
                      </div>
                      {windowWidth < 768 ? (
                        <img
                          src={detail?.image}
                          alt="product"
                          className="product_img"
                        />
                      ) : (
                        ""
                      )}
                    </motion.div>
                  </div>
                  <div className="col-md-5 productinner-standard">
                    <motion.div
                      className="title_wrap"
                      variants={textVariants("right", 0.2)}
                      initial="hidden"
                      whileInView="show"
                      viewport={{ once: true, amount: 0.1 }}
                      transition={{ duration: 0.5 }}
                    >
                      <div className="details_box">
                        <div
                          className="product_heading"
                          style={{
                            display: product?.have_child ? "block" : "none",
                          }}
                        >
                          <h1>{product?.title}</h1>
                        </div>
                        <FormControl
                          fullWidth
                          style={{
                            display: product?.have_child ? "block" : "none",
                          }}
                          className="form_control_mui"
                        >
                          <Select
                            MenuProps={{ disableScrollLock: true }}
                            IconComponent={ExpandMore}
                            value={childIndex}
                            onChange={handleSelectChange}
                            inputProps={{ "aria-label": "Without label" }}
                            sx={{
                              width: "100%",
                              maxWidth: "100%",
                              // maxWidth: "41.1875em",
                              // maxWidth: "35vw",
                              color: "#FFFFFF",
                              fontSize: "17px",
                              paddingLeft: "0",
                              textTransform: "uppercase",
                              fontFamily: "Poppins",
                              fontWeight: "300",
                              margin: "0 0 2.583vw",
                              ".MuiOutlinedInput-input": {
                                padding: ".75em 2.031em",
                              },
                              ".MuiOutlinedInput-notchedOutline": {
                                borderRadius: "0",
                                border: "1px solid #FFC295",
                              },
                              "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                  borderRadius: "0",
                                  border: "1px solid #FFC295",
                                },
                              "&:hover .MuiOutlinedInput-notchedOutline": {
                                borderRadius: "0",
                                border: "1px solid #FFC295",
                              },
                              ".MuiSvgIcon-root ": {
                                fill: "#FFC295 !important",
                                float: "right !important",
                              },
                              // ".MuiPaper-root.MuiPopover-paper.MuiMenu-paper": {
                              //   maxWidth: "25em",
                              // },
                            }}
                          >
                            {optionList}
                          </Select>
                        </FormControl>
                        {standardLenght !== 0 ? (
                          windowWidth < 768 ? (
                            <div className="table_wrapper">
                              <table>
                                <tr>
                                  <td className="text-white heading">
                                    Standard & Grade
                                  </td>
                                </tr>
                                {detail?.standards?.map((item) => {
                                  return (
                                    <tr>
                                      <td className="nobottom_border first_box">
                                        {item}
                                      </td>
                                    </tr>
                                  );
                                })}

                                {/* <tr>
                            <td className="nobottom_border first_box">
                              ASTM B 68
                            </td>
                          </tr>
                          <tr>
                            <td className="notopbottom_border">ASTM B 75</td>
                          </tr>
                          <tr>
                            <td className="notopbottom_border">
                              BS 2871 PART 2
                            </td>
                          </tr>
                          <tr>
                            <td className="notopbottom_border">
                              BS 2871 PART 3
                            </td>
                          </tr>
                          <tr>
                            <td className="notopbottom_border">EN 12451</td>
                          </tr>
                          <tr>
                            <td className="notop_border last_box">JIS H3300</td>
                          </tr> */}
                              </table>
                            </div>
                          ) : (
                            ""
                          )
                        ) : null}
                      </div>
                    </motion.div>
                  </div>
                </div>
                <div className="row productinner_row productinner_center_row">
                  <div className="col-md-7">
                    <motion.div
                      className="title_wrap"
                      variants={textVariants("left", 0.2)}
                      initial="hidden"
                      whileInView="show"
                      viewport={{ once: true, amount: 0.1 }}
                      transition={{ duration: 0.5 }}
                    >
                      {windowWidth > 767 ? (
                        <img
                          src={detail?.image}
                          alt="product"
                          className="product_img"
                        />
                      ) : (
                        ""
                      )}
                    </motion.div>
                  </div>
                  <div className="col-md-5 productinner-standard">
                    <motion.div
                      className="title_wrap"
                      variants={textVariants("right", 0.2)}
                      initial="hidden"
                      whileInView="show"
                      viewport={{ once: true, amount: 0.1 }}
                      transition={{ duration: 0.5 }}
                    >
                      {standardLenght !== 0 ? (
                        <div className="details_box">
                          {windowWidth > 767 ? (
                            <div className="table_wrapper">
                              <table>
                                <tr>
                                  {detail?.grade ? (
                                    <td className="text-white heading">
                                      Standard
                                    </td>
                                  ) : (
                                    <td className="text-white heading">
                                      Standard & Grade
                                    </td>
                                  )}
                                </tr>
                                {detail?.standards?.map((item) => {
                                  return (
                                    <tr>
                                      <td className="nobottom_border first_box">
                                        {item}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </table>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      ) : null}
                    </motion.div>
                  </div>
                </div>
              </div>
            </div>
            <div className="scroll-down-d" style={{ zIndex: "5" }}>
              {/* <a
                style={{ color: "#fff", cursor: "pointer" }}
                href="#productinner"
                data-scroll-to
                className="text-center"
              >
                Scroll for more
              </a> */}
              <div className="spinner scroll-down">
                <a
                  style={{ cursor: "pointer" }}
                  href="#productinner"
                  data-scroll-to
                  className="animate"
                ></a>
              </div>
            </div>
          </div>
        </section>
        <section
          id="productinner"
          className="prodinner_sec2"
          style={{ overflow: "hidden" }}
          data-scroll-section
        >
          <div className="my_container">
            <div className="row">
              {productDetails}
              {/* 
              <div className="col-md-12">
                <div className="download_location">
                   {windowWidth > 600 ? (
                    <motion.div
                      className="share_wrap"
                      variants={textVariants("up", 0.2)}
                      initial="hidden"
                      whileInView="show"
                      viewport={{ once: true, amount: 0.1 }}
                      transition={{ duration: 0.5 }}
                    >
                      <img
                        src={shareicon}
                        alt="share icon"
                        loading="lazy"
                        className="share_icon"
                        onClick={copyUrl}
                      />
                      <p className="share_indication" onClick={copyUrl}>
                        {!copied ? "Share" : "Copied!"}
                      </p>
                      <div style={{ marginLeft: "2em" }}>
                        <WhatsappShareButton
                          url={`${
                            window.location.href ? window.location.href : null
                          }`}
                        >
                          <img
                            src={share1}
                            alt="share icon"
                            loading="lazy"
                            className="share_icon"
                          />
                        </WhatsappShareButton>
                        <EmailShareButton
                          url={`${
                            window.location.href ? window.location.href : null
                          }`}
                        >
                          <img
                            src={share2}
                            alt="share icon"
                            loading="lazy"
                            className="share_icon"
                          />
                        </EmailShareButton>
                        <img
                          src={share3}
                          alt="share icon"
                          loading="lazy"
                          className="share_icon"
                          onClick={copyUrl}
                        />
                      </div>
                    </motion.div>
                  ) : null} 
                  <div className="download_box">
                    <div className="download_share_wrap">
                      <motion.div
                        className="title_wrap"
                        variants={textVariants("up", 0.2)}
                        initial="hidden"
                        whileInView="show"
                        viewport={{ once: true, amount: 0.1 }}
                        transition={{ duration: 0.5 }}
                      >
                        <h4 className="title">Downloads</h4>
                      </motion.div>
                      {windowWidth < 600 ? (
                        <motion.div
                          className="share_wrap"
                          variants={textVariants("up", 0.2)}
                          initial="hidden"
                          whileInView="show"
                          viewport={{ once: true, amount: 0.1 }}
                          transition={{ duration: 0.5 }}
                        >
                          <img
                            src={shareicon}
                            alt="share icon"
                            loading="lazy"
                            className="share_icon"
                            onClick={copyUrl}
                          />
                          <p className="share_indication" onClick={copyUrl}>
                            {!copied ? "Share" : "Copied!"}
                          </p>
                          <div style={{ marginLeft: "0.5em" }}>
                            <WhatsappShareButton
                              url={`${
                                window.location.href
                                  ? window.location.href
                                  : null
                              }`}
                            >
                              <img
                                src={share1}
                                alt="share icon"
                                loading="lazy"
                                className="share_icon"
                              />
                            </WhatsappShareButton>  
                            <EmailShareButton
                              url={`${
                                window.location.href
                                  ? window.location.href
                                  : null
                              }`}
                            >
                              <img
                                src={share2}
                                alt="share icon"
                                loading="lazy"
                                className="share_icon"
                              />
                            </EmailShareButton>
                            <img
                              src={share3}
                              alt="share icon"
                              loading="lazy"
                              className="share_icon"
                              onClick={copyUrl}
                            />
                          </div>
                        </motion.div>
                      ) : null}
                    </div>
                    <motion.a
                      className="description"
                      variants={textVariants("up", 0.2)}
                      initial="hidden"
                      whileInView="show"
                      viewport={{ once: true, amount: 0.1 }}
                      transition={{ duration: 0.5 }}
                      href={broucher}
                      target="main"
                    >
                      <div>Product Brochure</div>
                      <img
                        src={proddownloadicon}
                        alt="download icon"
                        loading="lazy"
                        className="download_icon"
                      />
                    </motion.a>
                    <motion.a
                      className="description"
                      variants={textVariants("up", 0.2)}
                      initial="hidden"
                      whileInView="show"
                      viewport={{ once: true, amount: 0.1 }}
                      transition={{ duration: 0.5 }}
                      href={demopdf}
                      
                    >
                      <div>Industrial applications in English</div>
                      <img
                        src={proddownloadicon}
                        alt="download icon"
                        loading="lazy"
                        className="download_icon"
                      />
                    </motion.a>
                  </div> 
                  <div
                    className="location_box"
                    style={{
                      display: detail?.size != "" ? "block" : "none",
                    }}
                  >
                    {/* <motion.div
                      className="title_wrap"
                      variants={textVariants("up", 0.2)}
                      initial="hidden"
                      whileInView="show"
                      viewport={{ once: true, amount: 0.1 }}
                      transition={{ duration: 0.5 }}
                    >
                      <h4 className="title">We provide</h4>
                    </motion.div> 
                    {detail?.size ? (
                      <motion.p
                        className="description"
                        variants={textVariants("up", 0.2)}
                        initial="hidden"
                        whileInView="show"
                        viewport={{ once: true, amount: 0.1 }}
                        transition={{ duration: 0.5 }}
                        href={() => false}
                      >
                        <div>
                           Wide Size Range - 30 mm to 155 mm 
                          <br />
                          {detail?.size?.map((item) => {
                            return (
                              <>
                                <span>{item}</span>
                                <br />
                              </>
                            );
                          })}
                        </div>
                      </motion.p>
                    ) : null}
                  </div>
                  <div className="location_box">
                    <motion.div
                      className="title_wrap"
                      variants={textVariants("up", 0.2)}
                      initial="hidden"
                      whileInView="show"
                      viewport={{ once: true, amount: 0.1 }}
                      transition={{ duration: 0.5 }}
                    >
                      <h4 className="title">Manufacturing Locations</h4>
                    </motion.div>
                    <motion.a
                      className="description"
                      variants={textVariants("up", 0.2)}
                      initial="hidden"
                      whileInView="show"
                      viewport={{ once: true, amount: 0.1 }}
                      transition={{ duration: 0.5 }}
                      href="https://www.google.com/maps?q=Multimetals+Limited,+Heavy,+Road,+Kansuwa,+Kota+Industrial+Area,+Kota,+Rajasthan+324003&ftid=0x396f9ad582e53cab:0x7347255cf881ae3d&hl=en-IN&gl=in&coh=179581&entry=gps&lucs=,47068306&g_ep=CAISBjYuNzMuMhgAINeCAyoJLDQ3MDY4MzA2QgJJTg%3D%3D&g_st=iw"
                      target="main"
                    >
                      <div>Kota, Rajasthan</div>
                      <img
                        src={locationicon}
                        alt="download icon"
                        loading="lazy"
                        className="download_icon"
                      />
                    </motion.a>
                    <motion.a
                      className="description"
                      variants={textVariants("up", 0.2)}
                      initial="hidden"
                      whileInView="show"
                      viewport={{ once: true, amount: 0.1 }}
                      transition={{ duration: 0.5 }}
                      href={() => false}
                    >
                      <div>Welwyn Garden City, United Kingdom</div>
                      <img
                        src={locationicon}
                        alt="download icon"
                        loading="lazy"
                        className="download_icon"
                      />
                    </motion.a>
                  </div>
                </div>
              </div> 
              */}
            </div>
          </div>
        </section>
        <section
          className="footer_area"
          data-scroll-section
          style={{
            overflowY: "hidden",
          }}
        >
          <Footer />
        </section>
      </div>
    </>
  );
};

export default ProductInner;
