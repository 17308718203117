import { React, useEffect, useRef, useState } from "react";
import "./manufacturingprocess.scss";
import Commonbanner from "../../Common Banner/CommonBanner";
import {
  icon1,
  icon2,
  icon3,
  images,
  leftArrow,
  manufacutringIcon1,
  manufacutringIcon2,
  manufacutringIcon3,
  maunfacutringProcess,
  rightArrow,
  testingBanner,
} from "../../assets";
import { useWindowSize } from "react-use";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  EffectCoverflow,
  FreeMode,
  Navigation,
  Pagination,
  Thumbs,
} from "swiper";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import { textVariants } from "../../Header/Motion";
import { motion } from "framer-motion";
import video from "../../assets/image/manufacturing/Desktop_1.mp4";
import mbvideo from "../../assets/image/manufacturing/Mobile_1.mp4";
import LocomotiveScroll from "locomotive-scroll";
import Footer from "../../Footer/Footer";
import PreLoader from "../../loader/preloader";
const imagesLoaded = require("imagesloaded");

const mainImage = [
  { id: 1, img: images.manufacturing1.image, alt: images.manufacturing1.alt },
  { id: 2, img: images.manufacturing2.image, alt: images.manufacturing2.alt },
  { id: 3, img: images.manufacturing3.image, alt: images.manufacturing4.alt },
  { id: 4, img: images.manufacturing4.image, alt: images.manufacturing4.alt },
  { id: 5, img: images.manufacturing5.image, alt: images.manufacturing5.alt },
  { id: 6, img: images.manufacturing6.image, alt: images.manufacturing6.alt },
];

const thumnailImage = [
  {
    id: 1,
    img: images.manufacturing1_thumbnil.image,
    alt: images.thumnail1.alt,
    mbImg: images.manufacturing1_thumbnil.image,
  },
  {
    id: 2,
    img: images.manufacturing2_thumbnil.image,
    alt: images.thumnail2.alt,
    mbImg: images.manufacturing2_thumbnil.image,
  },
  {
    id: 3,
    img: images.manufacturing3_thumbnil.image,
    alt: images.thumnail3.alt,
    mbImg: images.manufacturing3_thumbnil.image,
  },
  {
    id: 4,
    img: images.manufacturing4_thumbnil.image,
    alt: images.thumnail4.alt,
    mbImg: images.manufacturing4_thumbnil.image,
  },
  {
    id: 5,
    img: images.manufacturing5_thumbnil.image,
    alt: images.thumnail5.alt,
    mbImg: images.manufacturing5_thumbnil.image,
  },
  {
    id: 6,
    img: images.manufacturing6_thumbnil.image,
    alt: images.thumnail6.alt,
    mbImg: images.manufacturing6_thumbnil.image,
  },
];

const Maunfacutring = () => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const { width } = useWindowSize();
  const scrollRef = useRef(null);

  // const tableData = [
  //   {
  //     id: 1,
  //     make: "Ajax Wyatt USA",
  //     machinery: "Electric Induction Melting Furnaces Channel type - 4 Nos",
  //   },
  //   {
  //     id: 2,
  //     make: "Pioneer",
  //     machinery: "Electric Coreless Induction Melting Furnace - 2 Nos",
  //   },
  //   {
  //     id: 3,
  //     make: "SMS Schloemann Germany",
  //     machinery:
  //       "3500/500 Tonnes fully Automatic Horizontal Oil Hydraulic Direct Extrusion Press",
  //   },
  //   {
  //     id: 4,
  //     make: "Otto Junker Germany",
  //     machinery: "GAS Fired Billet Heater",
  //   },
  //   {
  //     id: 5,
  //     make: "Farrel USA",
  //     machinery:
  //       "2500/300 Tonnes Horizontal Oil Hydraulic Direct Extrusion Press",
  //   },
  //   {
  //     id: 6,
  //     make: "Ajax Magnatherm USA",
  //     machinery: "Electric Induction Billet Heaters",
  //   },
  //   {
  //     id: 7,
  //     make: "Mannesman - Meer Germany",
  //     machinery: '3.5" VM Cold Reducing Pilger Mill -2 Nos',
  //   },
  //   {
  //     id: 8,
  //     make: "MRB Schumag UK",
  //     machinery: '84" Dia Spinner Block',
  //   },
  //   {
  //     id: 9,
  //     make: "Atena Standard USA",
  //     machinery:
  //       "Draw Benches of various pulling capacities i.e. 10,00,000 lbs, 160,000 lbs, 40,000 lbs, 25,000 lbs, 20,000 lbs, 10,000 lbs & 5,000 lbs.",
  //   },
  //   {
  //     id: 10,
  //     make: "W.S. Rockwell USA",
  //     machinery: "Bright Annealing Furnace Chain conveyor type",
  //   },
  //   {
  //     id: 11,
  //     make: "Seco Warwick USA",
  //     machinery: "Bright Annealing Furnace, Roller Hearth Type",
  //   },
  //   {
  //     id: 12,
  //     make: "Chugai R.O. Japan",
  //     machinery: "Bright Annealing Furnace Chain conveyor type",
  //   },
  //   {
  //     id: 13,
  //     make: "Albert Mann UK",
  //     machinery: "Lomatic Tube Processing Line",
  //   },
  //   {
  //     id: 14,
  //     make: "Mackintosh Hemphill USA",
  //     machinery: "Tube Straightener",
  //   },
  //   {
  //     id: 15,
  //     make: "Gauder Germany",
  //     machinery: "Level Wound Coiler",
  //   },
  //   {
  //     id: 16,
  //     make: "Noble Et Lund USA",
  //     machinery: "Hydraulic Circular Sawing Machine",
  //   },
  //   {
  //     id: 17,
  //     make: "Soco Taiwan",
  //     machinery: "For Tube & Ring Cutting",
  //   },
  //   {
  //     id: 18,
  //     make: "CNC Takang Taiwan",
  //     machinery: "For Machined Component",
  //   },
  // ];

  const tableData = [
    {
      id: 1,
      make: "Ajax Wyatt USA",
      machinery: "Electric Induction Melting Furnaces Channel type 1 MT capacity – 4 Nos",
    },
    {
      id: 2,
      make: "Pioneer",
      machinery: "Electric Coreless Induction Melting Furnace – 3.6 MT capacity.- 2 Nos.",
    },
    {
      id: 3,
      make: "Hitachi Cable Ltd. Japan",
      machinery:
        "Designed Double Strand Semi Continuous Casting Equipment for 200 mm,250 mm and 300 mm dia Billets.-2 Nos.",
    },
    {
      id: 4,
      make: "ASMAG, UK ",
      machinery: "Hydraulic Circular Sawing Machine - 04 Nos, Maximum Cutting Size-350 mm",
    },
    {
      id: 5,
      make: "Farrel USA",
      machinery:
        "2500 / 300 Tonnes Horizontal Oil Hydraulic Direct Extrusion Press",
    },
    {
      id: 6,
      make: "SMS Meer Germany",
      machinery: "3500 / 500 Tonnes Horizontal Oil Hydraulic Direct Extrusion Press with under water extrusion Facility",
    },
    {
      id: 7,
      make: "Ajax Magathermic USA",
      machinery: "Electric Induction Billet Heaters – 630 KW- 4 NOS",
    },
    {
      id: 8,
      make: "Mannesman – Meer Germany",
      machinery: '3.5” VM Cold Reducing Pilger Mill – 2Nos. 6” VM Cold Reducing Pilger Mill – 1 Nos. 2” VM Cold Reducing Pilger Mill – 2 Nos.',
    },
    {
      id: 9,
      make: "MRB SchumagUK",
      machinery:
        '84” Dia Spinner Block with 10 ton pulling load',
    },
    {
      id: 10,
      make: "Revere Copper & Brass Inc. USA",
      machinery: "Draw Benches of various: Pulling Capacities –12 Nos. Ranging from 2.5MT - 80MT",
    },
    {
      id: 11,
      make: "W.S. Rockwell USA",
      machinery: "Bright Annealing Furnace Chain conveyor type –Rockwell USA",
    },
    {
      id: 12,
      make: "Wellman Incandesent",
      machinery: "Roller Hearth Annealing Furnace - Wellman make",
    },
    {
      id: 13,
      make: "Secowarwick make",
      machinery: "Bright Annealing Roller Hearth Annealing Furnace",
    },
    {
      id: 14,
      make: "Chugai R.O. Japan",
      machinery: "Roller Hearth Annealing Furnace Chain conveyor type",
    },
    {
      id: 15,
      make: "MVS",
      machinery: "Exogas Generator 300NM3 per HR",
    },
    {
      id: 16,
      make: "CarboTech/Gastek",
      machinery: "Nitrogen Gas Generator 300 NM3 per HR",
    },
    {
      id: 17,
      make: "Albert LOMA MANN UK",
      machinery: "Lomatic Tube Processing Line",
    },
    {
      id: 18,
      make: "Mackintosh Hemphill USA",
      machinery: "Tube Straightener.<br /> Size : 6 mm to 12 mm – 1 No Size : 12.0 mm to 30.0 mm – 2 No	Size : 35 mm to 70.00 mm – 1 No	Size : 50.00 mm to 100 mm – 1 No , Size : 70 mm to 220 mm – 1 No",
    },
    {
      id: 19,
      make: "Kirloskar",
      machinery: "Diesel Generating Sets 1156 KVA - 02 Nos",
    },
    {
      id: 20,
      make: "ITW Signode",
      machinery:
        "Stretch Wrapping Machine for Packing",
    },
    {
      id: 21,
      make: "Jessop / NSE / Garlick / Anupam / Saico",
      machinery: "5 Tons EOT Crane - 6 Nos. 20 Tons EOT Crane - 1 Nos",
    },
    {
      id: 22,
      make: "Endeavour & Divya Scales",
      machinery: "100 Ton Weigh Bridge. – 01 Nos 5 Ton Weigh Scale. – 01 Nos 1 Ton Weigh Scale. – 04 Nos",
    },
    {
      id: 23,
      make: "Godrej / Voltas",
      machinery: "Forklift Trucks – 5 Ton capacity Voltas – 1 Nos. Forklift Trucks - 3 Ton capacity Godrej – 1 Nos. and Voltas -04 Nos",
    },
    {
      id: 24,
      make: "Gauder Germany",
      machinery: "Level Wound Coiler – 2 Nos",
    },
    {
      id: 25,
      make: "Riat Grinder",
      machinery: "Rod Straightners- 10mm to 40 mm – 1Nos 40 mm – 80 mm – 1 Nos",
    },
    {
      id: 26,
      make: "SOCO Taiwan",
      machinery: "Fully Automated Tube Cutting Machine – 2 Nos",
    },
    {
      id: 27,
      make: "CNC Machines",
      machinery: "CNC Takang -04 , CNC ACE MICROMATIC -04 Nos, LMW - 06",
    },
    {
      id: 28,
      make: "INTEGERAL TUBE FINNING MACHINE, TAIWAN MAKE",
      machinery: "Capacity 12.70 mm OD to 25.4 mm OD -02 Nos",
    },
  ];

  const innerLinkData = [
    {
      id: 1,
      title: "Furnace",
      link: "/furnace",
    },
    {
      id: 2,
      title: "Billet Saw",
      link: "/billet-saw",
    },
    {
      id: 3,
      title: "Electric Induction Billet Heater",
      link: "/electric-induction-billet-heater",
    },
    {
      id: 4,
      title: "Extrusion",
      link: "/extrusion",
    },
    {
      id: 5,
      title: "Pilger",
      link: "/pilger",
    },
    {
      id: 6,
      title: "ACR Spinner",
      link: "/acr-spinner",
    },
    {
      id: 7,
      title: "Draw Bench",
      link: "/draw-bench",
    },
    {
      id: 8,
      title: "Annealing Furnace",
      link: "/annealing-furnace",
    },
    {
      id: 9,
      title: "Straightener",
      link: "/straightener",
    },
  ];

  const mbInnerLinkData = [
    {
      id: 1,
      title: "Furnace",
      link: "/furnace",
    },
    {
      id: 2,
      title: "Billet Saw",
      link: "/billet-saw",
    },
    {
      id: 3,
      title: "Extrusion",
      link: "/extrusion",
    },
    {
      id: 4,
      title: "Pilger",
      link: "/pilger",
    },
    {
      id: 5,
      title: "ACR Spinner",
      link: "/acr-spinner",
    },
    {
      id: 6,
      title: "Draw Bench",
      link: "/draw-bench",
    },
    {
      id: 7,
      title: "Annealing Furnace",
      link: "/annealing-furnace",
    },
    {
      id: 8,
      title: "Straightener",
      link: "/straightener",
    },
    {
      id: 9,
      title: "Electric Induction Billet Heater",
      link: "/electric-induction-billet-heater",
    },
  ];

  const tableList = tableData.map((item, i) => (
    <tr className="content" key={i}>
      <td
        className="table_desc"
        dangerouslySetInnerHTML={{ __html: item.make }}
      ></td>
      <td
        className="table_desc"
        dangerouslySetInnerHTML={{ __html: item.machinery }}
      ></td>
    </tr>
  ));

  const innerLinkList = innerLinkData.map((item, i) => (
    <div className="col-md-3 col-6 inner_btn_col" key={innerLinkData[i].id}>
      <div className="inner_cta">
        <a href={item.link} className="inner_btn">
          {item.title}
        </a>
      </div>
    </div>
  ));

  const mbInnerLinkList = mbInnerLinkData.map((item, i) => (
    <div className="col-md-3 col-6 inner_btn_col" key={innerLinkData[i].id}>
      <div className="inner_cta">
        <a href={item.link} className="inner_btn">
          {item.title}
        </a>
      </div>
    </div>
  ));

  const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      let pageNumber = index + 1;
      if (pageNumber < 10) {
        pageNumber = "0" + pageNumber;
      }
      return '<span className="' + className + '">' + pageNumber + "</span>";
      // return '<span className="' + className + '">' + (index + 1) + "</span>";
    },
  };

  const maufacturingData = [
    {
      id: 1,
      img: images.manufacturing1.image,
      alt: images.manufacturing1.alt,
    },
    {
      id: 2,
      img: images.manufacturing2.image,
      alt: images.manufacturing2.alt,
    },
    {
      id: 3,
      img: images.manufacturing3.image,
      alt: images.manufacturing3.alt,
    },
    {
      id: 4,
      img: images.manufacturing4.image,
      alt: images.manufacturing4.alt,
    },
    {
      id: 5,
      img: images.manufacturing5.image,
      alt: images.manufacturing5.alt,
    },
    {
      id: 6,
      img: images.manufacturing6.image,
      alt: images.manufacturing6.alt,
    },
  ];

  // const maufacturingList = maufacturingData.map((item, i) => (
  //   <SwiperSlide key={maufacturingData[i].id}>
  //     <div className="testing_content">
  //       <img
  //         src={item.img}
  //         alt={item.alt}
  //       />
  //       <p className="testing_details">{item.details}</p>
  //     </div>
  //   </SwiperSlide>
  // ));

  const maufacturingList = mainImage.map((item, i) => (
    <SwiperSlide key={i}>
      <img src={item.img} alt={item.alt} className="main_img" />
    </SwiperSlide>
  ));

  const thumbnailList = thumnailImage.map((item, i) => (
    <SwiperSlide key={i}>
      <img
        src={width > 767 ? item.img : item.mbImg}
        alt={item.alt}
        className="thumnail_img"
      />
    </SwiperSlide>
  ));

  useEffect(() => {
    let scrollContainer = document.querySelector("[data-scroll-container]");
    const scroll = new LocomotiveScroll({
      el: scrollRef.current,
      smooth: true,
      getSpeed: true,
      getDirection: true,
      multiplier: 0.4, // Adjust the scroll speed here
    });
    
    scroll.update(); // Manually update the scroll height

    imagesLoaded(scrollContainer, { background: true }, function () {
      scroll.update();
    });
    const resizeObserver = new ResizeObserver((entries) => {
      scroll.update();
    });

    // start observing a DOM node
    resizeObserver.observe(document.body);

    return () => {
      resizeObserver.unobserve(document.body);
  
        scroll.destroy();
      }
      
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <PreLoader />
      <div
        data-scroll-container
        ref={scrollRef}
        style={{
          background: "#141414",
          backgroundImage: `url(${images.manufacturing_bg.image})`,
          backgroundSize: width > 600 ? "cover" : "contain",
          overflow: "hidden",
          backgroundPosition: "center",
          backgroundAttachment: "fixed",
        }}
      >
        <section
          id="scrolltop"
          className="manufacturing_sec1"
          style={{ overflow: "hidden" }}
          data-scroll-section
        >
          <Commonbanner
            // image={testingBanner}
            linkText="ABOUT US"
            link="/about"
            linkText1="MANUFACTURING PROCESS"
            link1="/manufacturing-process"
            headingtext="MANUFACTURING PROCESS"
            scrollId="#manufacturing"
          />{" "}
          <div className="icon_container">
            <motion.div
              variants={textVariants("up", 0.2)}
              initial="hidden"
              whileInView="show"
              viewport={{ once: true, amount: 0.1 }}
              transition={{ duration: 0.5 }}
              whileHover={{ scale: 1.1 }}
            >
              <img src={manufacutringIcon1} alt="icon" className="icon_img" />
            </motion.div>
            <motion.div
              variants={textVariants("up", 0.2)}
              initial="hidden"
              whileInView="show"
              viewport={{ once: true, amount: 0.1 }}
              transition={{ duration: 0.5 }}
              whileHover={{ scale: 1.1 }}
            >
              <img
                src={manufacutringIcon2}
                alt="icon"
                className="icon_img icon_img1"
              />
            </motion.div>
            <motion.div
              variants={textVariants("up", 0.2)}
              initial="hidden"
              whileInView="show"
              viewport={{ once: true, amount: 0.1 }}
              transition={{ duration: 0.5 }}
              whileHover={{ scale: 1.1 }}
            >
              <img src={manufacutringIcon3} alt="icon" className="icon_img" />
            </motion.div>
          </div>
        </section>

        <section
          id="manufacturing"
          className="manufacturing_sec2"
          data-scroll-section
        >
          <div className="content_container">
            <motion.div
              variants={textVariants("up", 0.2)}
              initial="hidden"
              whileInView="show"
              viewport={{ once: true, amount: 0.1 }}
              transition={{ duration: 0.5 }}
            >
              <h2 className="heading">MANUFACTURING PROCESS</h2>
            </motion.div>
            <motion.div
              variants={textVariants("up", 0.2)}
              initial="hidden"
              whileInView="show"
              viewport={{ once: true, amount: 0.1 }}
              transition={{ duration: 0.5 }}
            >
              <p className="text">
                Multimetals, with a commitment to excellence and continuous
                improvement, delivers exceptional products that meet the diverse
                needs of its customers across various industries. In order to
                achieve this, the manufacturing process encompasses the use of
                advanced equipment and precise techniques to transform raw
                materials into high-quality products. Each step in the process
                plays a critical role in ensuring the integrity, strength, and
                precision of the final products. Moreover, Multimetals proudly
                stands out by utilizing cutting-edge machinery sourced
                exclusively from countries renowned for their expertise and
                superior quality in the manufacturing industry. This commitment
                to importing the best machines further enhances the company's
                ability to deliver outstanding products to its valued customers.
              </p>
            </motion.div>
          </div>

          <div className="manufacturing_process">
            {/* <img
            src={maunfacutringProcess}
            alt="maunfacutring process"
            className="maunfacutring_process_img"
          /> */}
            <video
              className="process_video"
              width="100%"
              autoPlay
              muted
              loop
              webkit-playsinline="true"
              playsinline="true"
            >
              <source src={width > 767 ? video : mbvideo} type="video/mp4" />
            </video>
          </div>

          <div className="innerpage_btn">
            <h2 className="heading">THE PATH TO PERFECTION</h2>
            <div className="row inner_btn_row">
              {width > 767 ? (
                innerLinkList
              ) : (
                <>
                  <div className="col-6 inner_btn_col">
                    <div className="inner_cta">
                      <a href="/furnace" className="inner_btn">
                        Furnace
                      </a>
                    </div>
                  </div>
                  <div className="col-6 inner_btn_col">
                    <div className="inner_cta">
                      <a href="/billet-saw" className="inner_btn">
                        Billet Saw
                      </a>
                    </div>
                  </div>
                  <div className="col-6 inner_btn_col">
                    <div className="inner_cta">
                      <a href="/extrusion" className="inner_btn">
                        Extrusion
                      </a>
                    </div>
                  </div>
                  <div className="col-6 inner_btn_col">
                    <div className="inner_cta">
                      <a href="/pilger" className="inner_btn">
                        Pilger
                      </a>
                    </div>
                  </div>
                  <div className="col-6 inner_btn_col">
                    <div className="inner_cta">
                      <a href="/acr-spinner" className="inner_btn">
                        ACR Spinner
                      </a>
                    </div>
                  </div>
                  <div className="col-6 inner_btn_col">
                    <div className="inner_cta">
                      <a href="/draw-bench" className="inner_btn">
                        Draw Bench
                      </a>
                    </div>
                  </div>
                  <div className="col-6 inner_btn_col">
                    <div className="inner_cta">
                      <a href="/annealing-furnace" className="inner_btn">
                        Annealing Furnace
                      </a>
                    </div>
                  </div>
                  <div className="col-6 inner_btn_col">
                    <div className="inner_cta">
                      <a href="/straightener" className="inner_btn">
                        Straightener
                      </a>
                    </div>
                  </div>
                  <div className="col-12 inner_btn_col">
                    <div className="inner_cta">
                      <a
                        href="/electric-induction-billet-heater"
                        className="inner_btn"
                      >
                        Electric Induction Billet Heater
                      </a>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </section>

        <section className="manufacturing_sec3" data-scroll-section>
          <div className="content_container" data-scroll>
            <motion.div
              variants={textVariants("up", 0.2)}
              initial="hidden"
              whileInView="show"
              viewport={{ once: true, amount: 0.1 }}
              transition={{ duration: 0.5 }}
            >
              <h2 className="heading">SEAMLESS TRANSFORMATION</h2>
            </motion.div>
            <motion.div
              variants={textVariants("up", 0.2)}
              initial="hidden"
              whileInView="show"
              viewport={{ once: true, amount: 0.1 }}
              transition={{ duration: 0.5 }}
            >
              <p className="text">
                Our state-of-the-art manufacturing unit is situated in the
                picturesque state of Rajasthan in the western part of India. It
                is spread across 42 acres of land is reachable by road, rail and
                air. <br /> <br />
                We house some of the finest and the most efficient machinery
                imported from worldover, in order to produce in industrial city
                of Kota and superior quality products.
              </p>
            </motion.div>
          </div>

          <div className="testing_slider" data-scroll>
            <div className="main_slider_wrapper">
              <Swiper
                // autoHeight={true}
                loop={true}
                spaceBetween={10}
                // breakpointsBase='window'
                navigation={{
                  prevEl: ".left_arrow",
                  nextEl: ".right_arrow",
                }}
                thumbs={{ swiper: thumbsSwiper }}
                modules={[FreeMode, Navigation, Thumbs]}
                className="swiper_image"
              >
                {maufacturingList}
              </Swiper>
              <div className="thumnail_container">
                {width > 992 ? (
                  <div className="arrow_wrapper">
                    <img
                      src={leftArrow}
                      alt="left arrow"
                      className="left_arrow"
                    />
                    <img
                      src={rightArrow}
                      alt="right arrow"
                      className="right_arrow"
                    />
                  </div>
                ) : (
                  ""
                )}
                <Swiper
                  onSwiper={setThumbsSwiper}
                  loop={true}
                  spaceBetween={39}
                  slidesPerView={6}
                  freeMode={true}
                  watchSlidesProgress={true}
                  breakpointsBase="window"
                  // loopedSlides={thumbnailList.length}
                  // allowTouchMove={false}
                  modules={[FreeMode, Navigation, Thumbs]}
                  className="swiper_thumnail"
                  breakpoints={{
                    0: {
                      slidesPerView: 3.5,
                      spaceBetween: 16.6,
                    },
                    768: {
                      slidesPerView: 4,
                      spaceBetween: 30,
                    },
                    1024: {
                      slidesPerView: 5,
                      spaceBetween: 25,
                    },
                    1280: {
                      slidesPerView: 6,
                      // spaceBetween: 39,
                      spaceBetween: 38.79,
                    },
                  }}
                >
                  {thumbnailList}
                </Swiper>
                {width < 992 ? (
                  <div className="arrow_wrapper">
                    <img
                      src={leftArrow}
                      alt="left arrow"
                      className="left_arrow"
                    />
                    <img
                      src={rightArrow}
                      alt="right arrow"
                      className="right_arrow"
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </section>

        <section className="manufacturing_sec4" data-scroll-section>
          <motion.div
            variants={textVariants("up", 0.2)}
            initial="hidden"
            whileInView="show"
            viewport={{ once: true, amount: 0.1 }}
            transition={{ duration: 0.5 }}
          >
            <h2 className="table_equipment">
              OUR MANUFACTURING FACILITY IS EQUIPPED WITH FOLLOWING MACHINERY
            </h2>
          </motion.div>
          <motion.div
            variants={textVariants("up", 0.2)}
            initial="hidden"
            whileInView="show"
            viewport={{ once: true, amount: 0.1 }}
            transition={{ duration: 0.5 }}
          >
            <div className="testing_table">
              <table className="table_content">
                <tr className="heading">
                  <th className="table_heading">Make</th>
                  <th className="table_heading1">Machinery</th>
                </tr>
                {tableList}
              </table>
            </div>
          </motion.div>
        </section>

        <section
          className="footer_area"
          data-scroll-section
          style={{
            overflowY: "hidden",
          }}
        >
          <Footer />
        </section>
      </div>
    </>
  );
};

export default Maunfacutring;
