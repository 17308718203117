import { React, useEffect, useRef } from "react";
import "./manufacturingprocessinner.scss";
import { drawBench, leftArrow } from "../../assets";
import LocomotiveScroll from "locomotive-scroll";
import { textVariants } from "../../Header/Motion";
import { motion } from "framer-motion";
import Footer from "../../Footer/Footer";
import PreLoader from "../../loader/preloader";
import { useWindowSize } from "react-use";
import { Link } from "react-router-dom";
import LeftArrowCtaSvg from "../../common/leftarrowCtaSvg";
const imagesLoaded = require("imagesloaded");

const Drawbench = () => {
  const { width } = useWindowSize();
  const scrollRef = useRef(null);

  useEffect(() => {
    let scrollContainer = document.querySelector("[data-scroll-container]");
    const scroll = new LocomotiveScroll({
      el: scrollRef.current,
      smooth: true,
      getSpeed: true,
      getDirection: true,
      multiplier: 0.4, // Adjust the scroll speed here
    });

    scroll.update(); // Manually update the scroll height

    imagesLoaded(scrollContainer, { background: true }, function () {
      scroll.update();
    });
    const resizeObserver = new ResizeObserver((entries) => {
      scroll.update();
    });

    // start observing a DOM node
    resizeObserver.observe(document.body);

    return () => {
      resizeObserver.unobserve(document.body);

      scroll.destroy();
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <PreLoader />
      <div
        data-scroll-container
        ref={scrollRef}
        style={{
          background: "#141414",
          overflow: "hidden",
        }}
      >
        {/* <Commonbanner2
          linkText="ABOUT US"
          link="/about"
          linkText1="MANUFACTURING PROCESS"
          link1="/manufacturing-process"
          linkText2="DRAW BENCH"
          link2="/draw-bench"
        /> */}
        <section id="scrolltop" className="draw_bench_sec1">
          <Link to="/manufacturing-process" className="back_btn_wrapper">
            <div className="back_cta_arrow">
              <LeftArrowCtaSvg />
            </div>
            <button className="back_btn"> Back</button>
          </Link>
          <div className="draw_bench_container">
            <div className="draw_bench_content">
              <motion.div
                variants={textVariants("left", 0.2)}
                initial="hidden"
                whileInView="show"
                viewport={{ once: true, amount: 0.1 }}
                transition={{ duration: 0.5 }}
              >
                <h2 className="draw_bench_heading">DRAW BENCH</h2>
                <p className="draw_bench_details">
                  The next step in the manufacturing process involves passing
                  the tubes through a draw bench. The draw bench is a machine
                  that gradually reduces the diameter and thickness of the tubes
                  through a series of drawing dies. This process is carried out
                  by pulling the tubes through the drawing dies with controlled
                  tension. The draw bench process helps to refine the dimensions
                  of the tubes, improve their surface finish, and enhance their
                  mechanical properties. It ensures that the tubes meet the
                  required specifications and exhibit the desired strength and
                  precision.
                </p>
              </motion.div>
            </div>
            <div className="draw_bench_image_wrapper">
              <motion.div
                variants={textVariants("up", 0.2)}
                initial="hidden"
                whileInView="show"
                viewport={{ once: true, amount: 0.1 }}
                transition={{ duration: 0.5 }}
              >
                <img
                  src={drawBench}
                  alt="billet saw"
                  className="draw_bench_img"
                />
              </motion.div>
            </div>
          </div>
        </section>
        <section
          className="footer_area"
          style={{
            overflowY: "hidden",
          }}
        >
          <Footer />
        </section>
      </div>
    </>
  );
};

export default Drawbench;
